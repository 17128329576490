import React, { useEffect } from 'react';
import styles from './textfield.module.scss';
import { TextField, MenuItem, Checkbox, ListSubheader } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

Object.byString = function(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

const ParagonTextField = (props) => {
    const { form: { setFieldValue } } = props;
    const _fieldName = props.field && props.field.name;
    const _hasError = props.form && props.form.errors && props.form.errors[_fieldName] !== undefined && props.form.errors[_fieldName] !== null;
    const _errorMessage = props.form && props.form.errors && props.form.errors[_fieldName] !== 'undefined' && props.form.errors[_fieldName];
    const _isTouched = props.form && props.form.touched && Object.byString(props.form.touched, _fieldName) !== undefined && Object.byString(props.form.touched, _fieldName) !== null;
    let _values = props.field ? { value: props.field.value || [] } : null;
    // let _multiples = props.multiline ? { multiline:true, rows: props.rows ? props.rows : 2 } : null;

    let _props = { ...props };

    delete _props.customFn;
    delete _props.defaultValue;
    delete _props.onlyNumbers;
    delete _props.inputProps;

    useEffect(() => {
        if (props?.defaultValue) {
            setFieldValue(_fieldName, props.defaultValue);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    const onChange = (event, secondProps) => {
        let value = props.onlyNumbers ? event.target.value.replace(/[^0-9.,]/, '') : event.target.value;

        if (_fieldName.toLowerCase().includes('password') || _fieldName.toLowerCase().includes('username')) {
            value = value.replace(" ", "");
        }

        if (props.customFn) {
            props.customFn(value, secondProps);
        }

        setFieldValue(_fieldName, value);
    }

    return (
        <TextField {..._props} {...props.field} error={_hasError && _isTouched} helperText={_errorMessage} {..._values} onChange={onChange}
            InputProps={{ classes: { root: _isTouched && _hasError ? styles['inputContainer__input-root-error'] : styles['inputContainer__input-root']}}}
            InputLabelProps={{ classes: { focused: _isTouched && _hasError ? styles['inputContainer__input-focusedLabel-error'] : styles['inputContainer__input-focusedLabel'], shrink: _isTouched && _hasError ? styles['inputContainer__input-focusedLabel-error'] : styles['inputContainer__input-focusedLabel'] } }}
            inputProps={{ className: props.chipview ? styles['inputContainer__inputField-chips'] : styles['inputContainer__inputField'], maxLength: props.maxLength, ...props.inputProps}} classes={{ root: styles['inputContainer__input'] }} />
    )
}


export default ParagonTextField;

export const NormalTextField = (props) => {
    return (
        <TextField {...props}
            InputProps={{ classes: { root: styles['inputContainer__input-root'] }, ...props.InputProps }}
            InputLabelProps={{ classes: { focused: styles['inputContainer__input-focusedLabel'], shrink: styles['inputContainer__input-focusedLabel'] } }}
            inputProps={{ className: styles['inputContainer__inputField'] }} classes={{ root: styles['inputContainer__input'] }} />
    )
}



export const ParagonMultipleSelect = (props) => {
    const jsonField = { ...props.jsonField };
    let _props = { ...props };
    const { form: { setFieldValue } } = props;
    const _fieldName = props.field && props.field.name;
    const _hasError = props.form && props.form.errors && props.form.errors[_fieldName] !== undefined && props.form.errors[_fieldName] !== null;
    const _errorMessage = props.form && props.form.errors && props.form.errors[_fieldName] !== 'undefined' && props.form.errors[_fieldName];
    const _isTouched = props.form && props.form.touched && props.form.touched[_fieldName] !== undefined && props.form.touched[_fieldName] !== null;
    let _values = props.field ? { value: props.field.value || [] } : null;

    delete _props['jsonField'];
    delete _props.defaultValue;

    useEffect(() => {
        if (props?.defaultValue) {
            setFieldValue(_fieldName, props.defaultValue);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    return (
        <TextField {..._props} {...props.field} error={_hasError && _isTouched} helperText={_errorMessage} {..._values} fullWidth
            InputProps={{ classes: { root: _isTouched && _hasError ? styles['inputContainer__input-root-error'] : styles['inputContainer__input-root'] } }}
            InputLabelProps={{ classes: { focused: _isTouched && _hasError ? styles['inputContainer__input-focusedLabel-error'] : styles['inputContainer__input-focusedLabel'], shrink: _isTouched && _hasError ? styles['inputContainer__input-focusedLabel-error'] : styles['inputContainer__input-focusedLabel'] } }}
            inputProps={{ className: props.chipview ? styles['inputContainer__inputField-chips'] : styles['inputContainer__inputField'] }} classes={{ root: styles['inputContainer__input'] }}>
            {jsonField.noOptionsText && jsonField.options.length === 0 ? <MenuItem value={''} disabled>{jsonField.noOptionsText}</MenuItem> : null}
            {jsonField.blankOption ? <MenuItem value={''}></MenuItem> : null}
            {
                jsonField?.options && jsonField?.options.map((opt, index) =>
                    <MenuItem key={index} value={opt.value ? opt.value : opt} classes={{ root: styles['menuItem__root'] }}>
                        <Checkbox color="primary" checked={props?.field?.value?.map(el => jsonField.inputFieldLabel ? el[jsonField.inputFieldLabel] : el).indexOf(jsonField.inputFieldLabel ? opt[jsonField.inputFieldLabel] : opt) > -1} />
                        {jsonField.optionLabel ? opt[jsonField.optionLabel] : opt}
                    </MenuItem>)
            }
        </TextField>
    )
}

export const ParagonMultipleSelectGrouped = (props) => {
    const jsonField = { ...props.jsonField };
    let _props = { ...props };
    const { form: { setFieldValue } } = props;
    const _fieldName = props.field && props.field.name;
    const _hasError = props.form && props.form.errors && props.form.errors[_fieldName] !== undefined && props.form.errors[_fieldName] !== null;
    const _errorMessage = props.form && props.form.errors && props.form.errors[_fieldName] !== 'undefined' && props.form.errors[_fieldName];
    const _isTouched = props.form && props.form.touched && props.form.touched[_fieldName] !== undefined && props.form.touched[_fieldName] !== null;
    let _values = props.field ? { value: props.field.value || [] } : null;

    delete _props['jsonField'];
    delete _props.defaultValue;

    useEffect(() => {
        if (props?.defaultValue) {
            setFieldValue(_fieldName, props.defaultValue);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    return (
        <TextField {..._props} {...props.field} error={_hasError && _isTouched} helperText={_errorMessage} {..._values} fullWidth
            InputProps={{ classes: { root: _isTouched && _hasError ? styles['inputContainer__input-root-error'] : styles['inputContainer__input-root'] } }}
            InputLabelProps={{ classes: { focused: _isTouched && _hasError ? styles['inputContainer__input-focusedLabel-error'] : styles['inputContainer__input-focusedLabel'], shrink: _isTouched && _hasError ? styles['inputContainer__input-focusedLabel-error'] : styles['inputContainer__input-focusedLabel'] } }}
            inputProps={{ className: props.chipview ? styles['inputContainer__inputField-chips'] : styles['inputContainer__inputField'] }} classes={{ root: styles['inputContainer__input'] }}>
            {jsonField.noOptionsText && jsonField.options.length === 0 ? <MenuItem value={''} disabled>{jsonField.noOptionsText}</MenuItem> : null}
            {jsonField.blankOption ? <MenuItem value={''}></MenuItem> : null}
            {
                jsonField?.options && Object.keys(jsonField?.options).map((category, index) =>
                    [
                        <><ListSubheader key={index}>{category}</ListSubheader></>,
                        jsonField.options[category].map((opt, index) => opt && <MenuItem key={index} value={opt.value ? opt.value : opt} classes={{ root: styles['menuItem__root'] }}>
                            <Checkbox color="primary" checked={props?.field?.value?.map(el => jsonField?.inputFieldLabel ? el[jsonField?.inputFieldLabel] : el).indexOf(jsonField?.inputFieldLabel ? opt[jsonField?.inputFieldLabel] : opt) > -1} />
                            {jsonField?.optionLabel ? opt[jsonField?.optionLabel] : opt}
                        </MenuItem>)
                    ]
                )
            }
        </TextField>
    )
}


export const ParagonMaskedTextField = (props) => {
    const _fieldName = props.field && props.field.name;
    const _hasError = props.form && props.form.errors && props.form.errors[_fieldName] !== undefined && props.form.errors[_fieldName] !== null;
    const _errorMessage = props.form && props.form.errors && props.form.errors[_fieldName] !== 'undefined' && props.form.errors[_fieldName];
    const _isTouched = props.form && props.form.touched && props.form.touched[_fieldName] !== undefined && props.form.touched[_fieldName] !== null;

    let _props = { ...props };

    delete _props.customFn;
    delete _props.defaultValue;
    delete _props.mask;
    delete _props.maskPlaceholder;
    delete _props.onlyNumbers;


    return (
        <MaskedInput
            mask={props.mask}
            placeholderChar={props.maskPlaceholder ? props.maskPlaceholder : '\u2000'}
            name={props.field.name}
            value={props.form.values[props.field.name]}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            showMask
            guide={false}

            render={(ref, innerProps) => (
                <TextField {..._props} error={_hasError && _isTouched} helperText={_errorMessage} inputRef={ref} {...innerProps}
                    InputProps={{ classes: { root: _isTouched && _hasError ? styles['inputContainer__input-root-error'] : styles['inputContainer__input-root'] } }}
                    InputLabelProps={{ classes: { focused: _isTouched && _hasError ? styles['inputContainer__input-focusedLabel-error'] : styles['inputContainer__input-focusedLabel'], shrink: _isTouched && _hasError ? styles['inputContainer__input-focusedLabel-error'] : styles['inputContainer__input-focusedLabel'] } }}
                    inputProps={{ className: props.chipview ? styles['inputContainer__inputField-chips'] : styles['inputContainer__inputField'] }} classes={{ root: styles['inputContainer__input'] }} />
            )}
        />
    );
}