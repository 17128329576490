import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, DialogContent, DialogContentText, MenuItem, TextField } from '@material-ui/core';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { usePaginatedRequests } from 'src/hooks/usePaginatedRequests';
import { GET_STUDIES } from '../Studies/services';
import { IsMobile } from 'src/hooks/MediaQuery/isMobile';
import { CustomActionButton } from 'src/roots/AuthenticatedApp/components/inputs/Button';
import { useStudy } from 'src/hooks/Study/useStudy';
import ReactPlayer from 'react-player';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useAsync } from 'src/hooks/useAsync';
import { GET_LANGUAGES } from 'src/components/Sidebar/services';
import styles from 'src/components/Button/button.module.scss';
import { useAuth } from 'src/context/auth-context';
import creationStyle from 'src/styles/creation.module.scss';
import InboxRoundedIcon from '@material-ui/icons/InboxRounded';
import { useTranslation } from 'react-i18next';
import { DetailLoader } from 'src/components';

const VideoTutorialDialog = ({ className, customStyles, openMenu }) => {
    const auth = useAuth();
    const studies = usePaginatedRequests(GET_STUDIES, { sort: 'createdAt_desc', size: 1000 }, false);
    const languages = useAsync(GET_LANGUAGES, false);
    const study = useStudy();
    const isMobile = IsMobile();
    const [tutorials, setTutorials] = useState([]);
    const [language, setLanguage] = useState(null);
    const [filteredLanguages, setLanguages] = useState([]);
    const [loadingLanguages, setLoadingLanguages] = useState(true);

    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const [openDialog, setOpenDialog] = useState(false);

    const loadStudyConfiguration = useCallback(
        (uuid) => {
            study.GetConfiguration(uuid, config => {
                setTutorials(config.TUTORIAL);
                languages.execute({}, response => {
                    if (response.data.length <= 0) {
                        setLanguage('en');
                    } else {
                        let _languages = [{
                            code: "en",
                            name: "English"
                        }];
                        config.TUTORIAL.forEach(tutorial => {
                            response.data.forEach(lang => {
                                if (tutorial[lang.code] && tutorial?.target_user?.includes(auth._data.profile.accountType) && _languages.map(l => l.code).indexOf(lang.code) <= -1) {
                                    _languages.push(lang);
                                }
                            });
                        });

                        setLanguages(_languages);
                        setLoadingLanguages(false);
                        if (_languages.length === 1) {
                            setLanguage(_languages[0].code);
                        } else {
                            setLanguage(null);
                        }

                    }
                    setLoading(false);
                });
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleClickButton = () => {
        if (openDialog) {
            setTutorials([]);
            setLanguage(null);
            languages.setResponse(oldResponse => {
                return {
                    ...oldResponse,
                    data: []
                }
            });
        }
        setOpenDialog(!openDialog);
    }

    useEffect(() => {
        if (openDialog === true && auth._data.profile.accountType !== 'PATIENT') {
            setLoading(true);
            studies.loadData({ sort: 'createdAt_desc', size: 1000 }, () => {
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDialog])

    useEffect(() => {
        if ((openDialog === true && studies.response.content.length === 1) || (openDialog === true && auth._data.profile.accountType === 'PATIENT')) {
            loadStudyConfiguration(auth._data.profile.accountType === 'PATIENT' ? auth._data.profile.study.uuid : studies.response.content[0].uuid);
            // languages.execute({ studyUuid: auth._data.profile.accountType === 'PATIENT' ? auth._data.profile?.study?.uuid : studies.response.content[0].uuid }, (response) => {
            //     setLanguage(response.data[0].code)
            // });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDialog, studies.response.content]);

    return (
        <CustomActionButton onButtonClick={handleClickButton} variant="text" className={className} customStyles={customStyles} icon={<OndemandVideoIcon fontSize="large" />} fullScreen={isMobile} fullWidth maxWidth={"md"} text={openMenu ? "Video tutorials" : ''} color="inherit" dialogTitle="Video Tutorials">
            <DialogContent style={{ minHeight: '45vh' }}>
                {
                    loading && <div className={creationStyle['container__Loader']}><DetailLoader /></div>
                }
                <DialogContentText component={"div"} className={styles['formView']}>
                    <div className={styles['formView__row']}>
                        {!loading && studies.response.content.length > 1 && <TextField SelectProps={{ renderValue: value => value.name }} style={{ margin: '10px 10px 10px 0' }} className={styles['container__textfield']} fullWidth variant="outlined" color="primary" label="study" select onChange={(event) => { loadStudyConfiguration(event.target.value.uuid) }}>
                            {studies.response.content.map(el => <MenuItem value={el}>{el.name}</MenuItem>)}
                        </TextField>}
                        {!loading && <TextField InputLabelProps={{ shrink: language ? true : false }} disabled={loadingLanguages || !filteredLanguages?.length} style={{ margin: '10px 0 10px 10px' }} className={styles['container__textfield']} fullWidth variant="outlined" color="primary" label="language" select onChange={(event) => { setLanguage(event.target.value) }} value={language}>
                            {filteredLanguages?.length <= 0 && <MenuItem disabled value={'en'}>{t('generic:english')}</MenuItem>}
                            {filteredLanguages?.map(el => <MenuItem value={el.code}>{el.name}</MenuItem>)}
                        </TextField>}
                    </div>
                    {
                        !loading && (!tutorials || tutorials?.filter(tut => tut[language]).length <= 0) && <div className={creationStyle['container__SectionBodyEmpty']}>
                            <div className={creationStyle['container__SectionBodyEmptyIcon']}><InboxRoundedIcon /></div>
                            <div className={creationStyle['container__SectionBodyEmptyText']}>{t('generic:noTutorialAvailable')}</div>
                        </div>
                    }
                    {
                        !loading && tutorials?.map(tutorial => tutorial[language] && tutorial.target_user.includes(auth._data.profile.accountType) && tutorial[language].title.length > 0 && <Accordion TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles['formView__blackTitle']}>
                                {tutorial[language].title}
                            </AccordionSummary>
                            <AccordionDetails className={styles['formView__column']}>
                                <div className={styles['formView__description']}>
                                    {
                                        tutorial[language].description
                                    }
                                </div>
                                <div className={styles['formView__row']}>
                                    <ReactPlayer
                                        controls
                                        width={'100%'}
                                        url={tutorial[language].url}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>)
                    }

                </DialogContentText>
            </DialogContent>
        </CustomActionButton>
    )
}

export default VideoTutorialDialog;