import React, { Fragment } from 'react';
import styles from '../Dashboard.module.scss';
import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePaginatedRequests } from 'src/hooks/usePaginatedRequests';
import { GET_UPCOMING_VISITS } from '../services';
import { isSameDay, parseISO } from 'date-fns';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';


export const UpcomingVisits = ({ isMobile, onScrollFn }) => {
    const visits = usePaginatedRequests(GET_UPCOMING_VISITS , { startDateFrom: new Date(), sort: 'startDate_asc', status: 'CONFIRMED' });
    const history = useHistory();
    const { t } = useTranslation();

    const mainClassName = isMobile ? 'upcomingVisitMobileContainer': 'upcomingVisitContainer';

    return(
        <Grid className={styles[mainClassName]} item md={7}>
            {!isMobile && <div className={styles[`${mainClassName}__title`]}>
                {t('dashboard:upcomingVisits')}
            </div>}
            <div id="parentScroll" className={styles[`${mainClassName}__body`]}>
                {visits.response.content.length > 0 && <InfiniteScroll onScroll={onScrollFn} scrollableTarget="parentScroll" dataLength={visits.response.content.length} next={visits.loadMore} hasMore={visits.response.hasMore} loader={<CircularProgress />}>
                    {
                        visits.response.content.map((visit, index) => {
                            let showDate = isSameDay(parseISO(visits.response.content[index - 1]?.startDate), parseISO(visit?.startDate));
                            return (
                                <Fragment key={visit.uuid}>
                                    {!showDate && <div className={styles[`${mainClassName}__body-row-title`]}>
                                        {moment(visit.startDate * 1000).format('DD/MM/YYYY')}
                                    </div>}
                                    <div className={styles[`${mainClassName}__body-row`]}>
                                        <div className={styles['rowContainer']}>
                                            <div className={styles['rowContainer__message']}>{moment(visit.startDate).format('HH:mm')} - {moment(visit.endDate).format('HH:mm')} <b className={styles['rowContainer__message-hover']} onClick={() => { sessionStorage.setItem("navigatedInsideDetail", true); history.push(`/patients/detail/${visit?.patient?.uuid}`) }}>{visit?.patient?.fullName}</b> {t('dashboard:willHaveVisit')} <b className={styles['rowContainer__message-hover']} onClick={() => { sessionStorage.setItem("navigatedInsideDetail", true); history.push(`/sites/detail/${visit?.site?.uuid}`) }}>{visit?.site?.name}</b></div>
                                        </div>
                                        <div className={styles[`${mainClassName}__body-row-icons`]}>
                                            <IconButton size="small" color="primary" onClick={() => window.location.href = `mailto:${visit?.patient?.email}`}><Tooltip title={t('dashboard:sendEmail')}><EmailIcon htmlColor={isMobile && '#FFF'} /></Tooltip></IconButton>
                                            <IconButton size="small" color="primary" onClick={() => { sessionStorage.setItem("navigatedInsideDetail", true); history.push(`/visits/detail/${visit.uuid}`) }}><Tooltip title={t('dashboard:openVisitDetail')}><OpenInNewIcon htmlColor={isMobile && '#FFF'} /></Tooltip></IconButton>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        })
                    }
                </InfiniteScroll>}
                {
                    visits.response.content.length === 0 && <div className={styles[`${mainClassName}__body-zero`]}>{t('dashboard:noVisits')}</div>
                }
            </div>
        </Grid>
    )
}