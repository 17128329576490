import React, { useState } from 'react';
import { Button, Dialog, DialogContentText } from '@material-ui/core';
import { useFormik } from 'formik';
import { FilledDialogTitle, FormField, DialogContent, DialogActions} from 'src/components';
import { IsMobile } from 'src/hooks/MediaQuery/isMobile';
import styles from '../LoginStyle.module.scss';
import { useTranslation } from 'react-i18next';

export function ForgotPassword({ auth }) {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const isMobile = IsMobile();

    const validate = values => {
        const errors = {};

        if (!values?.username) {
            errors.username = 'Username required';
        }

        if (!values?.email) {
            errors.email = 'Email required';
        }

        if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email Address';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            email: ''
        },
        validate,
        onSubmit: values => {
            auth.forgotPassword(values);
            setOpenDialog(false);
        }
    });

    return (
        <div className={styles['resetPasswordContainer']}>
            <span className={styles['resetPasswordContainer__text']} onClick={() => setOpenDialog(true)}> {t('login:forgotPassword')}</span>
            <Dialog
                fullScreen={isMobile}
                open={openDialog}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <FilledDialogTitle onClose={() => setOpenDialog(false)}>
                    {t('login:forgotPassword')}
                </FilledDialogTitle>
                <DialogContent dividers>
                    <DialogContentText component="div">
                    {t('login:resetPasswordSteps')}
                        <ol>
                            <li>{t('login:resetPasswordStepsOne')}</li>
                            <li>{t('login:resetPasswordStepsTwo')}</li>
                            <li>{t('login:resetPasswordStepsThree')}</li>
                        </ol>
                        <form>
                            <div className={styles['resetPassword__input']}>
                                <FormField id="username" fullWidth formik={formik} name="username" label="Username" />
                            </div>
                            <div>
                                <FormField id="email" fullWidth formik={formik} name="email" label="Email" />
                            </div>
                        </form>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setOpenDialog(false)} variant={"outlined"}>{t('generic:cancel')}</Button>
                    <Button onClick={formik.handleSubmit} color="primary" autoFocus variant={'contained'}>{t('login:getNewPassword')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}