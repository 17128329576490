import { Dialog, IconButton, InputBase, SvgIcon } from '@material-ui/core';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'src/styles/components.module.scss';
import { DialogContent, FilledDialogTitle } from '..';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import ClearIcon from '@material-ui/icons/Clear';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePaginatedRequests } from 'src/hooks/usePaginatedRequests';
import { GET_CARDS } from 'src/hooks/DebitCards/services';
import InboxRoundedIcon from '@material-ui/icons/InboxRounded';


export const AssignDebitCardDialog = forwardRef(({ onClick, filters }, ref) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const request = usePaginatedRequests(GET_CARDS, { onlyAvailable: true }, false);

    function handleClose() {
        setOpen(!open);
    }

    function Search() {
        request.changeFilters({ ...filters, onlyAvailable: true, code: searchValue });
    }

    function resetSearch() {
        request.changeFilters({ onlyAvailable: true, ...filters });
        setSearchValue(null);
    }

    useImperativeHandle(ref, () => ({
        openDialog: () => setOpen(true)
    }));

    useEffect(() => {
        if (open) {
            request.loadData(filters ? filters : { onlyAvailable: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
            <FilledDialogTitle onClose={handleClose}>
                {t('studies:selectDebitCard')}
            </FilledDialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
                <div className={styles['container__Search']}>
                    <IconButton size="small" onClick={searchValue ? Search : null}>
                        <SvgIcon fontSize="default">
                            <SearchIcon />
                        </SvgIcon>
                    </IconButton>
                    <InputBase fullWidth value={searchValue || ''} onChange={e => { setSearchValue(e.target.value) }} onKeyDown={Search} placeholder={"search debit card..."}
                        endAdornment={searchValue && searchValue.length > 0 ?
                            <IconButton size="small" onClick={resetSearch}>
                                <ClearIcon />
                            </IconButton> : null} />
                </div>

                {request.response.content.length <= 0 && <div className={styles['container__ListEmpty']}>
                    <div className={styles['container__ListEmptyIcon']}><InboxRoundedIcon /></div>
                    <div className={styles['container__ListEmptyText']}>{t('patients:noDebitCardAvailable')}</div>
                </div>}
                {request.response.content.length > 0 && <div className={styles['container__List']} id="parentScroll" >
                    <InfiniteScroll style={{ overflow: 'unset' }} scrollableTarget="parentScroll" dataLength={request.response.content.length} next={request.loadMore} hasMore={request.response.hasMore}>
                        {
                            request.response.content.map(debitCard => <div className={styles['container__ListItem']}>
                                <div className={styles['container__ListItemName']}>{debitCard.code}</div>
                                <div className={styles['container__ListItemIcon']}>
                                    <IconButton size="small" onClick={() => { onClick(debitCard); handleClose(); }}>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>)
                        }
                    </InfiniteScroll>
                </div>}
            </DialogContent>
        </Dialog>
    )
})