import React from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Fade } from 'src/components';


export const StudyConfiguration = ({ formik }) => {
    const { t } = useTranslation();

    function handleChange(event) {
        if(event.target.name === 'HAS_DEBIT_CARD' && !event.target.checked && Boolean(formik.values['HAS_B4B_INTEGRATION'])){
            formik.setFieldValue(event.target.name, event.target.checked);
            formik.setFieldValue('HAS_B4B_INTEGRATION', false);
        }else{
            formik.setFieldValue(event.target.name, event.target.checked);
        }
    }

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']}>{t('studies:studyConfiguration')}</div>
            <div className={creationStyle['container__SectionBody']}>
                <FormControlLabel className={creationStyle['container__SectionBodyField']}
                    control={<Checkbox color="primary" checked={Boolean(formik.values['HAS_SCHEDULING_GRID'])} onChange={handleChange} name="HAS_SCHEDULING_GRID" />}
                    label={t('studies:studyHasSchedulingGrid')}
                />
                <FormControlLabel className={creationStyle['container__SectionBodyField']}
                    control={<Checkbox color="primary" checked={Boolean(formik.values['PATIENT_CONSENT_FORM'])} onChange={handleChange} name="PATIENT_CONSENT_FORM" />}
                    label={t('studies:requestConsentForm')}
                />
                <FormControlLabel className={creationStyle['container__SectionBodyField']}
                    control={<Checkbox color="primary" checked={Boolean(formik.values['VISIT_NUMBER_AUTO_GENERATED'])} onChange={handleChange} name="VISIT_NUMBER_AUTO_GENERATED" />}
                    label={t('studies:visitNumberAutoGenerated')}
                />
                <FormControlLabel className={creationStyle['container__SectionBodyField']}
                    control={<Checkbox color="primary" checked={Boolean(formik.values['HAS_DEBIT_CARD'])} onChange={handleChange} name="HAS_DEBIT_CARD" />}
                    label={t('studies:hasDebitCard')}
                />
                {Boolean(formik.values['HAS_DEBIT_CARD']) && <FormControlLabel className={creationStyle['container__SectionBodyField']}
                    control={<Checkbox color="primary" checked={Boolean(formik.values['HAS_B4B_INTEGRATION'])} onChange={handleChange} name="HAS_B4B_INTEGRATION" />}
                    label={t('studies:hasB4bIntegration')}
                />}
                <FormControlLabel className={creationStyle['container__SectionBodyField']}
                    control={<Checkbox color="primary" checked={Boolean(formik.values['PATIENT_CODE_AUTO_GENERATED'])} onChange={handleChange} name="PATIENT_CODE_AUTO_GENERATED" />}
                    label={t('studies:patientCodeAutoGenerated')}
                />
            </div>
        </Fade>
    )
}