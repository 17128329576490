import HttpRequestsHandler from "src/services/http-requests-handler";

const path = {
    GET_PATIENTS: '/api/patient/all',
    DELETE_PATIENT: '/api/patient/:username',
    PUT_PATIENT: '/api/patient/:username',
    POST_PATIENT: '/api/patient',
    GET_ALL_STUDIES: '/api/study/all',
    GET_PATIENT: '/api/patient/:uuid',
    ASSIGN_PATIENT_TO_SITE: '/api/patient/:username/site',
    GET_PATIENT_DOCUMENT: '/api/patient/:uuid/document/all',
    UPLOAD_PATIENT_DOCUMENT: '/api/patient/:uuidPatient/document',
    UPLOAD_SIGNED_PATIENT_DOCUMENT: '/api/patient/:uuidPatient/document/:uuidDocument/signed',
    CREATE_VISIT: '/api/visit',
    EDIT_PATIENT_STATUS: '/api/patient/:uuid/status',
    GET_STUDYCOORDINATORS: '/api/studycoordinator/all',
    FORCE_RESET_PASSWORD_PATIENT: '/api/patient/:uuid/password',
    UPLOAD_REIMBURSEMENT_FORM: '/api/patient/:uuid/reimbursement/document',
    ASSIGN_DEFAULT_PAYMENT_METHOD: '/api/patient/:patientUuid/paymentmethod/:paymentUuid',
    REMOVE_DEFAULT_PAYMENT_METHOD: '/api/patient/:patientUuid/paymentmethod/remove',
}


export const GET_PATIENTS = (filters) => {
    return HttpRequestsHandler("GET", path.GET_PATIENTS, filters);
}

export const DELETE_PATIENT = (username) => {
    return HttpRequestsHandler("DELETE", path.DELETE_PATIENT.replace(/:username/gi, username), null);
}

export const POST_PATIENT = (body) => {
    return HttpRequestsHandler("POST", path.POST_PATIENT, null, body);
}

export const GET_ALL_STUDIES = (params) => {
    return HttpRequestsHandler("GET", path.GET_ALL_STUDIES, params);
}

export const GET_STUDYCOORDINATORS = (params) => {
    return HttpRequestsHandler("GET", path.GET_STUDYCOORDINATORS, params);
}

export const GET_PATIENT = (uuid) => {
    return HttpRequestsHandler("GET", path.GET_PATIENT.replace(/:uuid/gi, uuid), null);
}

export const ASSIGN_PATIENT_TO_SITE = (username, body) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_PATIENT_TO_SITE.replace(/:username/gi, username), null, body);
}

export const PUT_PATIENT = (username, body) => {
    return HttpRequestsHandler("PUT", path.PUT_PATIENT.replace(/:username/gi, username), null, body);
}

export const GET_PATIENT_DOCUMENT = (uuid, params) => {
    return HttpRequestsHandler("GET", path.GET_PATIENT_DOCUMENT.replace(/:uuid/gi, uuid), params);
}

export const UPLOAD_PATIENT_DOCUMENT = (uuidPatient, body) => {
    return HttpRequestsHandler("UPLOAD", path.UPLOAD_PATIENT_DOCUMENT.replace(/:uuidPatient/gi, uuidPatient), null, body);
}

export const UPLOAD_SIGNED_PATIENT_DOCUMENT = (uuidPatient, uuidDocument, body) => {
    return HttpRequestsHandler("UPLOAD", path.UPLOAD_SIGNED_PATIENT_DOCUMENT.replace(/:uuidPatient/gi, uuidPatient).replace(/:uuidDocument/gi, uuidDocument), null, body);
}

export const CREATE_VISIT = (body) => {
    return HttpRequestsHandler("POST", path.CREATE_VISIT, null, body);
}

export const EDIT_PATIENT_STATUS = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.EDIT_PATIENT_STATUS.replace(/:uuid/gi, uuid), null, body);
}

export const FORCE_RESET_PASSWORD_PATIENT = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.FORCE_RESET_PASSWORD_PATIENT.replace(/:uuid/gi, uuid), null, body);
}

export const UPLOAD_REIMBURSEMENT_FORM = (uuid, body) => {
    return HttpRequestsHandler("UPLOAD", path.UPLOAD_REIMBURSEMENT_FORM.replace(/:uuid/gi, uuid), null, body);
}

export const ASSIGN_DEFAULT_PAYMENT_METHOD = (patientUuid, paymentUuid) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_DEFAULT_PAYMENT_METHOD.replace(/:patientUuid/gi, patientUuid).replace(/:paymentUuid/gi, paymentUuid), null, null);
}

export const REMOVE_DEFAULT_PAYMENT_METHOD = (patientUuid) => {
    return HttpRequestsHandler("PUT", path.REMOVE_DEFAULT_PAYMENT_METHOD.replace(/:patientUuid/gi, patientUuid), null, null);
}

