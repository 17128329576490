import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

export const StaticFormAutocomplete = ({ formik, helperText, InputProps, ...rest }) => {

    function onChange(_, value){
        formik.setFieldValue(rest.name, value || "");
    }

    return <Autocomplete onChange={onChange}
        {...rest}
        renderInput={(params) => <TextField {...params} fullWidth={rest.fullWidth} helperText={formik.touched[rest.name] && formik.errors[rest.name] ? formik.errors[rest.name] : helperText} error={formik.touched[rest.name] && Boolean(formik.errors[rest.name])} label={rest.label} margin="none" variant="outlined" color="primary" onBlur={formik.handleBlur} required={rest.required} onFocus={event => event.target.autocomplete = "removeAutcomplete"} />}
    />
}