
import XLSX from "xlsx";

export function navigateThroughDetail(history, URL) {
  let _lstLocationList = [];
  if (sessionStorage.getItem('_lstURL')) {
    _lstLocationList = _lstLocationList.concat(...JSON.parse(sessionStorage?.getItem('_lstURL')))
  }
  let _currentLocation = window.location.pathname + window.location.search;
  _lstLocationList.push(_currentLocation);
  sessionStorage.setItem('_lstURL', JSON.stringify(_lstLocationList));
  history.push(URL);
}

export function timeMask(value) {

  const chars = value.split('');

  var hours;
  if (chars[0] == '0' || chars[0] == '1' || chars[0] == '2') {
    if (chars[1] == ':') {
      hours = [/[0-2]/];
    } else {
      hours = [/[0-2]/, chars[2] == '2' ? /[0-3]/ : /[0-9]/];
    }
  } else {
    hours = [/[3-9]/];
  }

  const minutes = [/[0-5]/, /[0-9]/];

  return hours.concat(':').concat(minutes);
}




export function preventClose(e) {
  var message = "You have attempted to leave this page.  If you have made any changes to the fields without clicking the Save button, your changes will be lost.  Are you sure you want to exit this page?";
  e = e || window.event;
  // For IE and Firefox
  if (e) {
    e.returnValue = message;
  }

  // For Safari
  return message;
}


export function convertToPaginatedPromise(list = []){
    return new Promise((resolve) => resolve({
      data: {
        number: 0,
        last: true,
        totalElements: list.length,
        content: list
      }
    }));
}


export const clamp = (pos, low, high) => {
  const mid = Math.max(pos, low);
  return Math.min(mid, high);
};

export const swap = (arr, from, to) => {
  const copy = [...arr];
  const [index] = copy.splice(from, 1);
  copy.splice(to, 0, index);
  return copy;
};