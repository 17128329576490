import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#259ECB',
        color: '#FFF',
        boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.25)',
        margin: 0,
        padding: theme.spacing(2)
    },
    typography:{
        flex: 1,
        fontWeight: '600',
        fontFamily: 'Nunito'
    },
    closeButton: {
        display: 'flex',
        color: '#FFF',
    },
});

export const FilledDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, onHelp, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className={classes.typography} variant="h6">{children}</Typography>
            {onHelp ? (
                <IconButton size="small" aria-label="help" className={classes.closeButton} onClick={onHelp}>
                    <HelpIcon />
                </IconButton>
            ) : null}

            {onClose ? (
                <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


FilledDialogTitle.propTypes = {
    onClose: PropTypes.func
  }