import React, { useRef } from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { Button, Grid, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetailLoader, Fade, FormField } from 'src/components';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { clamp, swap } from 'src/components/utils';
import { useGesture } from '@use-gesture/react'
import { config, useSprings } from '@react-spring/core';
import { animated } from '@react-spring/web';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const fn =
    (order, active = false, originalIndex = 0, curIndex = 0, y = 0) =>
        (index) =>
            active && index === originalIndex
                ? {
                    y: curIndex * 100 + y,
                    scale: 1,
                    zIndex: 1,
                    shadow: 15,
                    immediate: (key) => key === 'zIndex',
                    config: (key) => (key === 'y' ? config.stiff : config.default),
                }
                : {
                    y: order.indexOf(index) * 100,
                    scale: 1,
                    zIndex: 0,
                    shadow: 1,
                    immediate: false,
                }


export const StudySchedulingGridConfiguration = ({ formik, schedulingGrid }) => {
    const { t } = useTranslation();
    const scrollDestinationRef = useRef(null);

    const order = useRef(formik.values.schedulingGrid.map((_, index) => index));
    const [springs, api] = useSprings(formik.values.schedulingGrid.length, fn(order.current), [formik.values.schedulingGrid.length, order.current.length]);
    const bind = useGesture({
        onDrag: ({ args: [originalIndex], active, movement: [, y] }) => {
            const curIndex = order.current.indexOf(originalIndex)
            const curRow = clamp(Math.round((curIndex * 100 + y) / 100), 0, formik.values.schedulingGrid.length - 1)
            const newOrder = swap(order.current, curIndex, curRow)
            api.start(fn(newOrder, active, originalIndex, curIndex, y))
            if (!active) order.current = newOrder
        },
        onDragEnd: ({ args: [originalIndex] }) => {
            let _schedulingGrid = [...formik.values.schedulingGrid];

            order.current.forEach((sGridIndex, index) => {
                _schedulingGrid[sGridIndex].sort = index;
            });

            formik.setFieldValue(`schedulingGrid`, _schedulingGrid);
        }
    });

    function addSchedulingGrid() {
        let _schedulingGrid = [...formik.values.schedulingGrid];
        _schedulingGrid.push({
            name: '',
            amount: 0,
            sort: order.current.length
        });
        formik.setFieldValue('schedulingGrid', _schedulingGrid);
        order.current = [...order.current, order.current.length];
        setTimeout(() => {
            scrollDestinationRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }, 200);
    }

    function onRemove(index) {
        let _schedulingGrid = [...formik.values.schedulingGrid];
        _schedulingGrid.splice(index, 1);
        order.current.splice(order.current.indexOf(index), 1);
        order.current = order.current.map(el => {
            if(el >= index){
                el--;
            }
            return el;
        })

        formik.setFieldValue('schedulingGrid', _schedulingGrid);
    }

    if (schedulingGrid.loading)
        return <DetailLoader />

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']} style={{ paddingBottom: 0 }}>{t('studies:schedulingGridConfiguration')}</div>
            <div className={creationStyle['container__SectionTitleSecondary']}>{t('studies:schedulingGridConfigurationSecondaryTitle')}</div>
            <div className={creationStyle['container__SectionBody']}>
                <Grid container className={creationStyle['container__SectionBodyTableHeader']}>
                    <Grid style={{ padding: '0 10px' }} item xs={10} className={creationStyle['container__SectionBodyTableCell']}>{t('studies:scheduledVisitName')}</Grid>
                    <Grid style={{ padding: '0 10px' }} item xs={1} className={creationStyle['container__SectionBodyTableCell']}>{t('studies:amount')}</Grid>
                    <Grid style={{ padding: '0 10px' }} item xs={1} className={creationStyle['container__SectionBodyTableCell']}></Grid>
                </Grid>
                <div className={creationStyle['container__SectionBodyTable']} style={{ height: 'calc(100vh - 530px)', position: 'relative' }}>

                    {springs.map(({ zIndex, shadow, y, scale }, index) => (
                        <animated.div
                            key={index}
                            style={{
                                zIndex,
                                boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
                                y,
                                scale,
                            }}
                            ref={index === springs.length - 1 ? scrollDestinationRef : null}  
                            children={<Grid key={index} container className={creationStyle['container__SectionBodyTableRowSGrid']}>
                                <div className={creationStyle['container__SectionBodyTableRowSGridDragTrigger']} {...bind(index)}>
                                    <DragIndicatorIcon />
                                </div>
                                <Grid style={{ padding: '0 10px', maxWidth: '100%', flex: 1 }} container item alignItems="center" xs={9} className={creationStyle['container__SectionBodyTableCell']}>
                                    <FormField required inputProps={{ maxLength: 255 }} multiline maxRows={1} fullWidth placeholder={t('studies:scheduledVisitName')} className={creationStyle['container__Field']} InputProps={{ classes: { root: creationStyle['container__FieldWhite'] } }} formik={formik} name={`schedulingGrid[${index}].name`} />
                                </Grid>
                                <Grid container item alignItems="center" xs={1} className={creationStyle['container__SectionBodyTableCell']}>
                                    <FormField required fullWidth onlyNumbers className={creationStyle['container__Field']} InputProps={{ classes: { root: creationStyle['container__FieldWhite'] } }} formik={formik} name={`schedulingGrid[${index}].amount`} />
                                </Grid>
                                <Grid justify="center" container item alignItems="center" xs={1} className={creationStyle['container__SectionBodyTableCell']}>
                                    <IconButton onClick={() => onRemove(index)}>
                                        <DeleteRoundedIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>}
                        />
                    ))}
                </div>
                <Button style={{ marginTop: 10 }} onClick={addSchedulingGrid} color="primary">
                    {t('studies:addASchedulingGrid')}
                </Button>
            </div>
        </Fade>
    )
}