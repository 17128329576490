import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { getIn } from 'formik';

export const FormField = ({ formik, helperText, onlyNumbers, onChangeFn, maxLength, maxRows, variant, preventSetFieldValue, ...rest }) => {
    
    const handleChange = event => {
        let value = onlyNumbers ? event.target.value.replace(/[^0-9.,]/, '')  : event.target.value;

        if(onChangeFn){
            onChangeFn(value, event);
        }

        if(!preventSetFieldValue)
        formik.setFieldValue(rest.name, value);
    }

    const handlePaste = (e) => {
        if(onlyNumbers){
            let value = onlyNumbers ? e.target.value.replace(/\D/g, "") : e.target.value;
            formik.setFieldValue(rest.name, value);
        }
    }

    return (
        <TextField rowsMax={maxRows} onPaste={handlePaste} inputProps={{ maxLength:maxLength }} value={getIn(formik.values,rest.name)} helperText={formik.touched[rest.name] && formik.errors[rest.name] ? formik.errors[rest.name] : helperText} error={formik.touched[rest.name] && Boolean(formik.errors[rest.name])} variant={variant ? variant : "outlined"} color="primary" onChange={handleChange} onBlur={formik.handleBlur} {...rest} />
    )
}

FormField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired
}


export const MaskedFormField = ({ formik, helperText, ...rest }) => {
    return (
        <MaskedInput required={rest.required} mask={rest.mask} placeholderChar={'\u2000'} name={rest.name} value={formik.values[rest.name]} onChange={formik.handleChange} className={rest.className}
            onBlur={formik.handleBlur} showMask guide={false}
            render={(ref, innerProps) => (
                <TextField {...innerProps} InputProps={rest.InputProps} inputRef={ref} fullWidth helperText={formik.touched[rest.name] && formik.errors[rest.name] ? formik.errors[rest.name] : helperText} error={formik.touched[rest.name] && Boolean(formik.errors[rest.name])} name={rest.name} label={rest.label} variant="outlined" color="primary" />
            )}
        />
    )
}