import React, { useState } from 'react';
import styles from '../../inputs/Button/button.module.scss';
import ParagonTextField from 'components/inputs/textfield';
import { Formik, Form, FastField } from 'formik';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { CHANGE_USER_PASSWORD } from '../../../services';
import { useAuth } from 'context/auth-context';
import { HandleErrorCatching, IsMobile } from '../../utils/utils';


const PasswordExpiredDialog = () => {
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    const _isMobile = IsMobile();
    const [open, setOpen] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [informationValues, setInformationValues] = useState({
        oldPassword: '',
        newPassword: '',
        repeatPassword: ''
    });


    const validateForm = (values) => {
        let errors = {};
        Object.keys(informationValues).forEach(el => {
            let _containsUpperCase = new RegExp("^(?=.*[A-Z])");
            let _containsLowerCase = new RegExp("^(?=.*[a-z])");
            let _containsNumbers = new RegExp("^(?=.*[0-9])");

            // eslint-disable-next-line no-useless-escape
            let _containsSpecial = new RegExp("(?=.*[-!@#\$%\^&\*])");
            let _minLength = new RegExp("(?=.{8,20})");

            if (values.oldPassword === values.newPassword) {
                errors['newPassword'] = 'password must be different from the previous one';
            }

            if (!_containsUpperCase.test(values.newPassword)) {
                errors['newPassword'] = 'Password must contain at least one upper case letter';
                errors['repeatPassword'] = 'undefined';
            } else if (!_containsLowerCase.test(values.newPassword)) {
                errors['newPassword'] = 'Password must contain at least one lower case letter';
                errors['repeatPassword'] = 'undefined';
            } else if (!_containsNumbers.test(values.newPassword)) {
                errors['newPassword'] = 'Password must contain at least one number';
                errors['repeatPassword'] = 'undefined';
            } else if (!_containsSpecial.test(values.newPassword)) {
                errors['newPassword'] = 'Password must contain at least one special letter (@#%&*-)';
                errors['repeatPassword'] = 'undefined';
            } else if (!_minLength.test(values.newPassword)) {
                errors['newPassword'] = 'Password must have at least 8 characters and maximum 20 characters';
                errors['repeatPassword'] = 'undefined';
            } else if (values.repeatPassword && values.newPassword !== values.repeatPassword) {
                errors['newPassword'] = 'Passwords are not the same';
                errors['repeatPassword'] = 'undefined';
            }

            if (!values.oldPassword) {
                errors['oldPassword'] = 'this field cannot be empty';
            }
        });
        return errors;
    }

    const onSubmit = (values) => {
        setLoading(true);
        let _toSend = {
            ...values,
            oldPassword: values.oldPassword.trim()
        }

        console.log(_toSend);
        
        CHANGE_USER_PASSWORD(_toSend).then((data) => {
            if (data.status === 200) {
                localStorage.removeItem('token');
                setLoading(false);
                window.location.href = window.location.origin;
            }
        }, error => {
            HandleErrorCatching(error, () => onSubmit(values), auth)
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            HandleErrorCatching(error, () => onSubmit(values), auth)
        });
    }

    return (

        <Dialog fullScreen={_isMobile} fullWidth={true} maxWidth={'sm'} open={open} onClose={() => setOpen(false)} disableBackdropClick>
            <DialogTitle disableTypography className={styles['dialogContainer__title']}>
                <h2>{'Insert a new password before continuing'}</h2>
            </DialogTitle>
            <Formik initialValues={informationValues} validate={validateForm} onSubmit={onSubmit} enableReinitialize>
                {({ submitForm }) => (
                    <>
                        <DialogContent dividers={true}>
                            <Form>
                                <div className={styles['formView__row']}>
                                    <FastField name="oldPassword" label="Old password" className={styles['formView__field']} component={ParagonTextField} variant="outlined" type="password" required />
                                </div>
                                <div className={styles['formView__row']}>
                                    <FastField name="newPassword" label="New password" className={styles['formView__field']} component={ParagonTextField} variant="outlined" type="password" required />
                                    {!_isMobile && <FastField name="repeatPassword" label="Repeat new password" className={styles['formView__field']} component={ParagonTextField} variant="outlined" type="password" required />}
                                </div>
                                {
                                    _isMobile &&
                                    <div className={styles['formView__row']}>
                                        <FastField name="repeatPassword" label="Repeat new password" className={styles['formView__field']} component={ParagonTextField} variant="outlined" type="password" required />
                                    </div>
                                }
                            </Form>
                        </DialogContent>
                        <DialogActions className={styles['formView__actionContainer']}>
                            <Button color="primary" onClick={() => { localStorage.removeItem('token'); window.location.href = window.location.origin; }} className={styles['formView__button']} variant={"outlined"}>{'Cancel'}</Button>
                            <Button disabled={loading} onClick={submitForm} color="primary" autoFocus variant={'contained'} className={styles['formView__button']}>{loading ? <CircularProgress size={24} /> : 'Confirm'}</Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog >
    )
}


export default PasswordExpiredDialog;