import React from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { useTranslation } from 'react-i18next';
import { FormDatePicker, FormField } from 'src/components';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';


export const StudyDetailsForm = ({ formik }) => {
    const { t } = useTranslation();
    return (
        <Grid container item md={8} sm={12} justify="center">
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className={creationStyle['container__Details']}>
                    <div className={creationStyle['container__RowFullWidth']}>
                        <FormField required helperText={t('studies:helper.name')} fullWidth className={creationStyle['container__Field']} formik={formik} name="name" label={t('studies:name')} />
                        <FormField required helperText={t('studies:helper.code')} fullWidth className={creationStyle['container__Field']} formik={formik} name="code" label={t('studies:code')} />
                    </div>

                    <div className={creationStyle['container__RowFullWidth']}>
                        <FormDatePicker required maxDate={formik.values['endDate'] || undefined} helperText={t('studies:helper.startDate')} fullWidth className={creationStyle['container__Field']} formik={formik} label={t('studies:startDate')} name="startDate" />
                        <FormDatePicker required minDate={formik.values['startDate'] || undefined} helperText={t('studies:helper.endDate')} fullWidth className={creationStyle['container__Field']} formik={formik} label={t('studies:endDate')} name="endDate" />
                    </div>


                    <div className={creationStyle['container__RowFullWidth']}>
                        <FormField helperText={t('studies:helper.protocolName')} style={{marginBottom: '15px'}} fullWidth formik={formik} name="protocolName" label={t('studies:protocolName')} />
                    </div>

                    <div className={creationStyle['container__RowFullWidth']}>
                        <FormField helperText={t('studies:helper.description')} fullWidth rows={5} multiline formik={formik} name="description" label={t('studies:description')} />
                    </div>
                </div>
            </MuiPickersUtilsProvider>
        </Grid>
    )
}