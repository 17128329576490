import React, { useState, useEffect } from 'react';
import styles from './tutorial.module.scss';
import Tour from "reactour";
import { Button } from '@material-ui/core';
import _steps from './tourControllerReferences.js';
import { PUT_USER_CONFIGURATION } from 'src/pages/Profile/services';
import { useAuth } from 'src/context/auth-context';
import { HandleErrorCatching } from '../../utils/utils';
import { GET_TUTORIAL } from 'src/roots/AuthenticatedApp/services';
import { useTranslation } from 'react-i18next';


export const ShowHelpIcon = (section) => {
    let roles = useAuth()?._data?.roles;
    let _tutorialSteps = retriveStep(_steps, section.split('__'));

    _tutorialSteps = _tutorialSteps.filter((el, index) => {

        if ((el?.role && roles?.indexOf(el.role) !== -1) || !el.role) {
            return el;
        }
        return null;
    })

    if (_tutorialSteps.length === 1 && _tutorialSteps[0].selector.includes('#followTutorial')) {
        _tutorialSteps = [];
    }

    return _tutorialSteps.length;
}


const retriveStep = (json, stepPath) => {
    if (stepPath.length === 1 && json[stepPath[0]]) {
        return json[stepPath[0]];
    } else {
        const _stepPath = [...stepPath];
        _stepPath.splice(0, 1);
        if (json && json[stepPath[0]])
            return retriveStep(json[stepPath[0]], _stepPath);
        else
            return false
    }
}

const Tutorial = ({ section, openOnLoad, onClose, openTutorial }) => {
    const [openTour, setOpenTour] = useState(false);
    const [steps, setSteps] = useState([]);
    const [index, setIndex] = useState(0);
    const [userConfiguration, setUserConfiguration] = useState({});
    const auth = useAuth();
    const profile = useAuth()?._data?.profile;
    const { t } = useTranslation();

    const setTutorialDone = () => {
        let _userConfiguration = { ...userConfiguration };
        if (!_userConfiguration.tutorials[section.split('__')[0]] || _userConfiguration.tutorials[section.split('__')[0]][section.split('__')[1]] !== "DONE") {
            if (!_userConfiguration.tutorials[section.split('__')[0]]) {
                _userConfiguration.tutorials[section.split('__')[0]] = {};
                _userConfiguration.tutorials[section.split('__')[0]][section.split('__')[1]] = "DONE";
            } else {
                _userConfiguration.tutorials[section.split('__')[0]][section.split('__')[1]] = "DONE";
            }

            PUT_USER_CONFIGURATION(_userConfiguration).catch(error => HandleErrorCatching(error, setTutorialDone, auth));
        }

        if (onClose) {
            onClose();
        }

        setOpenTour(false);
    }

    const skipTutorial = () => {
        let _userConfiguration = { ...userConfiguration };

        if (!_userConfiguration.tutorials[section.split('__')[0]] || _userConfiguration.tutorials[section.split('__')[0]][section.split('__')[1]] !== "DONE") {
            if (!_userConfiguration.tutorials[section.split('__')[0]]) {
                _userConfiguration.tutorials[section.split('__')[0]] = {};
                _userConfiguration.tutorials[section.split('__')[0]][section.split('__')[1]] = "SKIP";
            } else {
                _userConfiguration.tutorials[section.split('__')[0]][section.split('__')[1]] = "SKIP";
            }

            PUT_USER_CONFIGURATION(_userConfiguration).catch(error => HandleErrorCatching(error, skipTutorial, auth));
        }

        if (onClose) {
            onClose();
        }

        setOpenTour(false);

    }

    // const reloadUserConfiguration = () => {
    //     GET_USER_CONFIGURATION().then(configuration => {
    //         auth.setState({ config: JSON.parse(configuration.data.json) });
    //     }, error => HandleErrorCatching(error, reloadUserConfiguration, auth)).catch(error => HandleErrorCatching(error, reloadUserConfiguration, auth))
    // }

    const loadSteps = async () => {
        let roles = auth?._data?.roles;
        let _userConfiguration = auth?._data?.config || {};


        let _tutorialSteps = retriveStep(_steps, section.split('__'));
        let stepStatus = retriveStep(_userConfiguration.tutorials, section.split('__'));

        if (_tutorialSteps.length)
            _tutorialSteps = _tutorialSteps.filter((el, index) => {

                if ((el?.role && roles?.indexOf(el.role) !== -1) || !el.role) {
                    return el;
                }
                return null;
            });

        await GET_TUTORIAL({ code: section.split('__')[0] }).then(data => {
            data.data.forEach(translate => {
                if (_tutorialSteps.length)
                    _tutorialSteps.forEach(el => {
                            el.content = t(el.content);
                    })
            })
            if (_tutorialSteps.length)
                _tutorialSteps.forEach((el, index) => {
                    if (el.firstAccess && stepStatus) {
                        _tutorialSteps.splice(index, 1);
                    }
                });

            if (_tutorialSteps.length === 1 && _tutorialSteps[0].selector.includes('#followTutorial')) {
                _tutorialSteps = [];
            }
            setSteps(_tutorialSteps);
        }, error => HandleErrorCatching(error, loadSteps, auth)).catch(error => HandleErrorCatching(error, loadSteps, auth))

    }

    useEffect(() => {
        loadSteps();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [section])


    useEffect(() => {
        let _userConfiguration = auth?._data?.config || {};

        if (!_userConfiguration.tutorials) {
            _userConfiguration['tutorials'] = {};
        }

        let _retriveStep = retriveStep(_userConfiguration.tutorials, section.split('__'));
        if (Boolean(openOnLoad) || !_retriveStep || openTutorial === true) {
            loadSteps();
            setOpenTour(true);
        }
        setUserConfiguration(_userConfiguration)
    },
        // eslint-disable-next-line
        []);

    useEffect(() => {
        if (openTutorial === true) {
            setOpenTour(true);
            loadSteps();
        } else {
            setSteps([]);
        }
    },
        // eslint-disable-next-line
        [openTutorial])

    return (
        steps.length > 0 && <Tour
            accentColor={"#259ECB"}
            className={styles.text}
            steps={steps}
            disableKeyboardNavigation={['esc']}
            getCurrentStep={(curr) => setIndex(curr)}
            rounded={5}
            showCloseButton={false}
            isOpen={openTour}
            closeWithMask={false}
            onRequestClose={() => setOpenTour(false)}
            nextButton={profile?.firstLogin && index === 0 && <Button disableFocusRipple component="div" color="primary" variant="outlined" className={styles['tutorialButton']}> Proceed</Button>}
            lastStepNextButton={<Button onClick={setTutorialDone} component="div" color="primary" variant="outlined" className={styles['tutorialButton']}>Done!</Button>}
            prevButton={index === 0 && <Button onClick={skipTutorial} disableFocusRipple component="div" color="primary" variant="outlined" className={styles['tutorialButton']}> {profile?.firstLogin ? 'Skip' : 'Close'}</Button>}
        />
    )
}

export default Tutorial;