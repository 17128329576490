import moment from 'moment';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { DetailLoader, Sidebar, TopBar } from 'src/components';
import PasswordExpiredDialog from 'src/roots/AuthenticatedApp/components/generalComponents/passwordExpiredDialog';
import { routes } from 'src/services/routing-service';
import { ErrorContainer } from '..';
import styles from './AuthenticatedAppContainer.module.scss';

import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/ne';


export const AuthenticatedAppContainer = ({ openNotifier, roles, passwordExpired, displayError }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const { i18n } = useTranslation();

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n])

    return (
        passwordExpired ? <PasswordExpiredDialog /> : <Router>
            <div className={styles['authenticatedAppContainer']} id="mainView">
                <div className={styles['authenticatedAppContainer__sidebarContainer']}>
                    <Sidebar menuOpen={openMenu} closeMenu={() => setOpenMenu(false)} />
                </div>
                <div className={styles[`authenticatedAppContainer__viewContainer`]}>
                    <div className={styles['authenticatedAppContainer__viewContainer-view']}>
                        <div className={styles['authenticatedAppContainer__viewContainer-topbar']}>
                            <TopBar openMenu={() => setOpenMenu(true)} />
                        </div>
                        <Suspense fallback={<DetailLoader />}>
                            {displayError !== null ? <ErrorContainer error={displayError} /> : <Switch>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        render={(props) => (!route.role || roles.includes(route.role)) && <route.view {...props} openNotifier={openNotifier} roles={roles} />}
                                    />
                                ))}
                            </Switch>}
                        </Suspense>
                    </div>
                </div>
            </div>
        </Router>
    )
}