import React from 'react';
import styles from './notifier.module.scss';
import { IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { useTranslation } from 'react-i18next';

const Notifier = ({store, closeNotifier}) => {
    const { t } = useTranslation();
    
    return (
        store?.displayNotifier ? <div className={styles['notifierContainer']}>
            <div className={styles[`notifierContainer__notifier-${store.status}`]}>
                <div className={styles[`notifierContainer__notifier-${store.status}-icon`]}>
                    {store.status === 'success' ? <CheckCircleOutlineIcon /> : store.status === 'warning' ? <WarningIcon /> : <ErrorIcon />}
                </div>
                <div className={styles[`notifierContainer__notifier-${store.status}-message`]}>
                    { typeof store.message === 'string' ? t(store.message.replace('paragon.', 'response:')) : store.message ? store.message : 'Server non raggiungibile'}
                </div>
                <div className={styles[`notifierContainer__notifier-${store.status}-close`]}>
                    <IconButton classes={{ label: styles[`notifierContainer__notifier-${store.status}-white`] }} size="small" onClick={() => closeNotifier()}><CloseIcon /></IconButton>
                </div>
            </div>
        </div>
            : null
    )
}

export default Notifier;