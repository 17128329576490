import HttpRequestsHandler from "src/services/http-requests-handler";

const path = {
    DOWNLOAD_DOCUMENT:'/api/:section/:sectionUuid/document/:uuid/download',
    DELETE_DOCUMENT:'/api/:section/:sectionUuid/document/:uuid'
}

export const DOWNLOAD_DOCUMENT = (section, sectionUuid, uuid) => {
        return HttpRequestsHandler("DOWNLOAD", path.DOWNLOAD_DOCUMENT.replace(/:sectionUuid/gi, sectionUuid).replace(/:section/gi, section).replace(/:uuid/gi, uuid), null);
}

export const DELETE_DOCUMENT = (section, sectionUuid, uuid, params) => {
        return HttpRequestsHandler("DELETE", path.DELETE_DOCUMENT.replace(/:sectionUuid/gi, sectionUuid).replace(/:section/gi, section).replace(/:uuid/gi, uuid), params);
}