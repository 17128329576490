import React, { createContext, useContext, useState } from 'react';

export const NotifierContext = createContext();

export const NotifierProvider = ({ children }) => {
  const [displayNotifier, setDisplayNotifier] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const openNotifier = (status, message, timer) => {
    setDisplayNotifier(true);
    setStatus(status);
    setMessage(message);
    setTimeout(() => {
      closeNotifier();
    }, timer);
  }

  const closeNotifier = () => {
    setDisplayNotifier(false);
    setStatus("");
    setMessage("");
  }

  return (
    <NotifierContext.Provider value={{ openNotifier, closeNotifier, status, message, displayNotifier}}>
      {children}
    </NotifierContext.Provider>
  );
};

export const useNotifier = () => {
  const context = useContext(NotifierContext);
  if (context === undefined) {
      throw new Error(`useNotifier must be used within a NotifierProvider`)
  }

  return context.openNotifier;
}