import { Button, CircularProgress, Grid, Tabs } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import creationStyle from 'src/styles/creation.module.scss';
import { LanguagesConfiguration } from './sections/LanguagesConfiguration';
import { PatientsConfiguration } from './sections/PatientsConfiguration';
import { PaymentMethodsConfiguration } from './sections/PaymentMethodsConfiguration';
import { ReimbursementTypesConfiguration } from './sections/ReimbursementTypesConfiguration';
import { ServiceTypesConfiguration } from './sections/ServiceTypesConfiguration';
import { StudyConfiguration } from './sections/StudyConfiguration';
import { SuppliersConfiguration } from './sections/SuppliersConfiguration';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { animated, config, Transition } from 'react-spring';
import { useHistory } from 'react-router-dom';
import { AnimatedGrid } from 'src/components';
import { AnimatedTab } from 'src/components/Transitions/AnimatedComponents';
import { useSpring } from '@react-spring/core';
import { StudySchedulingGridConfiguration } from './sections/StudySchedulingGridConfiguration';
import { StudyDebitCardsConfiguration } from './sections/StudyDebitCards';
import { useRoles } from 'src/hooks/User/useRoles';
import { TutorialsConfiguration } from './sections/TutorialsConfiguration';


export const StudyConfigurationForm = ({ formik, isSaving, savingStatus, uuid, study, languages, schedulingGrid, debitCards }) => {
    const permissions = useRoles();
    const { t } = useTranslation();
    const history = useHistory();
    const [index, setIndex] = useState(0);
    const tabs = [
        {
            label: t('studies:study'),
            key: 'configuration',
            view: <StudyConfiguration formik={formik} />
        },
        {
            label: `${t('patients:patients')}${formik.values.PATIENT_CODE_AUTO_GENERATED ? '*': ''}`,
            key: 'configuration',
            view: <PatientsConfiguration formik={formik} />
        },
        {
            label: t('suppliers:suppliers'),
            key: 'supplierList',
            view: <SuppliersConfiguration formik={formik} />
        },
        {
            label: t('services:serviceTypes'),
            key: 'serviceTypeList',
            view: <ServiceTypesConfiguration formik={formik} />
        },
        {
            label: t('reimbursements:reimbursementTypes'),
            key: 'reimbursementTypeList',
            view: <ReimbursementTypesConfiguration formik={formik} />
        },
        {
            label: t('studies:paymentMethods'),
            key: 'paymentMethodRequestList',
            view: <PaymentMethodsConfiguration formik={formik} />
        },
        {
            label: t('patients:languages'),
            key: 'languageList',
            view: <LanguagesConfiguration formik={formik} study={study} languages={languages} />
        },
        {
            label: t('studies:schedulingGrid'),
            key: 'schedulingGrid',
            hidden: !formik.values.HAS_SCHEDULING_GRID,
            view: <StudySchedulingGridConfiguration formik={formik} schedulingGrid={schedulingGrid} />
        },
        {
            label: t('studies:debitCards'),
            key: 'debitCards',
            hidden: !formik.values.HAS_B4B_INTEGRATION,
            view: <StudyDebitCardsConfiguration formik={formik} debitCards={debitCards} />
        },
        {
            label: t('studies:tutorials'),
            key: 'tutorials',
            hidden: !permissions.hasRole('PARAGON_STAFF'),
            view: <TutorialsConfiguration formik={formik} languages={languages}/>
        }
    ];

    const schedulingGridStyle = useSpring({ opacity: formik.values.HAS_SCHEDULING_GRID ? index === 7 ? 1 : 0.7 : 0 });


    return (
        <Grid container direction="row">
            <Transition
                items={isSaving}
                immediate={false}
                from={{ flexGrow: 0, maxWidth: '25%', flexBasis: '25%', transform: 'translateX(0%)' }}
                enter={{ flexGrow: 0, maxWidth: '50%', flexBasis: '50%', transform: 'translateX(50%)' }}

                config={config.default}>
                {(styles, item) =>
                    <AnimatedGrid item sm={3} style={isSaving ? styles : null} className={creationStyle['container__SectionLeft']}>
                        <div className={creationStyle['container__SectionBackground']}>
                            <div className={creationStyle['container__SectionTitle']}>{t('generic:sections')}</div>
                            <div>
                                <Tabs
                                    value={index}
                                    onChange={(event, value) => setIndex(value)}
                                    orientation="vertical"
                                    variant="scrollable"
                                    classes={{
                                        indicator: isSaving ? creationStyle['container__SectionTabIndicatorUnset'] : creationStyle['container__SectionTabIndicator']
                                    }}
                                >
                                    {
                                        tabs.map((tab, tabIndex) => !tab.hidden && <AnimatedTab style={tab.key === 'schedulingGrid' ? schedulingGridStyle : null} key={tabIndex} value={tabIndex} classes={{
                                            wrapper: clsx({
                                                [creationStyle['container__SectionTabSelected']]: index === tabIndex,
                                                [creationStyle['container__SectionTab']]: tabIndex !== index
                                            })
                                        }} label={
                                            isSaving ?
                                                <div className={creationStyle['container__SectionTabLoading']}>
                                                    <div className={clsx({
                                                        [creationStyle['container__SectionTabLoadingLabel']]: savingStatus[tab.key] !== 'DONE',
                                                        [creationStyle['container__SectionTabLoadingLabelCompleted']]: savingStatus[tab.key] === 'DONE'
                                                    })}>
                                                        {tab.label}
                                                    </div>
                                                    {savingStatus[tab.key] === 'DONE' ? <CheckCircleRoundedIcon className={creationStyle['container__SectionTabLoadingIconCompleted']} /> : <CircularProgress className={creationStyle['container__SectionTabLoadingIconPending']} size="1.25rem" />}
                                                </div>
                                                :
                                                tab.label
                                        } />)
                                    }
                                </Tabs>
                                <Transition immediate={false} items={savingStatus['languageList']} from={{ y: 0, opacity: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center' }} enter={{ y: 50, opacity: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center' }} config={{ duration: 500, ...config.molasses }}>
                                    {(buttonStyle, item) =>
                                        savingStatus['languageList'] === 'DONE' && <animated.div style={buttonStyle}>
                                            <Button onClick={() => history.push(`/studies/detail/${uuid}`)} color="primary" variant="contained">{t('generic:gotoDetail')}</Button>
                                        </animated.div>
                                    }
                                </Transition>
                            </div>
                        </div>
                    </AnimatedGrid>
                }
            </Transition>
            {!isSaving && <Grid item sm={9} className={creationStyle['container__SectionRight']}>
                <div className={creationStyle['container__SectionBackground']}>
                    {!isSaving && tabs[index]?.view}
                </div>
            </Grid>}
        </Grid>
    )
}