import React from 'react';
import styles from './notAuthorized.module.scss';
import { ReactComponent as AccessDenied } from 'assets/403.svg';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useAuth } from 'src/context/auth-context';



export const NotAuthorizedPage = () => {
    const history = useHistory();
    const auth = useAuth();


    const handleClick = () => {
        auth.handleError(null);
        history.push('/dashboard');
    }
    return (
        <div className={styles['container']}>
            <div className={styles['container__icon']}><AccessDenied /></div>
            <div className={styles['container__title']}>We are sorry</div>
            <div className={styles['container__secondary']}>The page you're trying to access has restricted access.</div>
            <div className={styles['container__secondary']}>Please refer to your system administrator</div>

            <div className={styles['container__button']}>
                <Button color="primary" variant="contained" onClick={handleClick}>Go back to homepage</Button>
            </div>
        </div>
    )
}