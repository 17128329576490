import HttpRequestsHandler from "src/services/http-requests-handler";

const path = {
    GET_STUDY_DETAIL: '/api/study/:uuid',
    PUT_STUDY: '/api/study/:uuid',
    ACTIVATE_STUDY: '/api/study/:uuid/activate',
    COMPLETE_STUDY: '/api/study/:uuid/complete',
    GET_STUDY_CONFIGURATION: '/api/study/:uuid/configuration',
    PUT_STUDY_CONFIGURATION: '/api/study/:uuid/configuration',
    ASSIGN_SUPPLIER: '/api/study/:uuid/supplier',
    ASSIGN_SERVICETYPE: '/api/study/:uuid/servicetype',
    ASSIGN_REIMBURSEMENT_TYPE:'/api/study/:uuidStudy/reimbursementtype',
    ASSIGN_PAYMENT_METHOD: '/api/study/:studyUuid/paymentmethod',
    ASSIGN_STUDY_LANGUAGE: '/api/study/:studyUuid/language',
    GET_FOLLOW_STUDY: '/api/study/:uuid/followcheck',
    FOLLOW_STUDY: '/api/study/:uuid/follow',
    UNFOLLOW_STUDY: '/api/study/:uuid/unfollow',
    GET_STUDY_DOCUMENTS: '/api/study/:uuid/document/all',
    UPLOAD_STUDY_DOCUMENT: '/api/study/:uuid/document',
    POST_SCHEDULING_GRID: '/api/schedulingvisit',
    PUT_SCHEDULING_GRID: '/api/schedulingvisit/:uuid'
}

export const GET_STUDY_DETAIL = (uuid) => {
    return HttpRequestsHandler("GET", path.GET_STUDY_DETAIL.replace(/:uuid/gi, uuid), null);
}

export const PUT_STUDY = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.PUT_STUDY.replace(/:uuid/gi, uuid), null, body);
}

export const ACTIVATE_STUDY = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.ACTIVATE_STUDY.replace(/:uuid/gi, uuid), null, body);
}

export const COMPLETE_STUDY = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.COMPLETE_STUDY.replace(/:uuid/gi, uuid), null, body);
}

export const GET_STUDY_CONFIGURATION = (uuid) => {
    return HttpRequestsHandler("GET", path.GET_STUDY_CONFIGURATION.replace(/:uuid/gi, uuid), null);
}

export const PUT_STUDY_CONFIGURATION = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.PUT_STUDY_CONFIGURATION.replace(/:uuid/gi, uuid), null, body);
}

export const DELETE_STUDY = (uuid) => {
    return HttpRequestsHandler("DELETE", path.GET_STUDY_DETAIL.replace(/:uuid/gi, uuid), null, null);
}

export const ASSIGN_SUPPLIER = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_SUPPLIER.replace(/:uuid/gi, uuid), null, body);
}

export const UNASSIGN_SUPPLIER = (uuid, body) => {
    return HttpRequestsHandler("DELETE", path.ASSIGN_SUPPLIER.replace(/:uuid/gi, uuid), null, body);
}

export const ASSIGN_SERVICETYPE = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_SERVICETYPE.replace(/:uuid/gi, uuid), null, body);
}

export const UNASSIGN_SERVICETYPE = (uuid, body) => {
    return HttpRequestsHandler("DELETE", path.ASSIGN_SERVICETYPE.replace(/:uuid/gi, uuid), null, body);
}

export const ASSIGN_REIMBURSEMENT_TYPE = (uuidStudy, body) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_REIMBURSEMENT_TYPE.replace(/:uuidStudy/gi, uuidStudy), null, body);
}

export const UNASSIGN_REIMBURSEMENT_TYPE = (uuidStudy, body) => {
    return HttpRequestsHandler("DELETE", path.ASSIGN_REIMBURSEMENT_TYPE.replace(/:uuidStudy/gi, uuidStudy), null, body);
}

export const UNASSIGN_PAYMENT_METHOD = (uuid, body) => {
    return HttpRequestsHandler("DELETE", path.ASSIGN_PAYMENT_METHOD.replace(/:studyUuid/gi, uuid), null, body);
}

export const ASSIGN_PAYMENT_METHOD = (studyUuid, body) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_PAYMENT_METHOD.replace(/:studyUuid/gi, studyUuid), null, body);
}

export const ASSIGN_STUDY_LANGUAGE = (studyUuid, body) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_STUDY_LANGUAGE.replace(/:studyUuid/gi, studyUuid), null, body);
}

export const UNASSIGN_STUDY_LANGUAGE = (studyUuid, body) => {

    return HttpRequestsHandler("DELETE", path.ASSIGN_STUDY_LANGUAGE.replace(/:studyUuid/gi, studyUuid), null, body);
}

export const FOLLOW_STUDY = (uuid) => {

    return HttpRequestsHandler("PUT", path.FOLLOW_STUDY.replace(/:uuid/gi, uuid), null, null);
}

export const UNFOLLOW_STUDY = (uuid) => {

    return HttpRequestsHandler("PUT", path.UNFOLLOW_STUDY.replace(/:uuid/gi, uuid), null, null);
}

export const GET_FOLLOW_STUDY = (uuid) => {

    return HttpRequestsHandler("GET", path.GET_FOLLOW_STUDY.replace(/:uuid/gi, uuid), null);
}

export const GET_STUDY_DOCUMENTS = (uuid, params) => {
    return HttpRequestsHandler("GET", path.GET_STUDY_DOCUMENTS.replace(/:uuid/gi, uuid), params);
}

export const UPLOAD_STUDY_DOCUMENT = (uuid, body) => {
    return HttpRequestsHandler("UPLOAD", path.UPLOAD_STUDY_DOCUMENT.replace(/:uuid/gi, uuid), null, body);
}

export const POST_SCHEDULING_GRID = (body) => {
    return HttpRequestsHandler("POST", path.POST_SCHEDULING_GRID, null, body);
}

export const PUT_SCHEDULING_GRID = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.PUT_SCHEDULING_GRID.replace(/:uuid/gi, uuid), null, body);
}

export const DELETE_SCHEDULING_GRID = (uuid) => {
    return HttpRequestsHandler("DELETE", path.PUT_SCHEDULING_GRID.replace(/:uuid/gi, uuid), null, null);
}