import React from 'react';
import { Redirect } from 'react-router';
import { useAuth } from 'src/context/auth-context';
import { LoginForm } from './components/LoginForm';
import { TwoFactorAuthentication } from './components/TwoFactorAuthentication';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

export function LoginContainer() {
    const auth = useAuth();
    

    return (
        auth?._data?.token ? <Redirect to="/" /> :
            <Router>
                <Switch>
                    {
                        auth.requestTFA ? <Route path="/" render={() => <TwoFactorAuthentication auth={auth} />} /> : <Route path="/" render={() => <LoginForm auth={auth} />} />
                    }
                </Switch>
            </Router>
    );
}