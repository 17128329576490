import React, { useEffect, useState } from 'react';
import mainStyle from 'src/styles/main.module.scss';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Breadcrumbs, IconButton } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

export const BreadcrumbsHeader = ({ detailTitle, enableClose, enableRedirect }) => {
    const currentLocation = useLocation();
    const history = useHistory();
    const [paths, setPaths] = useState([]);

    useEffect(() => {
        if (currentLocation.pathname) {
            let _splittedLocation = currentLocation.pathname.split('/');
            _splittedLocation.shift();
            setPaths(_splittedLocation);
        }
    }, [currentLocation]);

    return (
        <div className={mainStyle['container__Breadcrumbs']}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {
                    paths.map((path, index) => ['detail', 'configuration', 'edit'].indexOf(path) <= -1 ?
                            index === paths.length - 1 ?
                                <span key={index} className={mainStyle['container__BreadcrumbsPathActive']}>{['detail', 'configuration', 'edit', 'clone'].indexOf(paths[index - 1]) > -1 && detailTitle ? 
                                enableRedirect ? 
                                <Link className={mainStyle['container__BreadcrumbsPath']} key={index} color="inherit" to={enableRedirect}>
                                    {detailTitle}
                                </Link>
                                : detailTitle : path.toUpperCase()}</span>
                                :
                                ['clone'].indexOf(path) > -1 ? path.toUpperCase() : <Link className={mainStyle['container__BreadcrumbsPath']} key={index} color="inherit" to={`/${path}`}>
                                    {path.toUpperCase()}
                                </Link> : null)
                }
            </Breadcrumbs>
            {enableClose && <div className={mainStyle['container__BreadcrumbsClose']}>
                <IconButton size="small" onClick={history.goBack}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            }
        </div>
    )
}