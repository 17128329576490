import React from 'react';
import styles from './sidebar.module.scss';
import { Link } from 'react-router-dom';
import { Button, IconButton, Badge, Drawer } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import VideoTutorialDialog from 'src/roots/AuthenticatedApp/components/generalComponents/videoTutorialDialog/videoTutorialDialog';
import { ExitButton } from './exitDialog';
import { useTranslation } from 'react-i18next';

export const MobileSidebar = ({ signOut, countNotification, buttonList, selectedView, setSelectedView, openMenu, closeMenu, accountType}) => {
    const { t } = useTranslation();

    return (
        <Drawer className={styles['sidebarContainer']} anchor="left" open={openMenu} classes={{ paper: styles['sidebarContainer__root'] }}>
            <div className={styles['sidebarContainer__icon-back']}>
                <IconButton className={styles['sidebarContainer__icon-button']} size="small" onClick={closeMenu}>
                    <ChevronLeftIcon fontSize="large" />
                </IconButton>
            </div>
            <div className={styles['menuContainer']}>
                {
                    buttonList.map(({ icon, title, linkTo, section }, index) =>
                        section === 'default' && (
                            <Link onClick={() => { setSelectedView(linkTo); closeMenu(); }} to={'/' + linkTo} key={index} className={linkTo === selectedView ? styles['menuContainer__activeLink'] : styles['menuContainer__link']}>
                                <Button key={title} className={styles['menuContainer__menuExpanded']}>
                                    {index === 0 ? <Badge badgeContent={parseInt(countNotification)} color="error" className={styles['menuContainer__menuExpanded-Icon']}>{icon}</Badge> : <div className={styles['menuContainer__menuExpanded-Icon']}>{icon}</div>}
                                    <div className={styles['menuContainer__menuExpanded-Title']}>{t(`sidebar:${title}`)}</div>
                                </Button>
                            </Link>
                        )
                    )
                }
                {
                    buttonList.map(el => el['section']).indexOf('administration') > -1 ?
                        <div className={styles['menuContainer-administration']}>
                            <div className={styles['menuContainer-administration__title']} style={openMenu ? null : { opacity: 0 }}>{t('sidebar:administration')}</div>
                            {
                                buttonList.map(({ icon, title, linkTo, section }, index) =>
                                    section === 'administration' && (
                                        <Link style={{ paddingBottom: 25 }} onClick={() => { setSelectedView(linkTo); closeMenu(); }} to={'/' + linkTo} key={index} className={linkTo === selectedView ? styles['menuContainer__activeLink'] : styles['menuContainer__link']}>
                                            <Button key={title} className={styles['menuContainer__menuExpanded']}>
                                                <div className={styles['menuContainer__menuExpanded-Icon']}>{icon}</div>
                                                <div className={styles['menuContainer__menuExpanded-Title']}>{t(`sidebar:${title}`)}</div>
                                            </Button>
                                        </Link>
                                    )
                                )
                            }
                        </div>
                        : null
                }
                {accountType !== 'SPONSOR' && <div className={styles['menuContainer-administration']}>
                    <div className={styles['menuContainer-administration__title']} style={openMenu ? null : { opacity: 0 }}>{t('sidebar:others')}</div>
                    <VideoTutorialDialog openMenu={openMenu} className={styles['menuContainer__menuExpanded']} customStyles={{ textButton: styles['menuContainer__menuExpanded-Title'], icon: styles['menuContainer__menuExpanded-Icon'] }} />

                    {
                        buttonList.map(({ icon, title, linkTo, section }, index) =>
                            section === 'others' && (
                                <Link onClick={() => setSelectedView(linkTo)} to={'/' + linkTo} key={index} className={linkTo === selectedView ? styles['menuContainer__activeLink'] : styles['menuContainer__link']}>
                                    <Button key={title} className={styles['menuContainer__menuExpanded']}>
                                        <div className={styles['menuContainer__menuExpanded-Icon']}>{icon}</div>
                                        {openMenu ? <div className={styles['menuContainer__menuExpanded-Title']}>{t(`sidebar:${title}`)}</div> : null}
                                    </Button>
                                </Link>
                            )
                        )
                    }
                </div>}

                <div className={styles['menuContainer__logoutContainer']}>
                    <div className={styles['menuContainer__logoutContainer-button']}>
                        <ExitButton styles={styles} openMenu={true} logout={signOut} />
                    </div>
                </div>
            </div>
        </Drawer>
    )
}