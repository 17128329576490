import React, { useState } from 'react';
import { DatePicker } from "@material-ui/pickers";
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { getIn } from 'formik';

export const FormDatePicker = ({ formik, helperText, InputProps, onlyNumbers, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (value) => {
        formik.setFieldValue(rest.name, value);
    }

    const handleClear = (e) => {
        e.stopPropagation();
        formik.setFieldValue(rest.name, null);
    }

    const handleClick = (e) => {
        setIsOpen(true);
    }

    return (
        <DatePicker open={isOpen} value={getIn(formik.values, rest.name)} helperText={formik.touched[rest.name] && formik.errors[rest.name] ? formik.errors[rest.name] : helperText} error={formik.touched[rest.name] && Boolean(formik.errors[rest.name])}
            variant="inline" inputVariant="outlined" color="primary" onChange={handleChange} onBlur={formik.handleBlur}
            {...rest} views={["year", "month", "date"]} openTo="year" clearable="true" InputProps={{
                endAdornment: formik.values[rest.name] ?
                    <IconButton style={{ marginRight: '5px' }} size="small" onClick={handleClear}>
                        <ClearIcon />
                    </IconButton> : <IconButton style={{ marginRight: '5px' }} size="small" onClick={handleClick}>
                        <CalendarTodayIcon />
                    </IconButton>,
                    ...InputProps
            }} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}  format="DD/MM/YYYY"/>
    )
}