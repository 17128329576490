import axios from 'axios';

/**
 * @param  {} type - GET, POST, PUT, DELETE
 * @param  {} url - url api
 * @param  {} params - params of url
 * @param  {} body - body for post otherwise null
 * @param  {} disableToken - remove auth token from header
 */
const HttpRequestsHandler = async(type, url, params, body, disableToken, headers) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    let _token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = _token;
    axios.defaults.headers.common['Accept-Language'] = 'en';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Accept'] = 'application/json';

    if(disableToken){
        delete axios.defaults.headers.common['Authorization'];
    }

    switch (type) {
        case 'GET':
                return await axios.get(url, { params: params, headers: headers });
        case 'POST':
            return axios.post(url, body, {headers: headers});
        case 'PUT':
            return axios.put(url, body, {
                params: params,
                headers: headers
            });
        case 'UPLOAD':
            return axios({
                method: 'post',
                url: url,
                data: body,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        case 'DOWNLOAD':
            return axios({
                url: url,
                params: params,
                method: 'GET',
                responseType: 'blob'
            });
        case 'DELETE':
            return axios.delete(url, {
                data: body,
                params: params
            });
        default:
            return null;
    }
}


export default HttpRequestsHandler;