import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { useTranslation } from 'react-i18next';
import { preventClose } from 'src/components/utils';

export const ExitButton = ({ styles, openMenu, logout }) => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        window.removeEventListener('beforeunload', preventClose, false);
        logout();
    }

    return (
        <div>
            <Button className={styles[`menuContainer__menu${openMenu ? 'Expanded' : ''}`]} onClick={handleClickOpen}>
                <div className={styles[`menuContainer__menu${openMenu ? 'Expanded' : ''}-Icon`]}><MeetingRoomIcon /></div>
                {openMenu ? <div className={styles['menuContainer__menu-Title']}>{t('sidebar:logout')}</div> : null}
            </Button>
            <Dialog
                open={open}
                maxWidth="xs"
                fullWidth
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('sidebar:logoutConfirmation')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t('generic:cancel')}
                    </Button>
                    <Button onClick={handleLogout} color="primary" autoFocus variant="contained">
                        {t('generic:confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
