const _steps = {
    "studies": {
        "detail": [
            {
                "selector": "#followTutorial",
                "content": "studies:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#supplierAssign",
                "content": "studies:tutorial.detail.supplierAssign",
                "role": "ROLE_ICARE_STUDY_SUPPLIER_ASSIGN"
            },
            {
                "selector": "#siteCreate",
                "content": "studies:tutorial.detail.siteCreate",
                "role": "ROLE_ICARE_SITE_CREATE"
            },
            {
                "selector": "#studyDelete",
                "content": "studies:tutorial.detail.delete",
                "role": "ROLE_ICARE_STUDY_DELETE"
            },
            {
                "selector": "#studyEdit",
                "content": "studies:tutorial.detail.edit",
                "role": "ROLE_ICARE_STUDY_EDIT"
            },
            {
                "selector": "#sites",
                "content": "studies:tutorial.detail.sites",
                "role": "ROLE_ICARE_SITE_READ_ALL"
            },
            {
                "selector": "#studycoordinators",
                "content": "studies:tutorial.detail.studycoordinators",
                "role": "ROLE_ICARE_STUDY_COORDINATOR_READ_ALL"
            },
            {
                "selector": "#patients",
                "content": "studies:tutorial.detail.patients",
                "role": "ROLE_ICARE_PATIENT_READ_ALL"
            },
            {
                "selector": "#suppliers",
                "content": "studies:tutorial.detail.suppliers",
                "role": "ROLE_ICARE_SUPPLIER_READ_ALL"
            },
            {
                "selector": "#services",
                "content": "studies:tutorial.detail.services",
                "role": "ROLE_ICARE_SERVICE_READ_ALL"
            },
            {
                "selector": "#reimbursements",
                "content": "studies:tutorial.detail.reimbursements",
                "role": "ROLE_ICARE_STUDY_REIMBURSEMENTTYPE_ASSIGN"
            },
            {
                "selector": "#payments",
                "content": "studies:tutorial.detail.payments",
                "role": "ROLE_ICARE_STUDY_PAYMENTMETHOD_ASSIGN"
            },
            {
                "selector": "#documents",
                "content": "studies:tutorial.detail.documents",
                "role": "ROLE_ICARE_STUDY_DOCUMENT_READ_ALL"
            }

        ],
        "table": [
            {
                "selector": "#followTutorial",
                "content": "studies:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#changeTableView",
                "content": "studies:tutorial.table.changeView"
            },
            {
                "selector": "#searchInput",
                "content": "studies:tutorial.table.searchInput"
            },
            {
                "selector": "#filterButton",
                "content": "studies:tutorial.table.filterButton"
            },
            {
                "selector": "#create",
                "content": "studies:tutorial.table.create",
                "role": "ROLE_ICARE_STUDY_CREATE"
            }, {
                "selector": "#dotIcon",
                "content": "studies:tutorial.table.rowIcon"
            }
        ],
        "create": [
            {
                "selector": "#name",
                "content": "studies:tutorial.create.name"
            },
            {
                "selector": "#code",
                "content": "studies:tutorial.create.code"
            },
            {
                "selector": "#startDate",
                "content": "studies:tutorial.create.startDate"
            },
            {
                "selector": "#endDate",
                "content": "studies:tutorial.create.endDate"
            },
            {
                "selector": "#caregivers",
                "content": "studies:tutorial.create.caregivers"
            },
            {
                "selector": "#hasDebitCard",
                "content": "studies:tutorial.create.hasDebitCard"
            },
            {
                "selector": "#protocolName",
                "content": "studies:tutorial.create.protocolName"
            },
            {
                "selector": "#patientIdDigits",
                "content": "studies:tutorial.create.patientIdDigits"
            },
            {
                "selector": "#description",
                "content": "studies:tutorial.create.description"
            }
        ]
    },
    "studycoordinators": {
        "otherdetail": [
            {
                "selector": "#followTutorial",
                "content": "studycoordinators:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#createPatient",
                "content": "studycoordinators:tutorial.detail.createPatient",
                "role": "ROLE_ICARE_PATIENT_CREATE"
            },
            {
                "selector": "#forceChangePassword",
                "content": "studycoordinators:tutorial.detail.forceChangePassword",
                "role": "ROLE_ICARE_USER_FORCED_CHANGE_PASSWORD"
            },
            {
                "selector": "#delete",
                "content": "studycoordinators:tutorial.detail.delete",
                "role": "ROLE_ICARE_STUDY_COORDINATOR_DELETE"
            },
            {
                "selector": "#edit",
                "content": "studycoordinators:tutorial.detail.edit",
                "role": "ROLE_ICARE_STUDY_COORDINATOR_EDIT"
            },
            {
                "selector": "#study",
                "content": "studycoordinators:tutorial.detail.study"
            },
            {
                "selector": "#sites",
                "content": "studycoordinators:tutorial.detail.sites",
                "role": "ROLE_ICARE_SITE_READ_ALL"
            },
            {
                "selector": "#patients",
                "content": "studycoordinators:tutorial.detail.patients",
                "role": "ROLE_ICARE_PATIENT_READ_ALL"
            },
            {
                "selector": "#documents",
                "content": "studycoordinators:tutorial.detail.documents"
            }
        ],
        "detail": [
            {
                "selector": "#followTutorial",
                "content": "studycoordinators:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#createPatient",
                "content": "studycoordinators:tutorial.detail.createPatient",
                "role": "ROLE_ICARE_PATIENT_CREATE"
            },
            {
                "selector": "#forceChangePassword",
                "content": "studycoordinators:tutorial.detail.forceChangePassword",
                "role": "ROLE_ICARE_USER_FORCED_CHANGE_PASSWORD"
            },
            {
                "selector": "#changePassword",
                "content": "studycoordinators:tutorial.detail.changePassword",
                "role": "STUDY_COORDINATOR"
            },
            {
                "selector": "#assignSite",
                "content": "studycoordinators:tutorial.detail.assignSite",
                "role": "ROLE_ICARE_STUDY_COORDINATOR_SITE_ASSIGN"
            },
            {
                "selector": "#delete",
                "content": "studycoordinators:tutorial.detail.delete",
                "role": "ROLE_ICARE_STUDY_COORDINATOR_DELETE"
            },
            {
                "selector": "#edit",
                "content": "studycoordinators:tutorial.detail.edit",
                "role": "ROLE_ICARE_STUDY_COORDINATOR_EDIT"
            },
            {
                "selector": "#study",
                "content": "studycoordinators:tutorial.detail.study"
            },
            {
                "selector": "#sites",
                "content": "studycoordinators:tutorial.detail.sites",
                "role": "ROLE_ICARE_SITE_READ_ALL"
            },
            {
                "selector": "#patients",
                "content": "studycoordinators:tutorial.detail.patients",
                "role": "ROLE_ICARE_PATIENT_READ_ALL"
            },
            {
                "selector": "#documents",
                "content": "studycoordinators:tutorial.detail.documents"
            }
        ],
        "table": [
            {
                "selector": "#followTutorial",
                "content": "studycoordinators:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#searchInput",
                "content": "studycoordinators:tutorial.table.searchInput"
            },
            {
                "selector": "#filterButton",
                "content": "studycoordinators:tutorial.table.filterButton"
            }, {
                "selector": "#dotIcon",
                "content": "studycoordinators:tutorial.table.rowIcon"
            }
        ],
        "create": [
            {
                "selector": "#firstName",
                "content": "studycoordinators:tutorial.create.firstName"
            },
            {
                "selector": "#middleName",
                "content": "studycoordinators:tutorial.create.middleName"
            },
            {
                "selector": "#lastName",
                "content": "studycoordinators:tutorial.create.lastName"
            },
            {
                "selector": "#languages",
                "content": "studycoordinators:tutorial.create.languages"
            },
            {
                "selector": "#prefix",
                "content": "studycoordinators:tutorial.create.prefix"
            },
            {
                "selector": "#email",
                "content": "studycoordinators:tutorial.create.email"
            },
            {
                "selector": "#fax",
                "content": "studycoordinators:tutorial.create.fax"
            },
            {
                "selector": "#primaryCountryCode",
                "content": "studycoordinators:tutorial.create.primaryCountryCode"
            },
            {
                "selector": "#primaryPhone",
                "content": "studycoordinators:tutorial.create.primaryPhone"
            },
            {
                "selector": "#workCountryCode",
                "content": "studycoordinators:tutorial.create.workCountryCode"
            },
            {
                "selector": "#workPhone",
                "content": "studycoordinators:tutorial.create.workPhone"
            },
            {
                "selector": "#workExtensionPhone",
                "content": "studycoordinators:tutorial.create.workExtensionPhone"
            }
        ]
    },
    "sites": {
        "detail": [
            {
                "selector": "#followTutorial",
                "content": "sites:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#createPi",
                "content": "sites:tutorial.detail.createPi",
                "role": "ROLE_ICARE_PI_CREATE"
            },
            {
                "selector": "#createSc",
                "content": "sites:tutorial.detail.createSc",
                "role": "ROLE_ICARE_STUDY_COORDINATOR_CREATE"
            },
            {
                "selector": "#delete",
                "content": "sites:tutorial.detail.delete",
                "role": "ROLE_ICARE_SITE_DELETE"
            },
            {
                "selector": "#editSite",
                "content": "sites:tutorial.detail.edit",
                "role": "ROLE_ICARE_SITE_EDIT"
            },
            {
                "selector": "#scList",
                "content": "sites:tutorial.detail.scList",
                "role": "ROLE_ICARE_STUDY_COORDINATOR_READ_ALL"
            }
        ],
        "table": [
            {
                "selector": "#followTutorial",
                "content": "sites:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#searchInput",
                "content": "sites:tutorial.table.searchInput"
            },
            {
                "selector": "#filterButton",
                "content": "sites:tutorial.table.filterButton"
            }, {
                "selector": "#dotIcon",
                "content": "sites:tutorial.table.rowIcon"
            }
        ],
        "create": [
            {
                "selector": "[name='name']",
                "content": "sites:tutorial.create.name"
            },
            {
                "selector": "[name='code']",
                "content": "sites:tutorial.create.code"
            },
            {
                "selector": "#directions",
                "content": "sites:tutorial.create.directions"
            },
            {
                "selector": "#notes",
                "content": "sites:tutorial.create.notes"
            }
        ],
        "picreate": [
            {
                "selector": "#firstName",
                "content": "sites:tutorial.picreate.firstName"
            },
            {
                "selector": "#middleName",
                "content": "sites:tutorial.picreate.middleName"
            },
            {
                "selector": "#lastName",
                "content": "sites:tutorial.picreate.lastName"
            },
            {
                "selector": "#languages",
                "content": "sites:tutorial.picreate.languages"
            },
            {
                "selector": "#prefix",
                "content": "sites:tutorial.picreate.prefix"
            },
            {
                "selector": "#email",
                "content": "sites:tutorial.picreate.email"
            },
            {
                "selector": "#fax",
                "content": "sites:tutorial.picreate.fax"
            },
            {
                "selector": "#primaryCountryCode",
                "content": "sites:tutorial.picreate.primaryCountryCode"
            },
            {
                "selector": "#primaryPhone",
                "content": "sites:tutorial.picreate.primaryPhone"
            },
            {
                "selector": "#workCountryCode",
                "content": "sites:tutorial.picreate.workCountryCode"
            },
            {
                "selector": "#workPhone",
                "content": "sites:tutorial.picreate.workPhone"
            },
            {
                "selector": "#workExtensionPhone",
                "content": "sites:tutorial.picreate.workExtensionPhone"
            }
        ]
    },
    "patients": {
        "detail": [
            {
                "selector": "#followTutorial",
                "content": "patients:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#createVisit",
                "content": "patients:tutorial.detail.createVisit",
                "role": "ROLE_ICARE_VISIT_CREATE"
            },
            {
                "selector": "#uploadReimbursementRequest",
                "content": "patients:tutorial.detail.uploadReimbursementRequest",
                "role": "ROLE_ICARE_PATIENT_REIMBURSEMENT_REQUEST_UPLOAD"
            },
            {
                "selector": "#forceChangePassword",
                "content": "patients:tutorial.detail.forceChangePassword",
                "role": "ROLE_ICARE_PATIENT_FORCED_CHANGE_PASSWORD"
            },
            {
                "selector": "#changeStatus",
                "content": "patients:tutorial.detail.changeStatus",
                "role": "ROLE_ICARE_PATIENT_EDIT_STATUS"
            },
            {
                "selector": "#changePaymentMethod",
                "content": "patients:tutorial.detail.changePaymentMethod",
                "role": "ROLE_ICARE_PATIENT_PAYMENT_METHOD_ASSIGN"
            },
            {
                "selector": "#deletePatient",
                "content": "patients:tutorial.detail.delete",
                "role": "ROLE_ICARE_PATIENT_DELETE"
            },
            {
                "selector": "#patientDetails",
                "content": "patients:tutorial.detail.patientDetails",
                "role": "ROLE_ICARE_PATIENT_EDIT"
            },
            {
                "selector": "#caregiverList",
                "content": "patients:tutorial.detail.caregiverList"
            },
            {
                "selector": "#studyDetail",
                "content": "patients:tutorial.detail.studyDetail",
                "role": "ROLE_ICARE_STUDY_READ"
            },
            {
                "selector": "#visitList",
                "content": "patients:tutorial.detail.visitList"
            },
            {
                "selector": "#reimbursementList",
                "content": "patients:tutorial.detail.reimbursementList"
            },
            {
                "selector": "#documents",
                "content": "patients:tutorial.detail.documents",
                "role": "ROLE_ICARE_PATIENT_DOCUMENT_READ_ALL"
            },
            {
                "selector": "#paragonDetails",
                "content": "patients:tutorial.detail.paragonDetails",
                "role": "PARAGON_STAFF"
            }
        ],
        "table": [
            {
                "selector": "#followTutorial",
                "content": "patients:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#changeTableView",
                "content": "patients:tutorial.table.changeView"
            },
            {
                "selector": "#searchInput",
                "content": "patients:tutorial.table.searchInput"
            },
            {
                "selector": "#filterButton",
                "content": "patients:tutorial.table.filterButton"
            }, {
                "selector": "#dotIcon",
                "content": "patients:tutorial.table.rowIcon"
            }
        ],
        "caregivers": [
            {
                "selector": "#firstName",
                "content": "patients:tutorial.caregiversCreate.firstName"
            },
            {
                "selector": "#middleName",
                "content": "patients:tutorial.caregiversCreate.middleName"
            },
            {
                "selector": "#lastName",
                "content": "patients:tutorial.caregiversCreate.lastName"
            },
            {
                "selector": "#relationship",
                "content": "patients:tutorial.caregiversCreate.relationship"
            },
            {
                "selector": "#gender",
                "content": "patients:tutorial.caregiversCreate.gender"
            },
            {
                "selector": "#birthDate",
                "content": "patients:tutorial.caregiversCreate.birthDate"
            },
            {
                "selector": "#email",
                "content": "patients:tutorial.caregiversCreate.email"
            },
            {
                "selector": "#primaryCountryCode",
                "content": "patients:tutorial.caregiversCreate.primaryCountryCode"
            },
            {
                "selector": "#primaryPhone",
                "content": "patients:tutorial.caregiversCreate.primaryPhone"
            },
            {
                "selector": "#isEmergency",
                "content": "patients:tutorial.caregiversCreate.isEmergency"
            },
            {
                "selector": "#add",
                "content": "patients:tutorial.caregiversCreate.add"
            },
            {
                "selector": "#deleteCaregiver",
                "content": "patients:tutorial.caregiversCreate.delete"
            },
        ],
        "emergency": [
            {
                "selector": "#fullName",
                "content": "patients:tutorial.emergencyCreate.fullName"
            },
            {
                "selector": "#phoneNumber",
                "content": "patients:tutorial.emergencyCreate.phoneNumber"
            },
            {
                "selector": "#relationship",
                "content": "patients:tutorial.emergencyCreate.relationship"
            },
            {
                "selector": "#add",
                "content": "patients:tutorial.emergencyCreate.add"
            },
            {
                "selector": "#deleteEmergency",
                "content": "patients:tutorial.emergencyCreate.delete"
            }

        ],
        "location": [
            {
                "selector": "#location",
                "content": "patients:tutorial.create.location"
            },
            {
                "selector": "#map",
                "content": "patients:tutorial.create.map"
            }
        ],
        "create": [
            {
                "selector": "#initials",
                "content": "patients:tutorial.create.initials"
            },
            {
                "selector": "#code",
                "content": "patients:tutorial.create.patientNumber"
            },
            {
                "selector": "#originalName",
                "content": "patients:tutorial.create.originalName"
            },
            {
                "selector": "#firstName",
                "content": "patients:tutorial.create.firstName"
            },
            {
                "selector": "#middleName",
                "content": "patients:tutorial.create.middleName"
            },
            {
                "selector": "#lastName",
                "content": "patients:tutorial.create.lastName"
            },
            {
                "selector": "#languages",
                "content": "patients:tutorial.create.languages"
            },
            {
                "selector": "#gender",
                "content": "patients:tutorial.create.gender"
            },
            {
                "selector": "#birthDate",
                "content": "patients:tutorial.create.birthDate"
            },
            {
                "selector": "#email",
                "content": "patients:tutorial.create.email"
            },
            {
                "selector": "#fax",
                "content": "patients:tutorial.create.fax"
            },
            {
                "selector": "#primaryCountryCode",
                "content": "patients:tutorial.create.primaryCountryCode"
            },
            {
                "selector": "#primaryPhone",
                "content": "patients:tutorial.create.primaryPhone"
            },
            {
                "selector": "#workCountryCode",
                "content": "patients:tutorial.create.workCountryCode"
            },
            {
                "selector": "#workPhone",
                "content": "patients:tutorial.create.workPhone"
            },
            {
                "selector": "#workExtensionPhone",
                "content": "patients:tutorial.create.workExtensionPhone"
            }
        ]
    },
    "visits": {
        "otherdetail": [
            {
                "selector": "#followTutorial",
                "content": "visits:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#requestService",
                "content": "visits:tutorial.detail.requestService",
                "role": "ROLE_ICARE_SERVICE_CREATE"
            },
            {
                "selector": "#cancelVisit",
                "content": "visits:tutorial.detail.cancelVisit",
                "role": "ROLE_ICARE_VISIT_DELETE"
            },
            {
                "selector": "#edit",
                "content": "visits:tutorial.detail.edit",
                "role": "ROLE_ICARE_VISIT_EDIT"
            },
            {
                "selector": "#requestedServices",
                "content": "visits:tutorial.detail.requestedServices"
            },
            {
                "selector": "#requestedReimbursements",
                "content": "visits:tutorial.detail.requestedReimbursements"
            },
            {
                "selector": "#visitHistoryList",
                "content": "visits:tutorial.detail.visitHistoryList",
                "role": "PARAGON_STAFF"
            }
        ],
        "detail": [
            {
                "selector": "#followTutorial",
                "content": "visits:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#requestReimbursement",
                "content": "visits:tutorial.detail.requestReimbursement",
                "role": "ROLE_ICARE_REIMBURSEMENT_REQUEST"
            },
            {
                "selector": "#requestService",
                "content": "visits:tutorial.detail.requestService",
                "role": "ROLE_ICARE_SERVICE_CREATE"
            },
            {
                "selector": "#cancelVisit",
                "content": "visits:tutorial.detail.cancelVisit",
                "role": "ROLE_ICARE_VISIT_DELETE"
            },
            {
                "selector": "#edit",
                "content": "visits:tutorial.detail.edit",
                "role": "ROLE_ICARE_VISIT_EDIT"
            },
            {
                "selector": "#requestedServices",
                "content": "visits:tutorial.detail.requestedServices"
            },
            {
                "selector": "#requestedReimbursements",
                "content": "visits:tutorial.detail.requestedReimbursements"
            },
            {
                "selector": "#visitHistoryList",
                "content": "visits:tutorial.detail.visitHistoryList",
                "role": "PARAGON_STAFF"
            }
        ],
        "table": [
            {
                "selector": "#followTutorial",
                "content": "visits:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#searchInput",
                "content": "visits:tutorial.table.searchInput"
            },
            {
                "selector": "#filterButton",
                "content": "visits:tutorial.table.filterButton"
            }, {
                "selector": "#dotIcon",
                "content": "visits:tutorial.table.rowIcon"
            }
        ],
        "create": [
            {
                "selector": "#code",
                "content": "visits:tutorial.create.code"
            },
            {
                "selector": "#name",
                "content": "visits:tutorial.create.name"
            },
            {
                "selector": "#startDate",
                "content": "visits:tutorial.create.startDate"
            },
            {
                "selector": "#endDate",
                "content": "visits:tutorial.create.endDate"
            },
            {
                "selector": "#site",
                "content": "visits:tutorial.create.site"
            },
            {
                "selector": "#studycoordinator",
                "content": "visits:tutorial.create.studycoordinator"
            },
            {
                "selector": "#siteDivision",
                "content": "visits:tutorial.create.siteDivision"
            },
            {
                "selector": "#directions",
                "content": "visits:tutorial.create.directions"
            },
            {
                "selector": "#scheduled",
                "content": "visits:tutorial.create.scheduled"
            }
        ]
    },
    "services": {
        "detail": [{
            "selector": "#followTutorial",
            "content": "services:tutorial.start",
            "firstAccess": true
        },
        {
            "selector": "#confirm",
            "content": "services:tutorial.detail.confirm",
            "role": "ROLE_ICARE_SERVICE_CONFIRM"
        },
        {
            "selector": "#deactivate",
            "content": "services:tutorial.detail.deactivate",
            "role": "ROLE_ICARE_SERVICE_DELETE"
        },
        {
            "selector": "#editDetails",
            "content": "services:tutorial.detail.editDetails",
            "role": "ROLE_ICARE_SERVICE_EDIT"
        },
        {
            "selector": "#editRequirements",
            "content": "services:tutorial.detail.editRequirements",
            "role": "ROLE_ICARE_SERVICE_ATTRIBUTE_VALUE_EDIT"
        },
        {
            "selector": "#editSupplier",
            "content": "services:tutorial.detail.editSupplier",
            "role": "ROLE_ICARE_SUPPLIER_READ_ALL"
        }
        ],
        "table": [
            {
                "selector": "#followTutorial",
                "content": "services:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#searchInput",
                "content": "services:tutorial.table.searchInput"
            },
            {
                "selector": "#filterButton",
                "content": "services:tutorial.table.filterButton"
            }, {
                "selector": "#dotIcon",
                "content": "services:tutorial.table.rowIcon"
            }
        ]
    },
    "suppliers": {
        "detail": [{
            "selector": "#followTutorial",
            "content": "suppliers:tutorial.start",
            "firstAccess": true
        },
        {
            "selector": "#deactivate",
            "content": "suppliers:tutorial.detail.deactivate",
            "role": "ROLE_ICARE_SUPPLIER_DELETE"
        },
        {
            "selector": "#edit",
            "content": "suppliers:tutorial.detail.edit",
            "role": "ROLE_ICARE_SUPPLIER_UPDATE"
        },
        {
            "selector": "#services",
            "content": "suppliers:tutorial.detail.services",
            "role": "ROLE_ICARE_SERVICE_TYPE_READ_ALL"
        }],
        "table": [
            {
                "selector": "#followTutorial",
                "content": "suppliers:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#searchInput",
                "content": "suppliers:tutorial.table.searchInput"
            },
            {
                "selector": "#filterButton",
                "content": "suppliers:tutorial.table.filterButton"
            },
            {
                "selector": "#create",
                "content": "suppliers:tutorial.table.create"
            }, {
                "selector": "#dotIcon",
                "content": "suppliers:tutorial.table.rowIcon"
            }
        ],
        "create": [
            {
                "selector": "#name",
                "content": "suppliers:tutorial.create.name"
            },
            {
                "selector": "#email",
                "content": "suppliers:tutorial.create.email"
            },
            {
                "selector": "#serviceTypeList",
                "content": "suppliers:tutorial.create.serviceTypeList"
            },
            {
                "selector": "#isGlobal",
                "content": "suppliers:tutorial.create.isGlobal"
            },
            {
                "selector": "#isCommissionable",
                "content": "suppliers:tutorial.create.isCommissionable"
            }
        ]
    },
    "accounts": {
        "detail": [

        ],
        "table": [
            {
                "selector": "#followTutorial",
                "content": "accounts:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#searchInput",
                "content": "accounts:tutorial.table.searchInput"
            },
            {
                "selector": "#create",
                "content": "accounts:tutorial.table.create"
            }, {
                "selector": "#dotIcon",
                "content": "accounts:tutorial.table.rowIcon"
            }
        ]
    },
    "reimbursements": {
        "detail": [
            {
                "selector": "#followTutorial",
                "content": "reimbursements:tutorial.start",
                "firstAccess": true
            },
            {
                "selector": "#changeStatus",
                "content": "reimbursements:tutorial.detail.changeStatus",
                "role": "ROLE_ICARE_REIMBURSEMENT_CHANGE_STATUS"
            },
            {
                "selector": "#edit",
                "content": "reimbursements:tutorial.detail.edit",
                "role": "ROLE_ICARE_REIMBURSEMENT_UPDATE"
            }, {
                "selector": "#conversion",
                "content": "reimbursements:tutorial.detail.conversion",
                "role": "ROLE_ICARE_REIMBURSEMENT_AMOUNT_CONVERSION"
            },
            {
                "selector": "#documents",
                "content": "reimbursements:tutorial.detail.documents",
                "role": "ROLE_ICARE_REIMBURSEMENT_DOCUMENT_READ_ALL"
            }
        ]
    },
    "sponsors": {
        "detail": [
            {
                "selector": "#deactivate",
                "content": "sponsors:tutorial.detail.deactivate",
                "role": "ROLE_ICARE_SPONSOR_DELETE"
            },
            {
                "selector": "#edit",
                "content": "sponsors:tutorial.detail.edit",
                "role": "ROLE_ICARE_SPONSOR_EDIT"
            },
            {
                "selector": "#studies",
                "content": "sponsors:tutorial.detail.studyList",
                "role": "ROLE_ICARE_SPONSOR_STUDY_ASSIGN"
            },
            {
                "selector": "#users",
                "content": "sponsors:tutorial.detail.userList",
                "role": "ROLE_ICARE_SPONSOR_USER_READ_ALL"
            }
        ],
        "table": [
            {
                "selector": "#searchInput",
                "content": "sponsors:tutorial.table.searchInput"
            },
            {
                "selector": "#create",
                "content": "sponsors:tutorial.table.create",
                "role": "ROLE_ICARE_SPONSOR_CREATE"
            }, {
                "selector": "#dotIcon",
                "content": "sponsors:tutorial.table.rowIcon"
            }],
        "create": [
            {
                "selector": "#name",
                "content": "sponsors:tutorial.create.name"
            },
            {
                "selector": "#email",
                "content": "sponsors:tutorial.create.email"
            },
            {
                "selector": "#currency",
                "content": "sponsors:tutorial.create.currency"
            },
            {
                "selector": "#countryCodePhone",
                "content": "sponsors:tutorial.create.countryCodePhone"
            },
            {
                "selector": "#phone",
                "content": "sponsors:tutorial.create.phone"
            },
            {
                "selector": "#extensionPhone",
                "content": "sponsors:tutorial.create.extensionPhone"
            },
            {
                "selector": "#notes",
                "content": "sponsors:tutorial.create.notes"
            }
        ]
    }
}




export default _steps;