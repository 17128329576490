import EventSource from "eventsource";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useNotifier } from "src/context/NotifierContext";
import { usePaginatedRequests } from "../usePaginatedRequests";
import { GET_NOTIFICATIONS, READ_ALL_NOTIFICATION } from "./service";
import styles from './serverSentEventsHandler.module.scss';
import { useTranslation } from "react-i18next";


export function useNotification() {
    const notifications = usePaginatedRequests(GET_NOTIFICATIONS);
    const history = useHistory();
    const openNotifier = useNotifier();
    const { t } = useTranslation();

    const reloadEvent = new Event('reloadCount');

    const readAll = () => {
        READ_ALL_NOTIFICATION().then(() => notifications.loadData());
        document.dispatchEvent(reloadEvent);
    }

    const read = (uuid) => {
        READ_ALL_NOTIFICATION({ "uuid": uuid }).then(() => notifications.loadData());
        document.dispatchEvent(reloadEvent);
    }

    const handleRedirect = (notification, section, uuid) => {
        sessionStorage.setItem("navigatedInsideDetail", true);
        if (section === 'patient_documents') {
            history.push(`/patients/detail/${uuid}?tab=documents`);
        } else {
            history.push(`/${section}/detail/${uuid}`);
        }
    }

    const gotoDetail = (notification, section, uuid) => {
        // let _section = section !== 'services' ? section : 'visits';
        sessionStorage.setItem("navigatedInsideDetail", true);
        history.push(`/${section}/detail/${uuid}`);
    }

    useEffect(() => {
        const eventSourceInitDict = { headers: { 'Authorization': localStorage.getItem('token') } };
        const evtSource = new EventSource('/api/realtime/notification', eventSourceInitDict);

        evtSource.onmessage = function (data) {
            if (data !== 'init') {
                let _parsedData = JSON.parse(data);

                let _msg = t(_parsedData.label.replace('paragon.notification.', 'response:'));
                let _objectName = <b className={styles['hoverEffect']} onClick={() => gotoDetail(_parsedData, _parsedData?.objectType?.toLowerCase() + "s", _parsedData?.object?.redirectUuid ? _parsedData?.object?.redirectUuid : _parsedData?.object?.uuid)}>&ensp;{_parsedData?.object?.name}&ensp;</b>;
                let _agentName = <b className={styles['hoverEffect']} >&ensp;{_parsedData?.agent?.name}&ensp;</b>;

                if (_msg.indexOf('{agent}') > -1) {
                    let splittedAgent = _msg.split('{agent}');
                    if (splittedAgent.indexOf("") > -1) {
                        splittedAgent[splittedAgent.indexOf("")] = _agentName;
                    } else {
                        splittedAgent = [splittedAgent[0], _agentName, splittedAgent[1]];
                    }

                    if (_msg.indexOf('{object}') > -1) {
                        let indexObject = splittedAgent.map(el => typeof el === 'string' && el.includes('{object}')).indexOf(true);
                        let splittedObject = splittedAgent[indexObject].split('{object}');
                        splittedObject[splittedObject.indexOf("")] = _objectName;
                        splittedAgent[indexObject] = splittedObject;


                    }
                    _msg = splittedAgent;
                } else if (_msg.indexOf('{object}') > -1) {
                    let splittedObject = _msg.split('{object}');

                    if (splittedObject.indexOf("") > -1) {
                        splittedObject[splittedObject.indexOf("")] = _objectName;
                    } else {
                        splittedObject = [splittedObject[0], _objectName, splittedObject[1]];
                    }

                    // console.log("splittedObject", splittedObject);
                    _msg = splittedObject;
                }

                openNotifier("warning", _msg, 10000);
            }
        };

        return () => {
            evtSource.close();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    return {
        ...notifications,
        handleRedirect,
        readAll,
        read
    }
}