import { Step, StepConnector, StepLabel, Stepper, withStyles } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import componentStyle from 'src/styles/components.module.scss';
import clsx from 'clsx';

const CustomStepConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    active: {
        '& $line': {
            backgroundColor: '#259ECB',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#259ECB'
        },
    },
    line: {
        height: 5,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

function customStepIcon(props) {
    const { active, completed, icon } = props;

    return (
        <div className={clsx(componentStyle['stepper__Icon'], { [componentStyle['stepper__IconActive']]: active, [componentStyle['stepper__IconCompleted']]: completed })}>
            {icon}
        </div>
    );
}


export const FormStepper = forwardRef(({ steps, activeStep }, ref) => {

    return <Stepper alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
            {steps.map(({ label, icon }) => (
                <Step key={label}>
                    <StepLabel StepIconProps={{ icon: icon }} StepIconComponent={customStepIcon}>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
})