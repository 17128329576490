import React, { useEffect, useState } from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetailLoader, Fade } from 'src/components';
import { usePaginatedRequests } from 'src/hooks/usePaginatedRequests';
import { GET_STUDY_SERVICETYPE } from 'src/pages/Studies/services';

export const ServiceTypesConfiguration = ({ formik }) => {
    const { t } = useTranslation();
    const services = usePaginatedRequests(GET_STUDY_SERVICETYPE, {}, true);
    const [serviceTypes, setServiceTypes] = useState([]);

    useEffect(() => {
        if (services.response.content) {
            let _options = {};
            services.response.content.forEach(el => {
                if (_options[el.serviceGroupName]) {
                    _options[el.serviceGroupName].push(el);
                } else {
                    _options[el.serviceGroupName] = [];
                    _options[el.serviceGroupName].push(el);
                }
            });
            setServiceTypes(_options);
        }
    }, [services.response.content]);

    function handleChange(event, service) {
        let _serviceTypeList = [...formik.values.SERVICE_TYPE_LIST];

        if (event.target.checked) {
            if (!_serviceTypeList.map(el => el.uuid).indexOf(service.uuid) > -1) {
                _serviceTypeList.push({ ...service });
            }
        } else {
            if (_serviceTypeList.map(el => el.uuid).indexOf(service.uuid) > -1) {
                _serviceTypeList.splice(_serviceTypeList.map(el => el.uuid).indexOf(service.uuid), 1);
            }
        }

        formik.setFieldValue('SERVICE_TYPE_LIST', _serviceTypeList);
    }

    if (services.loading)
        return <DetailLoader />

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']} style={{ paddingBottom: 0 }}>{t('studies:serviceTypesConfiguration')}</div>
            <div className={creationStyle['container__SectionTitleSecondary']}>{t('studies:serviceTypesSecondaryTitle')}</div>
            <div className={creationStyle['container__SectionBody']} style={{ maxHeight: 'calc(100vh - 400px)'}}>
                <div className={creationStyle['container__SectionBodyTable']}>
                    {
                        Object.keys(serviceTypes).map(key => {
                            return <Grid key={key} container>
                                <Grid container className={creationStyle['container__SectionBodyTableHeader']}>
                                    <Grid item xs={12} className={creationStyle['container__SectionBodyTableCell']} style={{ paddingLeft: 15 }}>{t(key.replace('paragon.service_group.', 'service_group:'))}</Grid>
                                </Grid>
                                {
                                    serviceTypes[key].map(service => {
                                        let selected = formik.values.SERVICE_TYPE_LIST.map(el => el.uuid).indexOf(service.uuid) > -1;
                                        return <Grid key={service.uuid} item xs={12} style={{ marginLeft: 15 }}>
                                            <FormControlLabel className={creationStyle['container__SectionBodyField']}
                                                control={<Checkbox color="primary" checked={selected} onChange={event => handleChange(event, service)} />}
                                                label={t(service.label.replace('paragon.service_type.', 'service_type:'))}
                                            />
                                        </Grid>
                                    })
                                }
                            </Grid>
                        })
                    }
                </div>
            </div>
        </Fade>
    )
}