import React from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetailLoader, Fade } from 'src/components';
import { GET_REIMBURSEMENT_TYPE } from 'src/pages/Visits/services';
import { useAsync } from 'src/hooks/useAsync';


export const ReimbursementTypesConfiguration = ({ formik }) => {
    const { t } = useTranslation();
    const reimbursements = useAsync(GET_REIMBURSEMENT_TYPE, true);

    function handleChange(event, reimbursement) {
        let _reimbursementTypeList = [...formik.values.REIMBURSEMENT_TYPE_LIST];

        if (event.target.checked) {
            if (!_reimbursementTypeList.map(el => el.uuid).indexOf(reimbursement.uuid) > -1) {
                _reimbursementTypeList.push({ ...reimbursement });
            }
        } else {
            if (_reimbursementTypeList.map(el => el.uuid).indexOf(reimbursement.uuid) > -1) {
                _reimbursementTypeList.splice(_reimbursementTypeList.map(el => el.uuid).indexOf(reimbursement.uuid), 1);
            }
        }

        formik.setFieldValue('REIMBURSEMENT_TYPE_LIST', _reimbursementTypeList);
    }

    if(reimbursements.loading)
    return <DetailLoader />;

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']} style={{ paddingBottom: 0 }}>{t('studies:reimbursementTypesConfiguration')}</div>
            <div className={creationStyle['container__SectionTitleSecondary']}>{t('studies:reimbursementTypesSecondaryTitle')}</div>
            <div className={creationStyle['container__SectionBody']} style={{ maxHeight: 'calc(100vh - 400px)' }}>
                <div className={creationStyle['container__SectionBodyTable']}>
                    <Grid container>
                        {
                            reimbursements?.response?.data.map((reimbursement, index) => reimbursement.code !== 'Visit_auto_reimbursement' && <Grid key={index} item xs={12}>
                                <FormControlLabel key={index} className={creationStyle['container__SectionBodyField']}
                                    control={<Checkbox color="primary" checked={formik.values.REIMBURSEMENT_TYPE_LIST.map(el => el.uuid).indexOf(reimbursement.uuid) > -1} onChange={event => handleChange(event, reimbursement)} />}
                                    label={t(reimbursement.label.replace('paragon.reimbursement_type.', 'reimbursement_type:'))}
                                />
                            </Grid>
                            )
                        }
                    </Grid>
                </div>
            </div>
        </Fade>
    )
}