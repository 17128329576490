import { useAuth } from "src/context/auth-context"

export function useRoles() {
    const auth = useAuth();

    const hasRole = role => {
        return auth._data.roles.includes(role);
    }

    return {
        roles: auth._data.roles,
        hasRole
    }
}