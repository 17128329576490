import { Button, Dialog } from '@material-ui/core';
import React, { cloneElement, useEffect, useState } from 'react';
import { IsMobile } from 'src/hooks/MediaQuery/isMobile';
import Tutorial from 'src/roots/AuthenticatedApp/components/generalComponents/tourController/tourController';
import { FilledDialogTitle } from '../Dialog/FilledDialogTitle';
import styles from './button.module.scss';

export const FormButton = (props) => {
    const { children, detailButton, title, buttonText, maxWidth, tutorialSection, hide, onCloseFn, startIcon, variant, color, className, style } = props;

    const isMobile = IsMobile();
    const [open, setOpen] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
    const [tutSection, setTutSection] = useState(null);

    useEffect(() => {
        if(tutorialSection){
            setTutSection(tutorialSection);
        }
    }, [tutorialSection])

    const showHelp = () => {
        setShowTutorial(true);
    }

    const onClose = () => {
        if (onCloseFn) {
            onCloseFn();
        }
        setOpen(false);
    }

    return (
        <>
            {!hide && <Button style={style} className={detailButton ? styles['buttonContainer'] : className ? className : null} color={color} variant={variant} startIcon={startIcon} onClick={() => setOpen(true)}>
                <span className={detailButton ? styles['buttonContainer__text'] : null}>{ buttonText }</span>
            </Button>}
            <Dialog fullWidth maxWidth={maxWidth ? maxWidth : 'md'} fullScreen={isMobile} open={open}>
                <FilledDialogTitle onHelp={showHelp} onClose={onClose}>
                    {title}
                </FilledDialogTitle>
                {
                    cloneElement(children, { ...props, changeTutorialSection: (tut) => setTutSection(tut), handleClose: onClose, styles: styles, open: open })
                }
            </Dialog>
            {
                showTutorial && <Tutorial onClose={() => setShowTutorial(false)} section={tutSection} openOnLoad={showTutorial} />
            }
        </>
    )
}