import React from 'react';
import { Button, Dialog, DialogContentText } from '@material-ui/core';
import { FilledDialogTitle, FormField, DialogContent, DialogActions } from 'src/components';
import { IsMobile } from 'src/hooks/MediaQuery/isMobile';
import styles from '../LoginStyle.module.scss';
import { useTranslation } from 'react-i18next';

export function PasswordExpiredDialog({ formik, loading, openDialog, handleClose }) {
    const { t } = useTranslation();
    const isMobile = IsMobile();

    return (
        <Dialog
            fullScreen={isMobile}
            open={openDialog}
            fullWidth={true}
            maxWidth={'xs'}
        >
            <FilledDialogTitle onClose={handleClose}>
                {t('login:passwordExpired')}
            </FilledDialogTitle>
            <DialogContent dividers>
                <DialogContentText component="div">
                    <div style={{ padding: '5px 0 20px 0'}}>
                        {t('login:passwordExpiredText')}
                    </div>
                    <form>
                        <div className={styles['resetPassword__input']}>
                            <FormField autoComplete="current-password" fullWidth formik={formik} name="newpassword" label="New password" type="password" />
                        </div>
                        <div>
                            <FormField autoComplete="current-password" fullWidth formik={formik} name="repeatnewpassword" label="Repeat new password" type="password" />
                        </div>
                    </form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose} variant={"outlined"}>{t('generic:cancel')}</Button>
                <Button onClick={formik.handleSubmit} color="primary" autoFocus variant={'contained'}>{t('login:changePassword')}</Button>
            </DialogActions>
        </Dialog>
    );
}