import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#259ECB'
        },
        secondary: {
            main: '#484748'
        }
    },
    typography:{
        fontFamily:[
            'Nunito',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif'
        ]
    },
    overrides: {
        MuiButton:{
            label:{
                fontFamily: "Nunito",
                fontWeight: 600,
                fontSize: '1rem',
                textTransform: 'capitalize'
            }
        },
        MuiOutlinedInput: {
            root: {
                fontFamily: "Nunito",
                fontWeight: 600,
                fontSize: '1rem',
                color: "#484748",
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#259ECB",
                    borderWidth: '2px',
                  }
            },
            notchedOutline:{
                borderColor: "#259ECB",
                borderWidth: "2px"
            }
        },
        MuiInputLabel:{
            root:{
                color: '#484748',
                "& .Mui-focused":{
                    color: "#259ECB"
                }
            },
            shrink:{
                color: "#259ECB"
            }
        },
        MuiStepLabel:{
            label:{
                fontFamily: 'Nunito',
                fontSize: '1rem',
                fontWeight: 'bold'
            },
            alternativeLabel:{
                fontFamily: 'Nunito !important',
                fontSize: '1rem !important',
                fontWeight: 'bold !important',
                textTransform: 'uppercase !important'
            },
            active:{
                color: "#259ECB !important",
                fontFamily: 'Nunito !important',
                fontSize: '1rem !important',
                fontWeight: 'bold !important',
                textTransform: 'uppercase !important'
            },
            completed:{
                color: "#259ECB !important",
                fontFamily: 'Nunito !important',
                fontSize: '1rem !important',
                fontWeight: 'bold !important',
                textTransform: 'uppercase !important'
            }
        },
        MuiFormHelperText:{
            contained:{
                fontSize: '0.85rem',
                fontWeight: 600,
                fontFamily: 'Nunito',
                color: '#484748'
            }
        },
        MuiTab:{
            root:{
                maxWidth: 'unset'
            }
        }
    }
},
)

export default theme;