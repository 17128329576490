import React from 'react';
import { AuthProvider } from './auth-context'
import { NotifierProvider } from './NotifierContext';

function AppProviders({ children }) {
  return (
      <AuthProvider>
        <NotifierProvider>
          {children}
        </NotifierProvider>
      </AuthProvider>
  )
}

export default AppProviders