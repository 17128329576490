import React, { lazy } from 'react';
import { componentLoader } from 'src/roots/AuthenticatedApp/components/utils/utils';

const Dashboard = lazy(() => componentLoader(() => import('src/pages/Dashboard'), 10));
const MyProfile = lazy(() => componentLoader(() => import('src/pages/Profile'), 10));
const Patients = lazy(() => componentLoader(() => import('src/pages/Patients'), 10));
const StudyCoordinators = lazy(() => componentLoader(() => import('src/pages/StudyCoordinators'), 10));
const AccountsManagement = lazy(() => componentLoader(() => import('sections/accounts'), 10));
const Studies = lazy(() => componentLoader(() => import('src/pages/Studies'), 10));
const Visits = lazy(() => componentLoader(() => import('src/pages/Visits'), 10));
const Sites = lazy(() => componentLoader(() => import('src/pages/Sites'), 10));
const SiteDetail = lazy(() => componentLoader(() => import('src/pages/Sites/detail'), 10));
const CreateSite = lazy(() => componentLoader(() => import('src/pages/Sites/create'), 10));
const StudyCoordinatorDetail = lazy(() => componentLoader(() => import('src/pages/StudyCoordinators/detail'), 10));
const CreateStudyCoordinator = lazy(() => componentLoader(() => import('src/pages/StudyCoordinators/create'), 10));
const StudyDetail = lazy(() => componentLoader(() => import('src/pages/Studies/detail'), 10));
const CreateStudy = lazy(() => componentLoader(() => import('src/pages/Studies/create'), 10));
const PatientDetail = lazy(() => componentLoader(() => import('src/pages/Patients/detail'), 10));
const CreatePatient = lazy(() => componentLoader(() => import('src/pages/Patients/create'), 10));
const VisitDetail = lazy(() => componentLoader(() => import('src/pages/Visits/detail'), 10));
const CreateVisit = lazy(() => componentLoader(() => import('src/pages/Visits/create'), 10));
const ParagonBigCalendar = lazy(() => componentLoader(() => import('sections/calendar'), 10));
const Suppliers = lazy(() => componentLoader(() => import('src/pages/Suppliers'), 10));
const SupplierDetail = lazy(() => componentLoader(() => import('src/pages/Suppliers/detail'), 10));
const ParagonServices = lazy(() => componentLoader(() => import('src/pages/Services/index'), 10));
const ServiceDetail = lazy(() => componentLoader(() => import('src/pages/Services/detail'), 10));
const AccountDetail = lazy(() => componentLoader(() => import('sections/accounts/detail'), 10));
const ReimbursementDetail = lazy(() => componentLoader(() => import('src/pages/Reimbursements/detail'), 10));
const ConfigurationPage = lazy(() => componentLoader(() => import('sections/config'), 10));
const StudiesReport = lazy(() => componentLoader(() => import('sections/report/studies'), 10));
const VisitsReport = lazy(() => componentLoader(() => import('sections/report/visits'), 10));
const ReimbursementsReport = lazy(() => componentLoader(() => import('sections/report/reimbursements'), 10));
const Reporting = lazy(() => componentLoader(() => import('sections/report'), 10));
const Sponsor = lazy(() => componentLoader(() => import('src/pages/Sponsor'), 10));
const SponsorDetail = lazy(() => componentLoader(() => import('src/pages/Sponsor/detail/SponsorDetail'), 10));
const CreateSponsor = lazy(() => componentLoader(() => import('src/pages/Sponsor/create'), 10));
const DebitCards = lazy(() => componentLoader(() => import('src/pages/DebitCards'), 10));

export const routes = [
    {
      path: "/",
      exact: true,
      view: ({ openNotifier, roles }) => false && <Dashboard openNotifier={ openNotifier } roles={roles}  />
    },
    {
      path: "/dashboard",
      exact: true,
      view: (({ openNotifier, roles, triggerReload, toReload}) => <Dashboard triggerReload={triggerReload} toReload={toReload} openNotifier={ openNotifier } roles={roles}  />)
    },
    {
      path: "/patients",
      exact: true,
      role:'ROLE_ICARE_PATIENT_READ_ALL',
      view: ({ openNotifier, roles }) => <Patients openNotifier={ openNotifier } roles={roles}  />
    },
    {
      path: "/patients/create",
      exact: true,
      view: ({ openNotifier, roles }) => <CreatePatient openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/patients/edit/:editUuid",
      exact: true,
      view: ({ openNotifier, roles }) => <CreatePatient openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/studycoordinators",
      exact: true,
      view: ({ openNotifier, roles }) => <StudyCoordinators openNotifier={ openNotifier } roles={roles}  />
    },
    {
      path: "/studycoordinators/create",
      exact: true,
      view: ({ openNotifier, roles }) => <CreateStudyCoordinator openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/studycoordinators/edit/:editUuid",
      exact: true,
      view: ({ openNotifier, roles }) => <CreateStudyCoordinator openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/accounts",
      exact: true,
      view: ({ openNotifier, roles }) => <AccountsManagement openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/studies",
      exact: true,
      view: ({ openNotifier, roles }) => <Studies openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/studies/create",
      exact: true,
      view: ({ openNotifier, roles }) => <CreateStudy openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/studies/configuration/:studyUuid",
      view: ({ openNotifier, roles }) => <CreateStudy openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/studies/edit/:editUuid",
      view: ({ openNotifier, roles }) => <CreateStudy openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/sites",
      exact: true,
      view: ({ openNotifier, roles }) => <Sites openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/sites/create",
      view: ({ openNotifier, roles }) => <CreateSite openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/sites/clone/:editUuid",
      view: ({ openNotifier, roles }) => <CreateSite openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/sites/configuration/:editUuid",
      view: ({ openNotifier, roles }) => <CreateSite openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/sites/edit/:editUuid",
      view: ({ openNotifier, roles }) => <CreateSite openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/visits",
      exact: true,
      view: ({ openNotifier, roles }) => <Visits openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/visits/create",
      exact: true,
      view: ({ openNotifier, roles }) => <CreateVisit openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/visits/edit/:editUuid",
      view: ({ openNotifier, roles }) => <CreateVisit openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/calendar",
      exact: true,
      view: ({ openNotifier, roles }) => <ParagonBigCalendar openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/suppliers",
      exact: true,
      view: ({ openNotifier, roles }) => <Suppliers openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/services",
      exact: true,
      view: ({ openNotifier, roles }) => <ParagonServices openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/myprofile",
      exact: true,
      view: ({ openNotifier, roles }) => <MyProfile openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/sites/detail/:code",
      view: ({ openNotifier, roles }) => <SiteDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/studycoordinators/detail/:code",
      view: ({ openNotifier, roles }) => <StudyCoordinatorDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/studies/detail/:code",
      view: ({ openNotifier, roles }) => <StudyDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/patients/detail/:uuid",
      view: ({ openNotifier, roles }) => <PatientDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/visits/detail/:uuid",
      view: ({ openNotifier, roles }) => <VisitDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/visits/history/:uuid",
      view: ({ openNotifier, roles }) => <VisitDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/suppliers/detail/:uuid",
      view: ({ openNotifier, roles }) => <SupplierDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/services/detail/:uuid",
      view: ({ openNotifier, roles }) => <ServiceDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/services/history/:uuid",
      view: ({ openNotifier, roles }) => <ServiceDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/accounts/detail/:uuid",
      view: ({ openNotifier, roles }) => <AccountDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/reimbursements/detail/:uuid",
      view: ({ openNotifier, roles }) => <ReimbursementDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/config",
      view: ({ openNotifier, roles }) => <ConfigurationPage openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/reports",
      exact: true,
      view: ({ openNotifier, roles }) => <Reporting openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/reports/visits",
      view: ({ openNotifier, roles }) => <VisitsReport openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/reports/studies",
      view: ({ openNotifier, roles }) => <StudiesReport openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/sponsors",
      exact: true,
      view: ({ openNotifier, roles }) => <Sponsor openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/sponsors/detail/:uuid",
      view: ({ openNotifier, roles }) => <SponsorDetail openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/sponsors/create",
      exact: true,
      view: ({ openNotifier, roles }) => <CreateSponsor openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/reports/reimbursements",
      view: ({ openNotifier, roles }) => <ReimbursementsReport openNotifier={ openNotifier } roles={roles} />
    },
    {
      path: "/debitcards",
      exact: true,
      view: ({ openNotifier, roles }) => <DebitCards openNotifier={ openNotifier } roles={roles} />
    },
  ];


