import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'src/context/auth-context';
import { HandleErrorCatching } from 'src/roots/AuthenticatedApp/components/utils/utils';

export function useAsync(asyncFunction, immediate = true, filters) {
    const auth = useAuth();
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);

    const execute = useCallback((newFilters, onResolve) => {
        setLoading(true);
        setError(null);
        return asyncFunction(newFilters ? newFilters : filters)
            .then(response => {
                setLoading(false);
                if(onResolve){
                    onResolve(response);
                }
                setResponse(response);
            }, error => {
                setLoading(false);
                setResponse(null);
                setError(error);
                HandleErrorCatching(error, execute, auth);
            }).catch(error => {
                setLoading(false);
                setResponse(null);
                setError(error);
                HandleErrorCatching(error, execute, auth);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asyncFunction]);

    useEffect(() => {
        if (error?.response?.status === 401) {
            auth.refreshToken();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        let isMounted = true;
        if (immediate) {
            if(isMounted)
            execute();
        }
        return () => {
            isMounted = false;
        }
    }, [execute, immediate]);

    return { execute, loading, response, error, setResponse };
}