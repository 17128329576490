import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { TableCreateButton, TableElementsNotFound, TableSearch, TableSwitch } from 'src/components';
import mainStyle from 'src/styles/main.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRoles } from "src/hooks/User/useRoles";

export const TableContainer = ({ columns, children, request, inputPlaceholder, createText, onCreate, filterForm, differentView, createRole, parentStyle, loader }) => {
    const permissions = useRoles();
    const [index, setIndex] = useState(0);

    return (
        <>
            <div className={mainStyle['container__Row']}>
                {differentView && <TableSwitch index={index} setIndex={setIndex} />}
                { inputPlaceholder && <TableSearch Search={request.changeFilters} inputPlaceholder={inputPlaceholder} filterForm={filterForm} /> }
                {permissions.hasRole(createRole) && <TableCreateButton text={createText} onClick={onCreate} />}
            </div>
            <div className={mainStyle['container__Table']} id="parentScroll" style={parentStyle}>
                <InfiniteScroll loader={loader} className={index === 1 && mainStyle['container__TableChip']} style={{ overflow: 'unset' }} scrollableTarget="parentScroll" dataLength={request.response.content.length} next={request.loadMore} hasMore={request.response.hasMore}>
                    {index === 0 && <Table stickyHeader component={"div"}>
                        <TableHead component={"div"}>
                            <TableRow component={"div"}>
                                {
                                    columns.map((column, index) => (
                                        <TableCell className={mainStyle['container__TableHeaderCell']} size={column.size ? column.size : "medium"} align={column.align ? column.align : "left"} component={"div"} key={index} sortDirection={request.filters.order === column.key ? request.filters.sort : false}>
                                            {
                                                column.key !== 'icon' && <TableSortLabel classes={{ root: mainStyle['container__TableHeaderSortLabel'], active: mainStyle['container__TableHeaderSortLabel'] }} component={"div"} active={request.orderBy === column.key} direction={request.orderBy === column.key ? request.sort : 'asc'} onClick={() => request.handleSorting(column.key)}>
                                                    {column.title}
                                                </TableSortLabel>
                                            }
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody component={"div"} className={mainStyle['container__TableBody']}>
                            {
                                request.response.content.length > 0 ? request.response.content.map((element, index) => children(element, index)) : null
                            }
                        </TableBody>
                        {
                            request.response.content.length <= 0 && <TableElementsNotFound />
                        }
                    </Table>}
                        {
                            index === 1 && <>
                                {request.response.content.length > 0 ? request.response.content.map((element, rowIndex) => differentView({ element: element })) : <TableElementsNotFound />} </>
                        }
                </InfiniteScroll>
            </div>
        </>
    )
}

TableContainer.propTypes = {
    children: PropTypes.any,
    columns: PropTypes.any,
    createText: PropTypes.any,
    inputPlaceholder: PropTypes.any,
    request: PropTypes.any
}
