import React from 'react';
import styles from './Loader.module.scss';
import { CircularProgress } from '@material-ui/core';


export const DetailLoader = ({ position }) => {
    return (
        <div className={position === 'absolute' ? styles['detailLoaderAbsolute'] : styles['detailLoader']}>
            <CircularProgress color="primary" /> 
        </div>
    )
}

export const LoadingScreen = ({ size = '5rem' }) => {
    return (
        <div className={styles['LoadingScreenContainer']}>
            <CircularProgress color="primary" size={size}/>
        </div>
    )
}