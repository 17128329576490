import HttpRequestsHandler from "src/services/http-requests-handler";

const path = {
    GET_VISITS: '/api/visit/all',
    DELETE_VISIT: '/api/visit/:uuid',
    POST_VISIT: '/api/visit',
    GET_VISIT: '/api/visit/:uuid',
    PUT_VISIT: '/api/visit/:uuid',
    CONFIRM_VISIT: '/api/visit/:uuid/confirm',
    GET_SERVICE_TYPE: '/api/servicetype/all',
    GET_SUPPLIERS: '/api/supplier/all',
    POST_SERVICE: '/api/service',
    GET_SERVICES: '/api/service/all',
    DELETE_SERVICE: '/api/service/:uuid',
    CONFIRM_SERVICE: '/api/service/:uuid/confirm',
    CONFIRM_PRICE_OPTION: '/api/service/:uuid/priceOption/:priceOptionUuid/confirm',
    EDIT_SERVICE_BASIC_DATA: '/api/service/:uuid/basicdata',
    CHANGE_SERVICE_SUPPLIER: '/api/service/:uuid/supplier/:supplierUuid',
    CHANGE_PRICE_OPTION: '/api/service/:uuid/priceOption',
    EDIT_VISIT: '/api/visit/:uuid',
    GET_VISIT_HISTORY:'/api/visit/history/all',
    GET_VISIT_HISTORY_DETAIL: '/api/visit/history/:uuid',
    POST_VISIT_COMMENT: '/api/visit/note/:uuid',
    GET_VISIT_COMMENTS: '/api/visit/note/all',
    POST_REIMBURSEMENT: '/api/reimbursement/:visitUuid',
    GET_REIMBURSEMENT_TYPE: '/api/reimbursement/types',
    GET_CURRENCIES: '/api/currency/all',
    GET_PAYMENT_METHOD: '/api/paymentmethod/all',
    GET_ALL_REIMBURSEMENT:'/api/reimbursement/all',
    GET_ALL_UNITS:'/api/distanceunit/all',
    APPROVE_VISIT: '/api/visit/:uuid/sc/approval',
    GET_SCHEDULING_VISITS: '/api/schedulingvisit/all',
    CREATE_VISIT_REIMBURSEMENT: '/api/reimbursement/:uuid/autogenerate'
}


export const GET_VISITS = (filters) => {
    return HttpRequestsHandler("GET", path.GET_VISITS, filters);
}

export const GET_VISIT = (uuid, params) => {
    return HttpRequestsHandler("GET", path.GET_VISIT.replace(/:uuid/gi, uuid), params);
}

export const GET_SCHEDULING_VISITS = (params) => {
    return HttpRequestsHandler("GET", path.GET_SCHEDULING_VISITS, params);
}

export const DELETE_VISIT = (uuid) => {
    return HttpRequestsHandler("DELETE", path.DELETE_VISIT.replace(/:uuid/gi, uuid), null);
}

export const POST_VISIT = (body) => {
    return HttpRequestsHandler("POST", path.POST_VISIT, null, body);
}

export const PUT_VISIT = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.PUT_VISIT.replace(/:uuid/gi, uuid), null, body);
}

export const CONFIRM_VISIT = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.CONFIRM_VISIT.replace(/:uuid/gi, uuid), null, body);
}

export const APPROVE_VISIT = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.APPROVE_VISIT.replace(/:uuid/gi, uuid), null, body);
}

export const GET_SERVICE_TYPE = (params) => {
    return HttpRequestsHandler("GET", path.GET_SERVICE_TYPE, params);
}

export const GET_SUPPLIERS = (params) => {
    return HttpRequestsHandler("GET", path.GET_SUPPLIERS, params);
}

export const POST_SERVICE = (body) => {
    return HttpRequestsHandler("POST", path.POST_SERVICE, null, body);
}

export const GET_SERVICES = (params) => {
    return HttpRequestsHandler("GET", path.GET_SERVICES, params);
}

export const DELETE_SERVICE = (uuid) => {
    return HttpRequestsHandler("DELETE", path.DELETE_SERVICE.replace(/:uuid/gi, uuid), null);
}

export const CONFIRM_SERVICE = (uuid) => {
    return HttpRequestsHandler("PUT", path.CONFIRM_SERVICE.replace(/:uuid/gi, uuid), null, null);
}

export const CONFIRM_PRICE_OPTION = (uuid, priceOptionUuid) => {
    return HttpRequestsHandler("PUT", path.CONFIRM_PRICE_OPTION.replace(/:uuid/gi, uuid).replace(/:priceOptionUuid/gi, priceOptionUuid), null, null);
}

export const EDIT_SERVICE_BASIC_DATA = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.EDIT_SERVICE_BASIC_DATA.replace(/:uuid/gi, uuid), null, body);
}

export const CHANGE_SERVICE_SUPPLIER = (uuid, supplierUuid) => {
    return HttpRequestsHandler("PUT", path.CHANGE_SERVICE_SUPPLIER.replace(/:uuid/gi, uuid).replace(/:supplierUuid/gi, supplierUuid), null, null);
}

export const CHANGE_PRICE_OPTION = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.CHANGE_PRICE_OPTION.replace(/:uuid/gi, uuid), null, body);
}

export const EDIT_VISIT = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.EDIT_VISIT.replace(/:uuid/gi, uuid), null, body);
}

export const GET_VISIT_HISTORY = (filters) => {
    return HttpRequestsHandler("GET", path.GET_VISIT_HISTORY, filters);
}

export const GET_VISIT_HISTORY_DETAIL = (uuid, filters) => {
    return HttpRequestsHandler("GET", path.GET_VISIT_HISTORY_DETAIL.replace(/:uuid/gi, uuid), filters);
}

export const GET_VISIT_COMMENTS = (params) => {
    return HttpRequestsHandler("GET", path.GET_VISIT_COMMENTS, params);
}

export const POST_VISIT_COMMENT = (uuid, body) => {
    return HttpRequestsHandler("POST", path.POST_VISIT_COMMENT.replace(/:uuid/gi, uuid), null, body);
}

export const POST_REIMBURSEMENT = (uuid, body) => {
    return HttpRequestsHandler("POST", path.POST_REIMBURSEMENT.replace(/:visitUuid/gi, uuid), null, body);
}

export const GET_REIMBURSEMENT_TYPE = (filters) => {
    return HttpRequestsHandler("GET", path.GET_REIMBURSEMENT_TYPE, filters);
}

export const GET_CURRENCIES = (filters) => {
    return HttpRequestsHandler("GET", path.GET_CURRENCIES, filters);
}

export const GET_PAYMENT_METHOD = (filters) => {
    return HttpRequestsHandler("GET", path.GET_PAYMENT_METHOD, filters);
}

export const GET_ALL_REIMBURSEMENT = (filters) => {
    return HttpRequestsHandler("GET", path.GET_ALL_REIMBURSEMENT, filters);
}

export const GET_ALL_UNITS = (filters) => {
    return HttpRequestsHandler("GET", path.GET_ALL_UNITS, filters);
}

export const CREATE_VISIT_REIMBURSEMENT = (uuid, body) => {
    return HttpRequestsHandler("POST", path.CREATE_VISIT_REIMBURSEMENT.replace(/:uuid/gi, uuid), null, body);
}