import React, { useEffect, useState } from 'react';
import styles from './Dashboard.module.scss';
import { WelcomeText } from './components/WelcomeText';
import { IsMobile } from 'src/hooks/MediaQuery/isMobile';
import { Collapse, Grid } from '@material-ui/core';
import { UpcomingVisits } from './components/UpcomingVisits';
import { Notifications } from './components/Notification';
import { MobileContainer } from './components/MobileContainer';
import { useTranslation } from 'react-i18next';
import { DetailLoader } from 'src/components';
import { useRoles } from 'src/hooks/User/useRoles';
// import { WarningDialog } from './components/warningDialog/WarningDialog';

export const DashboardContainer = () => {
    const isMobile = IsMobile();
    const [fullHeight, setFullHeight] = useState(false);
    const { i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const roles = useRoles();

    // const [showWarning, setShowWarning] = useState( sessionStorage.getItem('showWarning') && sessionStorage.getItem('showWarning') === 'false' ? false : true);


    useEffect(() => {
        i18n.loadNamespaces('dashboard').then(() => {
            setLoading(false);
        });
    }, [i18n]);

    // const handleClose = () =>{
    //     sessionStorage.setItem('showWarning', false);
    //     setShowWarning(false);
    // }


    if (loading)
        return <DetailLoader />

    return (
        <div className={isMobile ? styles['dashboardMobileContainer'] : styles['dashboardContainer']}>
            {/* { showWarning && <WarningDialog open={showWarning} handleClose={handleClose} />} */}
            {!isMobile ? <WelcomeText /> :
                <Collapse style={{ padding: '25px 0' }} in={!fullHeight} className={styles['dashboardMobileContainer__collapse']}>
                    <WelcomeText />
                </Collapse>}
            {!roles.hasRole('SPONSOR') && <div className={!sessionStorage.getItem('_FL') ? styles['dashboardContainer__body-firstLogin'] : styles['dashboardContainer__body']}>
                {isMobile ? <MobileContainer fullHeight={fullHeight} setFullHeight={setFullHeight} /> :
                    <Grid container>
                        <UpcomingVisits />
                        <Notifications />
                    </Grid>}
            </div>}
        </div>
    )
}