import clsx from 'clsx';
import React from 'react';
import mainStyle from 'src/styles/main.module.scss';
import { useSpring, animated } from 'react-spring';



export const Fade = ({ children, direction, overflowY, ...rest }) => {
    const style = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1,
            flex: 1
        }
    });

    return (
        <animated.div style={style} className={clsx({
            [mainStyle['container__Row']]: direction === "row",
            [mainStyle['container__Column']]: direction === "column",
            [mainStyle['container__VerticalScroll']]: overflowY === "scroll",
            [mainStyle['container__VerticalScrollDisabled']]: overflowY === "hidden",
        })}>
            {children}
        </animated.div>
    )
}