import HttpRequestsHandler from "src/services/http-requests-handler";

const path = {
    GET_CARDS: '/api/card/all',
    GET_CARD: '/api/card/:uuid',
    POST_CARD: '/api/card/bulk',
    ACTIVATE_CARD: '/api/card/:uuid/activate',
    DISABLE_CARD: '/api/card/:uuid/disable',
    ASSIGN_CARD_TO_SITE: '/api/card/:cardUuid/site/:siteUuid',
    UNASSIGN_CARD_FROM_SITE: '/api/card/:cardUuid/site/:siteUuid',
    ASSIGN_CARD_TO_PATIENT: '/api/card/:cardUuid/patient/:patientUuid',
    UNASSIGN_CARD_TO_PATIENT: '/api/card/:cardUuid/patient/:patientUuid',
    REMOVE_CARD_FROM_STUDY: '/api/card/:cardUuid/study/:studyUuid',
    EDIT_TOTAL_AMOUNT: '/api/card/:cardUuid/amount',
    GET_CARD_TRANSACTIONS: '/api/card/:cardUuid/transactions?sort=createdAt_desc',
}

export const GET_CARDS = (params) => {
    return HttpRequestsHandler("GET", path.GET_CARDS, params);
}

export const GET_CARD = (uuid, params) => {
    return HttpRequestsHandler("GET", path.GET_CARD.replace(/:uuid/gi, uuid), params);
}

export const POST_CARD = (body) => {
    return HttpRequestsHandler("POST", path.POST_CARD, null, body);
}

export const ACTIVATE_CARD = (uuid) => {
    return HttpRequestsHandler("PUT", path.ACTIVATE_CARD.replace(/:uuid/gi, uuid), null, null);
}

export const DISABLE_CARD = (uuid) => {
    return HttpRequestsHandler("PUT", path.DISABLE_CARD.replace(/:uuid/gi, uuid), null, null);
}


export const REMOVE_CARD_FROM_STUDY = (cardUuid, studyUuid) => {
    return HttpRequestsHandler("DELETE", path.REMOVE_CARD_FROM_STUDY.replace(/:cardUuid/gi, cardUuid).replace(/:studyUuid/gi, studyUuid), null, null);
}

export const ASSIGN_CARD_TO_SITE = (cardUuid, siteUuid) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_CARD_TO_SITE.replace(/:cardUuid/gi, cardUuid).replace(/:siteUuid/gi, siteUuid), null, null);
}

export const REMOVE_CARD_FROM_SITE = (cardUuid, siteUuid) => {
    return HttpRequestsHandler("DELETE", path.UNASSIGN_CARD_FROM_SITE.replace(/:cardUuid/gi, cardUuid).replace(/:siteUuid/gi, siteUuid), null, null);
}

export const ASSIGN_CARD_TO_PATIENT = (cardUuid, patientUuid) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_CARD_TO_PATIENT.replace(/:cardUuid/gi, cardUuid).replace(/:patientUuid/gi, patientUuid), null, null);
}

export const REMOVE_CARD_FROM_PATIENT = (cardUuid, patientUuid) => {
    return HttpRequestsHandler("DELETE", path.UNASSIGN_CARD_TO_PATIENT.replace(/:cardUuid/gi, cardUuid).replace(/:patientUuid/gi, patientUuid), null, null);
}

export const EDIT_TOTAL_AMOUNT = (cardUuid, body) => {
    return HttpRequestsHandler("PUT", path.EDIT_TOTAL_AMOUNT.replace(/:cardUuid/gi, cardUuid), null, body);
}

export const GET_CARD_TRANSACTIONS = (params) => {
    let _params = {...params};
    delete _params.cardUuid;

    return HttpRequestsHandler("GET", path.GET_CARD_TRANSACTIONS.replace(/:cardUuid/gi, params.cardUuid), _params);
}