import { useCallback, useEffect, useState } from "react";
import { useAuth } from "src/context/auth-context";
import { HandleErrorCatching } from "src/roots/AuthenticatedApp/components/utils/utils";
import { ACTIVATE_CARD, ASSIGN_CARD_TO_PATIENT, ASSIGN_CARD_TO_SITE, DISABLE_CARD, EDIT_TOTAL_AMOUNT, GET_CARDS, GET_CARD_TRANSACTIONS, POST_CARD, REMOVE_CARD_FROM_PATIENT, REMOVE_CARD_FROM_SITE, REMOVE_CARD_FROM_STUDY } from "./services";


export function useDebitCards(ownerUuid = null, ownerType = null) {
    const auth = useAuth();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const Load = useCallback(
        async (params) => {
            try {
                setLoading(true);
                const response = await GET_CARDS(params);
                setData(response.data.content);
                setLoading(false);
                return response.data.content;
            } catch (error) {
                HandleErrorCatching(error, Load, auth);
                setLoading(false);
            }
        },
        [auth],
    )

    const Create = async (body, onResolve) => {
        setLoading(true);
        if (body.cards.length > 0) {
            POST_CARD(body).then(response => {
                if (onResolve) {
                    onResolve(response);
                }
                Load();
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                HandleErrorCatching(error, Load, auth);
            });
        } else {
            setLoading(false);
            if (onResolve) {
                onResolve();
            }
        }
    }

    const Assign = async (props, onResolve) => {
        let { ownerType, cards, ownerUuid, cardUuid } = props;
        if (ownerType === 'SITE') {
            let _promises = [];
            if (cards.length > 0) {
                cards.forEach(card => {
                    if (card.uuid)
                        _promises.push(ASSIGN_CARD_TO_SITE(card.uuid, ownerUuid));
                });
                Promise.all(_promises).then(response => {
                    if (onResolve) {
                        onResolve(response);
                    }
                });
            } else {
                if (onResolve) {
                    onResolve();
                }
            }
        }
        if(ownerType === 'PATIENT'){
            ASSIGN_CARD_TO_PATIENT(cardUuid, ownerUuid).then(onResolve).catch(error => { HandleErrorCatching(error, Load, auth); });
        }
    }

    const UnAssign = async (props, onResolve) => {
        let { ownerType, cards, ownerUuid } = props;
        if (ownerType === 'SITE') {
            let _promises = [];
            if (cards.length > 0) {
                cards.forEach(card => {
                    if (card.uuid)
                        _promises.push(REMOVE_CARD_FROM_SITE(card.uuid, ownerUuid));
                });
                Promise.all(_promises).then(response => {
                    if (onResolve) {
                        onResolve(response);
                    }
                });
            } else {
                if (onResolve) {
                    onResolve();
                }
            }
        }
    }

    const Activate = async (cardUuid, onResolve) => {
        try {
            ACTIVATE_CARD(cardUuid).then(response => {
                if (onResolve) {
                    onResolve(response);
                }
            })
        } catch (error) {
            setLoading(false);
            HandleErrorCatching(error, Load, auth);
        }
    }

    const Disable = async (cardUuid, onResolve) => {
        try {
            DISABLE_CARD(cardUuid).then(response => {
                if (onResolve) {
                    onResolve(response);
                }
            })
        } catch (error) {
            setLoading(false);
            HandleErrorCatching(error, Load, auth);
        }
    }

    const Remove = async (props, onResolve) => {
        let { cardType, cardUuid, studyUuid, siteUuid, patientUuid } = props;
        if (cardType === 'STUDY') {
            REMOVE_CARD_FROM_STUDY(cardUuid, studyUuid).then(response => {
                if (onResolve) {
                    onResolve(response);
                }
            }).catch(error => {
                setLoading(false);
                HandleErrorCatching(error, Load, auth);
            });
        } else if (cardType === 'SITE') {
            REMOVE_CARD_FROM_SITE(cardUuid, siteUuid).then(response => {
                if (onResolve) {
                    onResolve(response);
                }
            }).catch(error => {
                setLoading(false);
                HandleErrorCatching(error, Load, auth);
            });
        } else if (cardType === 'PATIENT') {
            REMOVE_CARD_FROM_PATIENT(cardUuid, patientUuid).then(response => {
                if (onResolve) {
                    onResolve(response);
                }
            }).catch(error => {
                setLoading(false);
                HandleErrorCatching(error, Load, auth);
            });
        }
    }

    const EditTotalAmount = (cardUuid, amount, onResolve) => {

        EDIT_TOTAL_AMOUNT(cardUuid, { "totalAmount": amount }).then(response => {
            if (onResolve) {
                onResolve(response);
            }
        }).catch(error => {
            setLoading(false);
            HandleErrorCatching(error, Load, auth);
        });
    }

    const GetTransactions = (cardUuid, params, onResolve) => {
        GET_CARD_TRANSACTIONS(cardUuid, params).then(response => {
            if (onResolve) {
                onResolve(response);
            }
        }).catch(error => {
            setLoading(false);
            HandleErrorCatching(error, Load, auth);
        });
    }

    useEffect(() => {
        if (ownerUuid) {
            Load({ ownerType: ownerType, ownerUuid: ownerUuid });
        }
    }, [Load, ownerUuid, ownerType])

    return {
        data,
        loading,
        Load,
        Create,
        Remove,
        Assign,
        UnAssign,
        EditTotalAmount,
        GetTransactions,
        Activate,
        Disable
    }
}