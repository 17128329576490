import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableCell, SvgIcon, IconButton } from '@material-ui/core';
import { ReactComponent as Other } from 'assets/other-gender.svg';
import { ReactComponent as Female } from 'assets/female.svg';
import { ReactComponent as Male } from 'assets/male.svg';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useTranslation } from 'react-i18next';
import TableContainer from 'src/roots/AuthenticatedApp/components/tables/tableContainer/tableContainer';
import { ParagonTableRow } from 'src/roots/AuthenticatedApp/components/tables/tableContainer/paragonTable/paragonTable';
import { navigateThroughDetail } from '../utils';
import { PatientChip } from 'src/pages/Patients/components/Table/singleChip';


const RenderRow = ({ element, styles }) => {
    const history = useHistory();

    return (
        <>
            <TableCell className={styles['tableBodyContainer__cell']} size="medium" component={"div"}>
                <IconButton color="primary" size="small" onClick={() => navigateThroughDetail(history, `/patients/detail/${element.uuid}`)}>
                    <OpenInNewIcon />
                </IconButton>
            </TableCell>
            <TableCell className={styles['tableBodyContainer__cell']} size="medium" component={"div"}>
                {
                   element.code ? element.code : '---'
                }
            </TableCell>
            <TableCell className={styles['tableBodyContainer__cell']} size="medium" component={"div"}>
                {
                   element.fullName ? element.fullName : '---'
                }
            </TableCell>
            <TableCell className={styles['tableBodyContainer__cell']} size="medium" component={"div"}>
                {
                   element.gender ? element.gender.toUpperCase() === 'MALE' ? <SvgIcon htmlColor="#339EFA" style={{ position: 'absolute', top: '10px', left: '35px' }}><Male /></SvgIcon> : element.gender.toUpperCase() === 'OTHER' ? <SvgIcon htmlColor="#000" style={{ position: 'absolute', top: '10px', left: '45px' }}><Other /></SvgIcon> : <SvgIcon htmlColor="#ED43D3" style={{ position: 'absolute', top: '10px', left: '35px' }}><Female /></SvgIcon> : '---'
                }
            </TableCell>
            <TableCell className={styles['tableBodyContainer__cell']} size="medium" component={"div"}>
                {
                   element.email ? element.email : '---'
                }
            </TableCell>
            <TableCell className={styles['tableBodyContainer__cell']} size="medium" component={"div"}>
                {
                   element.location && element.location.city ? element.location.city : '---'
                }
            </TableCell>
            <TableCell className={styles['tableBodyContainer__cell']} size="medium" component={"div"}>
                {
                   element.location && element.location.country ? element.location.country : '---'
                }
            </TableCell>
        </>
    )
}


export const PatientTab = ({getFn, getFilter, removePatientFn}) =>{
    const { t } = useTranslation();


const columns = [
    {
        title: "",
        key: "icon"
    },
    {
        title: t('patients:patientNumber'),
        key: "code"
    },
    {
        title: t('patients:fullName'),
        key: "fullName"
    },
    {
        title: t('patients:gender'),
        key: "gender",
        size: "small",
        align: "center"
    },
    {
        title: t('patients:email'),
        key: "email"
    },
    {
        title: t('patients:city'),
        key: "city"
    },
    {
        title: t('patients:country'),
        key: "country"
    }
];


    return (
        <TableContainer
        title={t('patients:patients')}
        searchPlaceholder={t('patients:searchPatient')}
        getFn={getFn}
        columns={columns}
        defaultFilters={{ sort: 'createdAt_desc', ...getFilter }}
        createLabel={t('patients:createPatient')}
        chipComponent={PatientChip}
    >
        <ParagonTableRow>
            <RenderRow />
        </ParagonTableRow>
    </TableContainer>
    )
};