import React, { cloneElement, createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styles from './tableContainer.module.scss';
import { IconButton, SvgIcon, InputBase, Button } from '@material-ui/core';
import { ReactComponent as Search } from 'assets/search.svg';
import ClearIcon from '@material-ui/icons/Clear';
import { IsMobile } from 'components/utils/utils';
import FilterListIcon from '@material-ui/icons/FilterList';
import ListIcon from '@material-ui/icons/List';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import AddIcon from '@material-ui/icons/Add';
import { HandleErrorCatching } from 'components/utils/utils';
import { useAuth } from 'src/context/auth-context';
import ParagonTable, { ParagonTableRow } from './paragonTable/paragonTable';
import { CustomActionButton } from 'components/inputs/Button';
import Tutorial from '../../generalComponents/tourController/tourController';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { ParagonButton } from 'src/components/Button';

const SearchInput = forwardRef(({ searchFn, InputPlaceholder }, ref) => {
    const [searchValue, setSearchValue] = useState(null);

    useEffect(() => {
        if (typeof searchValue === 'string' && searchValue.length === 0) {
            searchFn(null);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchValue]);

    const onSearch = event => {
        if (event.key === 'Enter' && searchValue.length) {
            searchFn(searchValue);
        }
    }

    const resetTextFn = () => {
        setSearchValue('');
        searchFn(null);
    }

    const ClearButton = ({ resetTextFn }) => {
        return (
            <IconButton size="small" onClick={resetTextFn}>
                <ClearIcon />
            </IconButton>
        )
    }


    return (
        <div className={styles['searchInput']} ref={ref} id="searchInput">
            <div className={styles['searchInput__icon']}>
                <IconButton size="small" onClick={() => searchValue ? searchFn(searchValue) : null}>
                    <SvgIcon fontSize="default" ><Search /></SvgIcon>
                </IconButton>
            </div>
            <InputBase value={searchValue || ''} onChange={e => { setSearchValue(e.target.value) }} onKeyDown={onSearch} classes={{ root: styles['searchInput__input'] }} placeholder={InputPlaceholder}
                endAdornment={searchValue && searchValue.length > 0 ? <ClearButton resetTextFn={resetTextFn} /> : null} />
        </div>
    )
})

const ChangeTableViewButtons = ({ view, setView }) => {
    return (
        <div id="changeTableView" className={styles['buttonGroup']}>
            <IconButton onClick={() => { setView('table') }} className={view === 'table' ? styles['buttonGroup__leftButtonActive'] : styles['buttonGroup__leftButton']}><ListIcon /></IconButton>
            <IconButton onClick={() => { setView('chip') }} className={view === 'chip' ? styles['buttonGroup__rightButtonActive'] : styles['buttonGroup__rightButton']}><ViewModuleIcon /></IconButton>
        </div>
    )
}

const FilterButton = ({ filters, filterFields, applyFilters, parentRef }) => {
    const [openFilters, setOpenFilters] = useState(false);
    const [filtersNumber, setFiltersNumber] = useState(0);
    const { t } = useTranslation();

    const handleSubmit = (values) => {
        let _filters = Object.entries(values).reduce((a, [k, v]) => (v == null || k === 'sort' ? a : (a[k] = v, a)), {});
        setFiltersNumber(Object.keys(_filters).length);
        applyFilters(_filters);
        setOpenFilters(false);
    }

    const handleClose = () => {
        applyFilters({});
        setOpenFilters(false);
        setFiltersNumber('');
    }

    return (
        <>
            <Button id="filterButton" style={{ color: openFilters ? '' : '#484748' }} color={openFilters ? 'primary' : 'inherit'} onClick={() => { setOpenFilters(!openFilters) }} variant={"outlined"} className={styles['filterButton']} startIcon={<FilterListIcon />} endIcon={filtersNumber > 0 && <div className={styles['filterButton__badge']}>{filtersNumber}</div>} >
                {t('generic:filters')}
            </Button>
            {
                openFilters && cloneElement(filterFields, { filters: filters, parentRef: parentRef, handleClose: handleClose, styles: styles, onSubmit: handleSubmit })
            }
        </>

    )
}

const CreateButton = ({ tutorialSection, createLabel, createButtonDialogTitle, CreateForm, maxWidth, allowIcon, reloadElements, redirectCreation }) => {
    const _isMobile = IsMobile();

    if (redirectCreation) {
        return <div className={styles['createButtonContainer']}>
            <ParagonButton onClick={redirectCreation} startIcon={allowIcon && <AddIcon fontSize="large" />} variant="contained" color="primary" className={styles['createButtonContainer__button']} fullWidth maxWidth={maxWidth ? maxWidth : "md"} text={createLabel} />
        </div>
    }

    return (
        !_isMobile ? <div className={styles['createButtonContainer']}>
            <CustomActionButton onCloseFn={reloadElements} tutorialSection={tutorialSection} id="create" startIcon={allowIcon && <AddIcon fontSize="large" />} variant="contained" color="primary" className={styles['createButtonContainer__button']} fullWidth maxWidth={maxWidth ? maxWidth : "md"} text={createLabel} dialogTitle={createButtonDialogTitle}>
                <CreateForm />
            </CustomActionButton>
        </div>
            :
            <CustomActionButton onCloseFn={reloadElements} tutorialSection={tutorialSection} id="create" isFab icon={<AddIcon fontSize="large" />} color="primary" className={styles['createButtonContainer__fab']} fullScreen fullWidth maxWidth={maxWidth ? maxWidth : "md"} text={createLabel} dialogTitle={createButtonDialogTitle}>
                <CreateForm />
            </CustomActionButton>
    )
}

const TableContainer = forwardRef((props, ref) => {
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const _isMobile = IsMobile();
    const parentRef = createRef();
    const auth = useAuth();
    const { params: { section } } = useRouteMatch('/:section');
    const { children, title, searchPlaceholder, createLabel, createFn, tutorialSection, allowIcon, disableTitle, disableFilters, redirectCreation } = props;
    const { getFn, defaultFilters, columns, createForm, createButtonDialogTitle, maxWidth, chipComponent, disableTutorial, headerCellStyle, coloredTitle } = props;
    const isDetail = window.location.href.includes('detail');
    const [view, setView] = useState(_isMobile && ((!isDetail && section === 'studies') || (!isDetail && section === 'patients')) ? 'chip' : 'table');
    const { filterFields, paragonTableRow } = parseChildren();
    const [showTutorial, setShowTutorial] = useState(false);

    // filters && loading consts
    const [filters, setFilters] = useState({ ...defaultFilters });
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();

    // table consts
    const [elements, setElements] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        loadElements();
        if (auth?._data?.profile?.firstLogin) {
            setShowTutorial(true);
        }
    },
        // eslint-disable-next-line
        []);

    function parseChildren() {
        let filterFields = null;
        let paragonTableRow = null;

        React.Children.forEach(children, child => {
            if (child.type === TableFilterDialog) {
                filterFields = child;
            } else if (child.type === ParagonTableRow) {
                paragonTableRow = child;
            }
        });

        return { filterFields, paragonTableRow };
    }

    const loadElements = async (_filters) => {
        try {
            const response = await getFn(_filters ? _filters : filters);
            setIsLoading(false);
            setElements(response.data.content);
            setPage(response.data.number);
            setHasMore(!response.data.last);
        } catch (error) {
            HandleErrorCatching(error, loadElements, auth);
        }
    }

    const getMoreElements = async () => {
        let _filters = { ...filters, page: page + 1 };
        try {
            const response = await getFn(_filters);
            let _elements = [...elements].concat(response.data.content);

            setIsLoading(false);
            setElements(_elements);
            setPage(response.data.number);
            setHasMore(!response.data.last);
            setFilters(_filters);
        } catch (error) {
            HandleErrorCatching(error, getMoreElements, auth);
        }
    }

    const handleSorting = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        let _sort = isAsc ? 'desc' : 'asc';
        let _property = property !== 'fullName' ? property : 'firstName';
        let _filters = { ...filters, sort: _property + '_' + _sort, page: 0 }
        setIsLoading(true);
        setOrder(_sort);
        setOrderBy(property);
        setFilters(_filters);
        setElements([]);
        loadElements(_filters);
    }

    const searchFn = (value) => {
        setIsLoading(true);
        let _filters = { ...filters };
        delete _filters['page'];

        if (value) {
            _filters['q'] = value.trim();
        } else {
            _filters = { ...defaultFilters };
        }
        setFilters(_filters);
        loadElements(_filters);
    }

    const applyFilters = (values) => {
        setIsLoading(true);
        setFilters(values);
        loadElements(values);
    }

    const openTutorial = () => {
        setShowTutorial(true);
    }


    useImperativeHandle(ref, () => ({
        reloadElements: async (_filters) => {
            setIsLoading(true);
            setFilters(_filters);
            loadElements(_filters);
        },
        removeSort: () => {
            setOrder(null);
            setOrderBy(null);
        }
    }));

    return (
        <div className={styles['container']}>
            {!isLoading && !isDetail && !disableTutorial && <Tutorial onClose={() => setShowTutorial(false)} openTutorial={showTutorial} section={`${section}__table`} disableInteraction />}
            {!disableTitle && <div className={coloredTitle ? styles['container__titleColored'] : styles['container__title']}>
                {
                    query.get('allowBack') && <IconButton style={{ marginRight: '15px' }} onClick={() => history.goBack()}><ArrowBackRoundedIcon /></IconButton>
                }
                {title}
                {tutorialSection && <div className={styles['container__title-icon']}>
                    <IconButton size="small" onClick={openTutorial}>
                        <HelpIcon />
                    </IconButton>
                </div>}
            </div>}
            {!disableFilters && <div className={styles['container__actionContainer']}>
                {((_isMobile && section !== 'studies' && section !== 'patients') || !_isMobile) && chipComponent && <ChangeTableViewButtons view={view} setView={setView} />}
                {searchPlaceholder && <SearchInput searchFn={searchFn} InputPlaceholder={searchPlaceholder} ref={parentRef} />}
                {filterFields && <FilterButton filters={filters} filterFields={filterFields} applyFilters={applyFilters} parentRef={parentRef} />}
                {((typeof createForm !== 'function' && createForm) || (typeof createForm === 'function' && createForm())) && <CreateButton allowIcon={allowIcon} tutorialSection={tutorialSection} maxWidth={maxWidth} createFn={createFn} createLabel={createLabel} createButtonDialogTitle={createButtonDialogTitle} CreateForm={createForm} reloadElements={loadElements} />}
                {redirectCreation && <CreateButton redirectCreation={redirectCreation} allowIcon={allowIcon} tutorialSection={tutorialSection} maxWidth={maxWidth} createFn={createFn} createLabel={createLabel} createButtonDialogTitle={createButtonDialogTitle} CreateForm={createForm} reloadElements={loadElements} />}
            </div>}
            <ParagonTable headerCellStyle={headerCellStyle} tableStyle={props?.tableStyle} reloadElements={loadElements} paragonTableRow={paragonTableRow} columns={columns} elements={elements} getMoreElements={getMoreElements} view={view} ChipComponent={chipComponent}
                hasMore={hasMore} order={order} orderBy={orderBy} handleSorting={handleSorting} filters={filters} isLoading={isLoading} />
        </div>

    )
})

const TableFilterDialog = ({ filters, children, onSubmit, handleClose, parentRef }) => {
    const element = parentRef?.current;

    return (
        <div style={{ width: `${element?.offsetWidth}px`, top: `${element?.offsetTop + 55}px`, left: element?.offsetLeft }} className={styles['filterDialogContainer']}>
            {
                cloneElement(children, { filters: filters, handleClose: handleClose, styles: styles, onSubmit: onSubmit })
            }
        </div>
    )
}

export { TableFilterDialog };
export default TableContainer;