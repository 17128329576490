import React, { useState } from 'react';
import styles from './singleChip.module.scss';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, SvgIcon, MenuItem, Menu, Button } from '@material-ui/core';
import { ReactComponent as Caregiver } from 'assets/caregiver.svg';
import { useHistory } from 'react-router-dom';
import { DELETE_PATIENT } from 'src/pages/Patients/services';
import LaunchIcon from '@material-ui/icons/Launch';
import { CATCH_ERRORS } from 'context/services';
import { useAuth } from 'context/auth-context';
import { useTranslation } from 'react-i18next';

const DotIcon = ({reload, uuid}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const { refreshToken } = useAuth();
    const { t } = useTranslation();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const gotoDetail = () => {
        history.push('/patients/detail/' + uuid);
    }

    const deletePatient = () => {
        DELETE_PATIENT(uuid).then(data => {
            reload();
            handleClose();
        }, error => {
            CATCH_ERRORS({ error: error.stack });
            if (error?.response?.status === 401) {
                refreshToken(() => deletePatient());
            }
        }).catch(catchedError => CATCH_ERRORS({ error: catchedError.stack }));
    }

    return (
        <div>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} size="small">
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={gotoDetail}>{t('generic:openDetail')}</MenuItem>
                <MenuItem onClick={deletePatient}>{t('patients:deletePatient')}</MenuItem>
            </Menu>
        </div>
    );

}

export const PatientChip = ({ element }) => {
        const { code, uuid, gender, firstName, lastName, fullName, primaryCountryCode, primaryAreaCode, primaryPhone, location, caregiverList, email, study, reload } = element;
        const history = useHistory();
        const { t } = useTranslation();

        return(
            <div className={styles['chipContainer']}>
                <div className={styles['chipContainer__firstRow']}>
                    <div className={styles['chipContainer__firstRow-iconContainer']}>
                        <div className={gender === 'Male' ? styles['chipContainer__firstRow-iconContainer-firstCircle'] : gender === 'Female' ? styles['chipContainer__firstRow-iconContainer-firstCircleRose'] : styles['chipContainer__firstRow-iconContainer-firstCircleBlack']}>
                            <div className={gender === 'Male' ? styles['chipContainer__firstRow-iconContainer-secondCircle'] : gender === 'Female' ? styles['chipContainer__firstRow-iconContainer-secondCircleRose'] : styles['chipContainer__firstRow-iconContainer-secondCircleBlack']}>
                                <div className={styles['chipContainer__firstRow-iconContainer-initials']}>
                                    {firstName.charAt(0) + lastName.charAt(0)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['chipContainer__firstRow-dotButton']}>
                        <DotIcon reload={reload} uuid={uuid}/>
                    </div>
                </div>
                <div className={styles['chipContainer__secondRow']}>
                        <div className={styles['chipContainer__secondRow-name']}>{fullName ? fullName : '---'}</div>
                        <div className={styles['chipContainer__secondRow-ssn']}>{code ? code : '---'}</div>
                </div>
                <div className={styles['chipContainer__rowDivider']}></div>
                <div className={styles['chipContainer__singleRow']}>
                    <div className={styles['chipContainer__singleRow-container']}>
                        <div className={styles['chipContainer__singleRow-container-title']}>{t('patients:country')}</div>
                        <div className={styles['chipContainer__singleRow-container-text']}>{location && location.country ? location.country : '---'}</div>
                    </div>
                    <div className={styles['chipContainer__grow']}></div>
                    <SvgIcon htmlColor={caregiverList && caregiverList.length > 0 ? "#484748" : "#D1D1D1"}><Caregiver /></SvgIcon>
                </div>
                <div className={styles['chipContainer__singleRow']}>
                    <div className={styles['chipContainer__singleRow-container']}>
                        <div className={styles['chipContainer__singleRow-container-title']}>{t('patients:city')}</div>
                        <div className={styles['chipContainer__singleRow-container-text']}>{location && location.city ? location.city : '---'}</div>
                    </div>
                </div>
                <div className={styles['chipContainer__singleRow']}>
                    <div className={styles['chipContainer__singleRow-container']}>
                        <div className={styles['chipContainer__singleRow-container-title']}>{t('patients:contacts')}</div>
                        <div className={styles['chipContainer__singleRow-container-text']}>{email ? email : '---'}</div>
                        <div className={styles['chipContainer__singleRow-container-text']}>{[primaryCountryCode, primaryAreaCode, primaryPhone].join(' ')}</div>
                    </div>
                </div>
                <div className={styles['chipContainer__rowDivider']}></div>

                <div className={styles['chipContainer__singleRow-centered']}>
                    <div className={styles['chipContainer__singleRow-container']}>
                        <div className={styles['chipContainer__singleRow-container-text']}>{study && study.code ? study.code : '---'}</div>
                    </div>
                </div>
                <div className={styles['chipContainer__buttonRow']}>
                    <Button className={styles['chipContainer__buttonRow-button']} color="primary" onClick={() => history.push('/patients/detail/' + uuid)}> <LaunchIcon />&ensp; {t('generic:openDetail')}</Button>
                </div>
            </div>
        )
}