import React, { forwardRef, useImperativeHandle, useState } from 'react';
import mainStyle from 'src/styles/main.module.scss';
import ListIcon from '@material-ui/icons/List';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { IconButton } from '@material-ui/core';

export const TableSwitch = forwardRef(({ index, setIndex}, ref) => {

    function changeIndex(index) {
        setIndex(index);
    }

    useImperativeHandle(ref, () => ({
        index: index
    }));

    return (
        <div id="changeTableView" className={mainStyle['container__Switch']}>
            <IconButton disableFocusRipple disableRipple onClick={() => changeIndex(0)} className={mainStyle[`container__SwitchLeft${index === 0 && 'Active'}`]}><ListIcon /></IconButton>
            <IconButton disableFocusRipple disableRipple onClick={() => changeIndex(1)} className={mainStyle[`container__SwitchRight${index === 1 && 'Active'}`]}><ViewModuleIcon /></IconButton>
        </div>
    );
});