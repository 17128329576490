import React, { useEffect,  } from 'react';
import { AuthContext, useAuth } from './context/auth-context';
import Notifier from 'commonContext/notifier';
import axios from 'axios';
import { useNotifier, NotifierContext } from './context/NotifierContext';
import { App, Login } from './pages';
import { LoadingScreen } from './components';
import { useTranslation, withTranslation } from 'react-i18next';

let lastAction = null;

const Application = () => {
  const auth = useAuth();
  const openNotifier = useNotifier();
  const { t } = useTranslation();
  
  useEffect(() => {
    if (sessionStorage.getItem('LA')) {
      lastAction = sessionStorage.getItem('LA');
      // updateLastAction(lastAction);
    }
  }, []);

  axios.interceptors.response.use(function (response) {
    if (response.status === 200) {
      lastAction = new Date();
      sessionStorage.setItem('LA', lastAction);
    }
    // eslint-disable-next-line no-mixed-operators
    if (response.config.method.includes('post') && response.data.message || response.config.method.includes('delete') && response.data.message || response.config.method.includes('put') && response.data.message) {
      if (response?.config?.url !== '/api/user/configuration')
        openNotifier("success", t(response.data.message.replace('paragon.', 'response:')), 10000);
    }
    return response;
  }, function (error) {

    let response = error.response;
    // HandleErrorCatching(error, () => null, auth);

    if (response && response?.data && response?.data?.code !== 401 && response?.data.code !== 403 && response?.data.code !== 404) {
      if (response?.data?.message && response?.status !== 401)
        openNotifier("error", t(response.data.message.replace('paragon.', 'response:')), 10000);
    } else if (response?.status === 403) {
      // openNotifier("error", "User not authorized", 10000);
      openNotifier("error", t(response.data.message.replace('paragon.', 'response:')), 10000);
      auth.handleError(403);
    } else if (response?.status === 404) {
      // openNotifier("error", "User not authorized", 10000);
      openNotifier("error", t(response.data.message.replace('paragon.', 'response:')), 10000);
      auth.handleError(404);
    }
    else if (response?.data.code === 401) {
      if (response.data.message === "paragon.authentication.password_expired" || response.data.message === "paragon.authentication.invalid")
        openNotifier("warning", t(response.data.message.replace('paragon.', 'response:')), 10000);
      else
        console.log(response.data.message);
      // openNotifier("error", t(response.data.message.replace('paragon.', 'response:')), 10000);
    }
    return Promise.reject(error);
  });

  return (
      <AuthContext.Consumer>
        {
          ({ _data, isPending, displayError }) =>
            <NotifierContext.Consumer>
              {({ openNotifier, closeNotifier, status, message, displayNotifier }) => <>
                {isPending && <LoadingScreen />}
                {!isPending && _data.token && <App displayError={displayError} openNotifier={openNotifier} passwordExpired={_data.passwordExpired} roles={_data.roles} />}
                {!isPending && !_data.token && <Login />}
                <Notifier logout={_data.signOut} openNotifier={openNotifier} closeNotifier={closeNotifier} store={{ displayNotifier: displayNotifier, message: message, status: status }} />
              </>
              }
            </NotifierContext.Consumer>
        }
      </AuthContext.Consumer>
  );

};

export default withTranslation()(Application);
