import { useEffect, useState } from "react";
import { useAuth } from "src/context/auth-context";
import { DELETE_DOCUMENT, DOWNLOAD_DOCUMENT } from "src/pages/services";
import { HandleErrorCatching } from "src/roots/AuthenticatedApp/components/utils/utils";
import { useRoles } from "../User/useRoles";
import { ASSIGN_SUPPLIER, DELETE_STUDY, GET_STUDY_CONFIGURATION, GET_STUDY_DETAIL, COMPLETE_STUDY, PUT_STUDY_CONFIGURATION, ASSIGN_SERVICETYPE, UNASSIGN_SERVICETYPE, ASSIGN_REIMBURSEMENT_TYPE, UNASSIGN_REIMBURSEMENT_TYPE, ASSIGN_PAYMENT_METHOD, UNASSIGN_PAYMENT_METHOD, ASSIGN_STUDY_LANGUAGE, UNASSIGN_STUDY_LANGUAGE, UNASSIGN_SUPPLIER, ACTIVATE_STUDY, GET_FOLLOW_STUDY, FOLLOW_STUDY, UNFOLLOW_STUDY, PUT_STUDY, GET_STUDY_DOCUMENTS, POST_SCHEDULING_GRID, DELETE_SCHEDULING_GRID, PUT_SCHEDULING_GRID } from "./services";


export function useStudy(uuid) {
    const auth = useAuth();
    const permissions = useRoles();
    const [data, setData] = useState(null);
    const [follow, setFollow] = useState(false);
    const [configuration, setConfiguration] = useState(null);
    const [files, setFiles] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingConfiguration, setLoadingConfiguration] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);

    const loadDetail = async () => {
        try {
            setLoading(true);
            const response = await GET_STUDY_DETAIL(uuid);
            setData(response.data);

            if (permissions.hasRole('ROLE_ICARE_STUDY_FOLLOW')) {
                const following = await GET_FOLLOW_STUDY(uuid);
                setFollow(following?.data?.following);
            }

            setLoading(false);
        } catch (error) {
            HandleErrorCatching(error, loadDetail, auth);
            setLoading(false);
        }
    }

    const Edit = (values, onResolve) => {
        PUT_STUDY(uuid, values).then(() => {
            if (onResolve) {
                onResolve();
            }
        }, error => HandleErrorCatching(error, () => Edit(values, onResolve), auth)).catch(error => HandleErrorCatching(error, () => Edit(values, onResolve), auth));
    }

    const Activate = (onResolve) => {
        ACTIVATE_STUDY(uuid).then(() => {
            if (onResolve) {
                onResolve();
            }
        }, error => HandleErrorCatching(error, Complete, auth)).catch(error => HandleErrorCatching(error, Complete, auth));
    }

    const Complete = (onResolve) => {
        COMPLETE_STUDY(uuid, { status: 'COMPLETED' }).then(() => {
            loadDetail();
            if (onResolve) {
                onResolve();
            }
        }, error => HandleErrorCatching(error, Complete, auth)).catch(error => HandleErrorCatching(error, Complete, auth));
    }

    const Deactivate = (onResolve) => {
        DELETE_STUDY(uuid).then(() => {
            if (onResolve) {
                onResolve();
            }
            // history.goBack();
        }, error => HandleErrorCatching(error, Deactivate, auth)).catch(error => HandleErrorCatching(error, Deactivate, auth));
    }


    const GetConfiguration = (newUuid, onResolve) => {
        try {
            setLoadingConfiguration(true);
            GET_STUDY_CONFIGURATION(newUuid ? newUuid : uuid).then(response => {

                let config = {};

                response.data.elementList.forEach(element => {
                    if (element.type === 'JSON') {
                        config[element.code] = JSON.parse(element.value);
                    } else {
                        if (element.type === 'BOOLEAN') {
                            config[element.code] = element.value.toLowerCase() === 'true' ? true : false;
                        } else {
                            config[element.code] = element.value;
                        }
                    }
                });

                if (!config['PATIENT_CONFIGURATION']) {
                    config['PATIENT_CONFIGURATION'] = {
                        initials: {
                            label: 'patients:initials',
                            hidden: false,
                            mandatory: true
                        },
                        originalName: {
                            label: 'patients:originalName',
                            hidden: false,
                            mandatory: false
                        },
                        firstName: {
                            label: 'patients:firstName',
                            hidden: false,
                            mandatory: true
                        },
                        middleName: {
                            label: 'patients:middleName',
                            hidden: false,
                            mandatory: false
                        },
                        lastName: {
                            label: 'patients:lastName',
                            hidden: false,
                            mandatory: true
                        },
                        languages: {
                            label: 'patients:languages',
                            hidden: false,
                            mandatory: true
                        },
                        gender: {
                            label: 'patients:gender',
                            hidden: false,
                            mandatory: true
                        },
                        birthDate: {
                            label: 'patients:birthdate',
                            hidden: false,
                            mandatory: true
                        },
                        fax: {
                            label: 'patients:fax',
                            hidden: false,
                            mandatory: false
                        },
                        workPhone: {
                            label: 'patients:workphone',
                            hidden: false,
                            mandatory: false
                        },
                        workExtensionPhone: {
                            label: 'patients:workExtension',
                            hidden: false,
                            mandatory: false
                        },
                        workCountryCode: {
                            label: 'patients:workCountryCode',
                            hidden: false,
                            mandatory: false
                        },
                        primaryPhone: {
                            label: 'patients:cellphone',
                            hidden: false,
                            mandatory: true
                        },
                        primaryCountryCode: {
                            label: 'patients:cellphoneCountryCode',
                            hidden: false,
                            mandatory: false
                        },
                        address: {
                            label: 'patients:address',
                            hidden: false,
                            mandatory: true
                        },
                        caregiverList: {
                            label: 'patients:caregivers',
                            hidden: false,
                            mandatory: false
                        },
                        emergencyContactList: {
                            label: 'patients:emergencyContacts',
                            hidden: false,
                            mandatory: false
                        }
                    }
                }

                if (onResolve) {
                    onResolve(config);
                }

                setConfiguration(config);
                setLoadingConfiguration(false);
            });
        } catch (error) {
            HandleErrorCatching(error, GetConfiguration, auth);
            setLoading(false);
        }
    }

    const EditConfiguration = (body, onResolve) => {
        PUT_STUDY_CONFIGURATION(uuid, body).then(() => {
            if (onResolve) {
                onResolve();
            }
        }, error => HandleErrorCatching(error, EditConfiguration, auth)).catch(error => HandleErrorCatching(error, EditConfiguration, auth));
    }

    const AssignSuppliers = (body, onResolve) => {
        let _toSend = body.map(el => { return { uuid: el.uuid } });
        if (_toSend.length)
            ASSIGN_SUPPLIER(uuid, { supplierList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => AssignSuppliers(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => AssignSuppliers(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const RemoveSuppliers = (body, onResolve) => {
        let _toSend = body.map(el => { return { uuid: el.uuid } });
        if (_toSend.length)
            UNASSIGN_SUPPLIER(uuid, { supplierList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => RemoveSuppliers(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => RemoveSuppliers(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const AssignServiceType = (body, onResolve) => {
        let _toSend = body.map(el => { return { uuid: el.uuid } });
        if (_toSend.length)
            ASSIGN_SERVICETYPE(uuid, { serviceTypeList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => AssignServiceType(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => AssignServiceType(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const RemoveServiceType = (body, onResolve) => {
        let _toSend = body.map(el => { return { uuid: el.uuid } });
        if (_toSend.length)
            UNASSIGN_SERVICETYPE(uuid, { serviceTypeList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => RemoveServiceType(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => RemoveServiceType(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const AssignReimbursementType = (body, onResolve) => {
        let _toSend = body.map(el => { return { uuid: el.uuid } });
        if (_toSend.length)
            ASSIGN_REIMBURSEMENT_TYPE(uuid, { reimbursementTypeList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => AssignReimbursementType(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => AssignReimbursementType(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const RemoveReimbursementType = (body, onResolve) => {
        let _toSend = body.map(el => { return { uuid: el.uuid } });
        if (_toSend.length)
            UNASSIGN_REIMBURSEMENT_TYPE(uuid, { reimbursementTypeList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => RemoveReimbursementType(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => RemoveReimbursementType(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const AssignPaymentMethod = (body, onResolve) => {
        let _toSend = body.map(el => { return { uuid: el.uuid } });
        if (_toSend.length)
            ASSIGN_PAYMENT_METHOD(uuid, { paymentMethodRequestList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => AssignPaymentMethod(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => AssignPaymentMethod(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const RemovePaymentMethod = (body, onResolve) => {
        let _toSend = body.map(el => { return { uuid: el.uuid } });
        if (_toSend.length)
            UNASSIGN_PAYMENT_METHOD(uuid, { paymentMethodRequestList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => RemovePaymentMethod(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => RemovePaymentMethod(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const AssignLanguage = (body, onResolve) => {
        let _toSend = body.map(el => el.code);
        if (_toSend.length)
            ASSIGN_STUDY_LANGUAGE(uuid, { languageList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => AssignLanguage(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => AssignLanguage(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const RemoveLanguage = (body, onResolve) => {
        let _toSend = body.map(el => el.code);
        if (_toSend.length)
            UNASSIGN_STUDY_LANGUAGE(uuid, { languageList: _toSend }).then(() => {
                if (onResolve) {
                    onResolve();
                }
            }, error => HandleErrorCatching(error, () => RemoveLanguage(body, onResolve), auth)).catch(error => HandleErrorCatching(error, () => RemoveLanguage(body, onResolve), auth));
        if (!_toSend.length && onResolve)
            onResolve();
    }

    const FollowStudy = () => {
        if (!follow) {
            FOLLOW_STUDY(uuid).then(() => {
                loadDetail();
            }, error => HandleErrorCatching(error, FollowStudy, auth)).catch(error => HandleErrorCatching(error, FollowStudy, auth));
        } else {
            UNFOLLOW_STUDY(uuid).then(() => {
                loadDetail();
            }, error => HandleErrorCatching(error, FollowStudy, auth)).catch(error => HandleErrorCatching(error, FollowStudy, auth));
        }
    }

    const GetFiles = async () => {
        try {
            setLoadingFiles(true);
            const response = await GET_STUDY_DOCUMENTS(uuid);
            let files = {
                ALL: [],
                PATIENT: [],
                STUDY_COORDINATOR: [],
                empty: true
            };

            response.data.content.forEach(file => {
                if (file.documentList.length > 0) {
                    files[file.visibility] = files[file.visibility].concat(file.documentList);
                    files.empty = false;
                }
            });

            setFiles(files)
            setLoadingFiles(false);
        } catch (error) {
            HandleErrorCatching(error, GetFiles, auth);
            setLoadingFiles(false);
        }
    }


    function DownloadDocument(uuid) {
        DOWNLOAD_DOCUMENT('study', data.uuid, uuid).then((response) => {
            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.headers['content-disposition'].split("filename=")[1]); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        }, error => {
            HandleErrorCatching(error, () => DownloadDocument(uuid), auth);
        }).catch(error => HandleErrorCatching(error, () => DownloadDocument(uuid), auth));
    }

    function PreviewDocument(uuid) {
        DOWNLOAD_DOCUMENT('study', data.uuid, uuid).then((response) => {
            if (response) {
                const file = new Blob(
                    [response.data],
                    { type: response.data.type });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
        }, error => {
            HandleErrorCatching(error, () => PreviewDocument(uuid), auth);
        }).catch(error => HandleErrorCatching(error, () => PreviewDocument(uuid), auth));
    }

    function RemoveDocument(uuid) {
        DELETE_DOCUMENT('study', data.uuid, uuid).then((response) => {
            GetFiles();
        }, error => {
            HandleErrorCatching(error, () => PreviewDocument(uuid), auth);
        }).catch(error => HandleErrorCatching(error, () => PreviewDocument(uuid), auth));
    }

    function AddSchedulingGrid(data, onResolve) {
        if (!data.length) {
            onResolve();
        } else if (Array.isArray(data) && data.length > 0) {
            let _promises = [];

            data.forEach(sGrid => {
                let _body = {
                    owner: "STUDY",
                    ownerUuid: uuid,
                    ...sGrid
                }
                _promises.push(POST_SCHEDULING_GRID(_body));
            });

            Promise.allSettled(_promises).then(data => {
                onResolve();
                console.log(data);
            });
        }
    }

    function RemoveSchedulingGrid(data, onResolve) {
        if (!data.length) {
            onResolve();
        } else if (Array.isArray(data) && data.length > 0) {
            let _promises = [];

            data.forEach(sGrid => {
                _promises.push(DELETE_SCHEDULING_GRID(sGrid.uuid));
            });

            Promise.allSettled(_promises).then(data => {
                onResolve();
                console.log(data);
            });
        }
    }

    function EditSchedulingGrid(data, onResolve) {
        if (!data.length) {
            onResolve();
        } else if (Array.isArray(data) && data.length > 0) {
            let _promises = [];

            data.forEach(sGrid => {
                _promises.push(PUT_SCHEDULING_GRID(sGrid.uuid, sGrid));
            });

            Promise.allSettled(_promises).then(data => {
                onResolve();
                console.log(data);
            });
        }
    }

    useEffect(() => {
        if (uuid) {
            loadDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    return {
        data,
        follow,
        configuration,
        files,
        loading,
        loadingConfiguration,
        loadingFiles,
        Reload: loadDetail,
        Complete,
        Deactivate,
        EditConfiguration,
        GetConfiguration,
        AssignSuppliers,
        RemoveSuppliers,
        AssignServiceType,
        RemoveServiceType,
        AssignReimbursementType,
        RemoveReimbursementType,
        AssignPaymentMethod,
        RemovePaymentMethod,
        AssignLanguage,
        RemoveLanguage,
        Activate,
        FollowStudy,
        Edit,
        GetFiles,
        DownloadDocument,
        PreviewDocument,
        RemoveDocument,
        AddSchedulingGrid,
        RemoveSchedulingGrid,
        EditSchedulingGrid
    }
}