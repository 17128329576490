import React, { useRef } from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, IconButton, MenuItem, Tooltip } from '@material-ui/core';
import { ConfirmDialogButton, Fade, FormField } from 'src/components';
import ReactPlayer from 'react-player';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { animated, config, useSprings } from 'react-spring';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';

export const TutorialsConfiguration = ({ formik, languages }) => {
    const { t } = useTranslation();
    const scrollDestinationRef = useRef(null);
    const springs = useSprings(formik.values.TUTORIAL.length, formik.values.TUTORIAL.map(item => ({ from: { opacity: 0 }, to:{ opacity: 1 }, reverse: item.expanded === false,config: config.molasses })));

    function onAdd() {
        let _tutorials = [...formik.values.TUTORIAL];
        let _tutorial = {
            code: _tutorials.length + 1,
            target_user: ['PARAGON_STAFF', 'PARAGON_MANAGER', 'PATIENT', 'STUDY_COORDINATOR'],
            selectedLanguage: "en",
            expanded: true,
            en: {
                url: "",
                title: "",
                description: ""
            }
        };
        _tutorials.push(_tutorial);
        formik.setFieldValue("TUTORIAL", _tutorials);
        setTimeout(() => {
            scrollDestinationRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 200);
    }

    const onLanguageChange = (value, index) => {
        let _tutorials = [...formik.values.TUTORIAL];
        if (!_tutorials[index][value]) {
            _tutorials[index][value] = {
                url: "",
                title: "",
                description: ""
            }
        }
        formik.setFieldValue("TUTORIAL", _tutorials);
    };

    const handleOpen = (index) => {
        let _tutorials = [...formik.values.TUTORIAL];
        _tutorials[index].expanded = !_tutorials[index].expanded;
        formik.setFieldValue("TUTORIAL", _tutorials);
    }

    const handleRemove = (index, handleClose) => {
        let _tutorials = [...formik.values.TUTORIAL];
        _tutorials.splice(index, 1);
        formik.setFieldValue("TUTORIAL", _tutorials);
        handleClose();
    }

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']} style={{ paddingBottom: 0 }}>{t('studies:tutorialsConfiguration')}</div>
            <div className={creationStyle['container__SectionTitleSecondary']}>{t('studies:tutorialsConfigurationSecondaryTitle')}</div>
            <div className={creationStyle['container__SectionBody']} style={{ overflowY: 'auto', height: 'calc(100vh - 450px)', position: 'relative' }}>
                {
                    formik.values.TUTORIAL.length === 0 && <div className={creationStyle['container__SectionBodyEmpty']}>
                        <div className={creationStyle['container__SectionBodyEmptyIcon']}><OndemandVideoRoundedIcon /></div>
                        <div className={creationStyle['container__SectionBodyEmptyText']}>{t('studies:tutorial.notFound')}</div>
                    </div>
                }
                {
                    formik.values.TUTORIAL.map((el, index) => <div ref={index === formik.values.TUTORIAL.length - 1 ? scrollDestinationRef : null}  key={index} className={creationStyle['container__Accordion']}>
                        <div className={creationStyle['container__AccordionTitleContainer']}>
                            <div className={creationStyle['container__AccordionTitle']}>
                                <div className={creationStyle['container__AccordionTitleExpand']}>
                                    <Tooltip title={t('studies:tutorial.expand')}>
                                        <IconButton size="small" onClick={() => handleOpen(index)}>{el.expanded ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}</IconButton>
                                    </Tooltip>
                                </div>
                                <FormField style={{ padding: 10 }} InputProps={{ classes: { root: creationStyle['container__FieldWhite'] } }} fullWidth placeholder={t('studies:tutorial.title')} formik={formik} name={`TUTORIAL[${index}][${el.selectedLanguage}].title`} />
                                <div className={creationStyle['container__AccordionTitleExpand']}>
                                    <Tooltip title={t('studies:tutorial.remove.dialogTitle')}>
                                        <ConfirmDialogButton size="small" iconButton maxWidth="sm" onConfirm={(handleClose) => handleRemove(index, handleClose)} text={t('studies:tutorial.remove.dialogText')} title={t('studies:tutorial.remove.dialogTitle')}><DeleteRoundedIcon /></ConfirmDialogButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        {
                            el.expanded && <animated.div style={springs[index]} className={creationStyle['container__AccordionBodyContainer']}>
                                <div className={creationStyle['container__AccordionBodyRow']}>
                                    <FormField onChangeFn={(value) => onLanguageChange(value, index)} style={{ padding: 10 }} InputProps={{ classes: { root: creationStyle['container__FieldWhite'] } }} select fullWidth placeholder={t('studies:tutorial.language')} formik={formik} name={`TUTORIAL[${index}].selectedLanguage`}>
                                        {languages?.response?.data.map((language, index) => <MenuItem key={index} value={language.code}>{`${language.code.toUpperCase()} - ${language.name}`}</MenuItem>)}
                                    </FormField>
                                    <FormField style={{ padding: 10 }} InputProps={{ classes: { root: creationStyle['container__FieldWhite'] } }} SelectProps={{ multiple: true, renderValue: el => el.map(value => value.replace('PARAGON_STAFF', 'Paragon Staff').replace('STUDY_COORDINATOR', 'Study Coordinator').replace('PATIENT', 'Patient').replace('PARAGON_MANAGER', 'Paragon Manager')).join(', ') }} select fullWidth placeholder={t('studies:tutorial.target')} formik={formik} name={`TUTORIAL[${index}].target_user`}>
                                        <MenuItem value={"PARAGON_STAFF"}>
                                            <Checkbox color="primary" value="" onChange={() => { }} checked={el.target_user.includes('PARAGON_STAFF')} />
                                            Paragon Staff
                                        </MenuItem>
                                        <MenuItem value={"PARAGON_MANAGER"}>
                                            <Checkbox color="primary" value="" onChange={() => { }} checked={el.target_user.includes('PARAGON_MANAGER')} />
                                            Paragon Manager
                                        </MenuItem>
                                        <MenuItem value={"STUDY_COORDINATOR"}>
                                            <Checkbox color="primary" value="" onChange={() => { }} checked={el.target_user.includes('STUDY_COORDINATOR')} />
                                            Study Coordinator
                                        </MenuItem>
                                        <MenuItem value={"PATIENT"}>
                                            <Checkbox color="primary" value="" onChange={() => { }} checked={el.target_user.includes('PATIENT')} />
                                            Patient
                                        </MenuItem>
                                    </FormField>
                                </div>
                                <div className={creationStyle['container__AccordionBodyRow']}>
                                    <FormField style={{ padding: 10 }} maxRows={4} multiline InputProps={{ classes: { root: creationStyle['container__FieldWhite'] } }} fullWidth placeholder={t('studies:tutorial.description')} formik={formik} name={`TUTORIAL[${index}][${el.selectedLanguage}].description`} />
                                </div>
                                <div className={creationStyle['container__AccordionBodyRow']}>
                                    <FormField style={{ padding: 10 }} InputProps={{ classes: { root: creationStyle['container__FieldWhite'] } }} fullWidth placeholder={t('studies:tutorial.url')} formik={formik} name={`TUTORIAL[${index}][${el.selectedLanguage}].url`} />
                                </div>
                                <div className={creationStyle['container__AccordionBodyRow']}>
                                    {el[el.selectedLanguage].url && <ReactPlayer
                                        style={{ padding: 10 }}
                                        controls
                                        width={'100%'}
                                        url={el[el.selectedLanguage].url}
                                    />}
                                </div>
                                <div className={creationStyle['container__AccordionBodyRow']}>
                                </div>
                            </animated.div>
                        }
                    </div>)
                }

            </div>
            <div className={creationStyle['container__SectionBody']}>
                <Button style={{ marginTop: 10 }} onClick={onAdd} color="primary">
                    {t('studies:tutorial.add')}
                </Button>
            </div>
        </Fade>
    )
}