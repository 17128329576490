import React from 'react';
import { NotAuthorizedPage } from "./NotAuthorizedPage/NotAuthorizedPage"
import { NotFoundPage } from "./NotFoundPage/NotFoundPage"


export const DisplayError = ({error}) => {
    return (
        error === 403 ? <NotAuthorizedPage /> :
        error === 404 ? <NotFoundPage /> : null
    )
}