import HttpRequestsHandler from "src/services/http-requests-handler";

const path = {
    GET_NOTIFICATIONS: '/api/notification/all',
    READ_ALL_NOTIFICATION: '/api/notification/update',
    GET_VISITS: '/api/visit/all'
}


export const GET_NOTIFICATIONS = (params) => {
    return HttpRequestsHandler("GET", path.GET_NOTIFICATIONS, params);
}

export const GET_UPCOMING_VISITS = (params) => {
    return HttpRequestsHandler("GET", path.GET_VISITS, params);
}

export const READ_ALL_NOTIFICATION = (params) => {
    return HttpRequestsHandler("PUT", path.READ_ALL_NOTIFICATION, params);
}