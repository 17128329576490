import React, { useEffect, useState } from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsHeader, DetailLoader, Fade, Stepper } from 'src/components';
import { StudyDetailsForm } from './components/StudyDetailsForm';
import { Button, Grid, IconButton } from '@material-ui/core';
import { useFormik } from 'formik';
import { StudyConfigurationForm } from './components/ConfigurationForm';
import moment from 'moment';
import { GET_ALL_STUDY_LANGUAGES, POST_STUDY } from '../services';
import { HandleErrorCatching } from 'src/roots/AuthenticatedApp/components/utils/utils';
import { useAuth } from 'src/context/auth-context';
import { animated, config, Transition } from 'react-spring';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useStudy } from 'src/hooks/Study/useStudy';
import { useAsync } from 'src/hooks/useAsync';
import { preventClose } from 'src/components/utils';
import { usePaginatedRequests } from 'src/hooks/usePaginatedRequests';
import { GET_SCHEDULING_VISITS } from 'src/pages/Visits/services';
import { useDebitCards } from 'src/hooks/DebitCards/useDebitCards';


export const CreateStudyContainer = () => {
    const { studyUuid, editUuid } = useParams();
    const query = new URLSearchParams(useLocation().search);
    const study = useStudy(studyUuid || editUuid);
    const auth = useAuth();
    const { t, i18n } = useTranslation();
    const [activeStep, setActiveStep] = useState(studyUuid ? 1 : 0);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const languages = useAsync(GET_ALL_STUDY_LANGUAGES, true);
    const schedulingGrid = usePaginatedRequests(GET_SCHEDULING_VISITS, {}, false);
    const debitCards = useDebitCards(studyUuid || editUuid, 'STUDY');

    const [firstLoading, setFirstLoading] = useState(false);
    const [displayWarning, setDisplayWarning] = useState(study?.data?.status === 'DRAFT');

    const [isSaving, setIsSaving] = useState(false);
    const [savingStatus, setSavingStatus] = useState({
        configuration: "PENDING",
        supplierList: "PENDING",
        serviceTypeList: "PENDING",
        reimbursementTypeList: "PENDING",
        paymentMethodRequestList: "PENDING",
        languageList: "PENDING",
        debitCards: "PENDING",
        tutorials: 'PENDING'
    });

    const steps = [
        { label: t('studies:studyDetails'), icon: <InfoRoundedIcon /> },
        { label: t('studies:configuration'), icon: <SettingsIcon /> },
    ];

    const formik = useFormik({
        initialValues: {
            name: '',
            code: '',
            schedulingGrid: [],
            startDate: null,
            endDate: null,
            protocolName: '',
            PATIENT_ID_DIGITS: '',
            description: '',
            HAS_DEBIT_CARD: false,
            HAS_SCHEDULING_GRID: false,
            HAS_B4B_INTEGRATION: false,
            PATIENT_CODE_AUTO_GENERATED: false,
            VISIT_NUMBER_AUTO_GENERATED: false,
            PATIENT_CONSENT_FORM: false,
            PATIENT_CONFIGURATION: {
                initials: {
                    label: 'patients:initials',
                    hidden: false,
                    mandatory: true
                },
                originalName: {
                    label: 'patients:originalName',
                    hidden: false,
                    mandatory: false
                },
                firstName: {
                    label: 'patients:firstName',
                    hidden: false,
                    mandatory: true
                },
                middleName: {
                    label: 'patients:middleName',
                    hidden: false,
                    mandatory: false
                },
                lastName: {
                    label: 'patients:lastName',
                    hidden: false,
                    mandatory: true
                },
                languages: {
                    label: 'patients:languages',
                    hidden: false,
                    mandatory: true
                },
                gender: {
                    label: 'patients:gender',
                    hidden: false,
                    mandatory: true
                },
                birthDate: {
                    label: 'patients:birthdate',
                    hidden: false,
                    mandatory: true
                },
                fax: {
                    label: 'patients:fax',
                    hidden: false,
                    mandatory: false
                },
                workPhone: {
                    label: 'patients:workphone',
                    hidden: false,
                    mandatory: false
                },
                workExtensionPhone: {
                    label: 'patients:workExtension',
                    hidden: false,
                    mandatory: false
                },
                workCountryCode: {
                    label: 'patients:workCountryCode',
                    hidden: false,
                    mandatory: false
                },
                primaryPhone: {
                    label: 'patients:cellphone',
                    hidden: false,
                    mandatory: true
                },
                primaryCountryCode: {
                    label: 'patients:cellphoneCountryCode',
                    hidden: false,
                    mandatory: false
                },
                address: {
                    label: 'patients:address',
                    hidden: false,
                    mandatory: true
                },
                caregiverList: {
                    label: 'patients:caregivers',
                    hidden: false,
                    mandatory: false
                },
                emergencyContactList: {
                    label: 'patients:emergencyContacts',
                    hidden: false,
                    mandatory: false
                }
            },
            SUPPLIER_LIST: [],
            SERVICE_TYPE_LIST: [],
            REIMBURSEMENT_TYPE_LIST: [],
            PAYMENT_METHOD_LIST: [],
            LANGUAGE_LIST: [],
            cardList: [],
            TUTORIAL: [],
            ...study.configuration,
            ...study.data
        },
        validate: values => {
            let errors = {};

            if (activeStep === 0) {
                if (!values['name']) {
                    errors['name'] = t('generic:valueRequired');
                }
                if (!values['code']) {
                    errors['code'] = t('generic:valueRequired');
                }
                if (!values['startDate']) {
                    errors['startDate'] = t('generic:valueRequired');
                }
                if (!values['endDate']) {
                    errors['endDate'] = t('generic:valueRequired');
                }

            } else if (activeStep === 1) {
                if (!values['PATIENT_ID_DIGITS'] && values.PATIENT_CODE_AUTO_GENERATED) {
                    errors['PATIENT_ID_DIGITS'] = t('generic:valueRequired');
                }

                if (values.HAS_SCHEDULING_GRID && values.schedulingGrid.length > 0) {
                    values.schedulingGrid.forEach((sGrid, index) => {
                        if (!sGrid.name) {
                            errors[`schedulingGrid[${index}].name`] = t('generic:valueRequired');
                        }
                        if (sGrid.amount < 0) {
                            errors[`schedulingGrid[${index}].amount`] = t('generic:valueRequired');
                        }
                        if (isNaN(sGrid.amount) || sGrid.amount === '') {
                            errors[`schedulingGrid[${index}].amount`] = t('generic:valueRequired');
                        }
                    });
                }

                // if (values.HAS_DEBIT_CARD && values.cardList.length > 0) {
                //     values.cardList.forEach((debitCard, index) => {
                //         if (debitCard?.code?.length < 10) {
                //             errors[`cardList[${index}].code`] = t('generic:errors.valueLength').replace('{length}', 10);
                //         }
                //     });
                // }
            }

            return errors;
        },
        onSubmit: values => {
            if (activeStep === 0) {
                let _values = {
                    name: values.name,
                    code: values.code,
                    startDate: moment(values.startDate).format('YYYY-MM-DD'),
                    endDate: moment(values.endDate).format('YYYY-MM-DD'),
                    protocolName: values.protocolName,
                    description: values.description,
                    patientIdDigits: editUuid ? study.data.patientIdDigits : '',
                    hasDebitCard: editUuid ? study.data.hasDebitCard : false,
                    caregiversAllowed: editUuid ? study.data.caregiversAllowed : values.PATIENT_CONFIGURATION.caregiverList.hidden ? false : true,
                    hasDateOfBirth: editUuid ? study.data.hasDateOfBirth : false
                };

                if (editUuid) {
                    _values['uuid'] = study.data.uuid;

                    study.Edit(_values, () => {
                        history.push(`/studies/detail/${study.data.uuid}`);
                    });
                } else {
                    POST_STUDY(_values).then((data) => {
                        history.push(`/studies/configuration/${data.data.data}`);
                        setActiveStep(1);
                    }, error => {
                        HandleErrorCatching(error, formik.handleSubmit, auth);
                    }).catch(error => HandleErrorCatching(error, formik.handleSubmit, auth));
                }
            } else {
                setIsSaving(true);
                SaveConfiguration(values);
            }
        },
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
    });


    function SaveConfiguration(values) {

        if(values.TUTORIAL){
            values.TUTORIAL.forEach(el => el.expanded = false);
        }

        let body = {
            elementList: [
                {
                    "code": "HAS_DEBIT_CARD",
                    "value": values.HAS_DEBIT_CARD,
                    "type": "BOOLEAN",
                    "label": "study_configuration.visit.has_debit_card"
                },
                {
                    "code": "HAS_B4B_INTEGRATION",
                    "value": values.HAS_DEBIT_CARD && values.HAS_B4B_INTEGRATION,
                    "type": "BOOLEAN",
                    "label": "study_configuration.visit.has_b4b_integration"
                },
                {
                    "code": "HAS_SCHEDULING_GRID",
                    "value": values.HAS_SCHEDULING_GRID,
                    "type": "BOOLEAN",
                    "label": "study_configuration.visit.has_scheduling_grid"
                },
                {
                    "code": "VISIT_NUMBER_AUTO_GENERATED",
                    "value": values.VISIT_NUMBER_AUTO_GENERATED,
                    "type": "BOOLEAN",
                    "label": "study_configuration.visit.number_auto_generated"
                },
                {
                    "code": "PATIENT_CONSENT_FORM",
                    "value": values.PATIENT_CONSENT_FORM,
                    "type": "BOOLEAN",
                    "label": "study_configuration.patient.consent_form"
                },
                {
                    "code": "HAS_DATE_OF_BIRTH",
                    "value": !values.PATIENT_CONFIGURATION.birthDate.hidden,
                    "type": "BOOLEAN",
                    "label": "study_configuration.patient.date_of_birth"
                },
                {
                    "code": "CAREGIVERS_ALLOWED",
                    "value": !values.PATIENT_CONFIGURATION.caregiverList.hidden,
                    "type": "BOOLEAN",
                    "label": "study_configuration.patient.caregivers_allowed"
                },
                {
                    "code": "PATIENT_ID_DIGITS",
                    "value": values.PATIENT_ID_DIGITS ? values.PATIENT_ID_DIGITS : null,
                    "type": "NUMERIC",
                    "label": "study_configuration.patient.id_digits"
                },
                {
                    "code": "PATIENT_CONFIGURATION",
                    "value": JSON.stringify(values.PATIENT_CONFIGURATION),
                    "type": "JSON",
                    "label": "study_configuration.patient.json_configuration"
                },
                {
                    "code": "SUPPLIER_LIST",
                    "value": JSON.stringify(values.SUPPLIER_LIST.map(el => { return { uuid: el.uuid } })),
                    "type": "JSON",
                    "label": "study_configuration.supplierList"
                },
                {
                    "code": "SERVICE_TYPE_LIST",
                    "value": JSON.stringify(values.SERVICE_TYPE_LIST.map(el => { return { uuid: el.uuid } })),
                    "type": "JSON",
                    "label": "study_configuration.serviceTypeList"
                },
                {
                    "code": "REIMBURSEMENT_TYPE_LIST",
                    "value": JSON.stringify(values.REIMBURSEMENT_TYPE_LIST.map(el => { return { uuid: el.uuid } })),
                    "type": "JSON",
                    "label": "study_configuration.reimbursementTypeList"
                },
                {
                    "code": "PAYMENT_METHOD_LIST",
                    "value": JSON.stringify(values.PAYMENT_METHOD_LIST.map(el => { return { uuid: el.uuid } })),
                    "type": "JSON",
                    "label": "study_configuration.paymentMethodRequestList"
                },
                {
                    "code": "LANGUAGE_LIST",
                    "value": JSON.stringify(values.LANGUAGE_LIST.map(el => { return { code: el.code } })),
                    "type": "JSON",
                    "label": "study_configuration.languageList"
                },
                {
                    "code": "PATIENT_CODE_AUTO_GENERATED",
                    "value": values.PATIENT_CODE_AUTO_GENERATED,
                    "type": "BOOLEAN",
                    "label": "study_configuration.patient.code_auto_generated"
                },
                {
                    "code": "TUTORIAL",
                    "value": JSON.stringify(values.TUTORIAL),
                    "type": "JSON",
                    "label": "study_configuration.tutorials"
                }
            ]
        };

        study.EditConfiguration(body, () => {
            setSavingStatus(oldState => { return { ...oldState, configuration: "DONE", tutorials: 'DONE' } });
            setTimeout(() => SaveSuppliers(values), 500);
        });

    }

    function SaveSuppliers(values) {
        let toSave = [];
        let toRemove = [];

        if (study.configuration['SUPPLIER_LIST']) {
            toSave = values.SUPPLIER_LIST.filter(curr => !study.configuration['SUPPLIER_LIST'].map(prev => prev.uuid).includes(curr.uuid));
            toRemove = study.configuration['SUPPLIER_LIST'].filter(prev => !values.SUPPLIER_LIST.map(curr => curr.uuid).includes(prev.uuid));
        } else {
            toSave = [...values.SUPPLIER_LIST];
        }

        study.AssignSuppliers(toSave, () => {
            study.RemoveSuppliers(toRemove, () => SaveServiceType(values));
            setSavingStatus(oldState => { return { ...oldState, supplierList: "DONE" } });
        });
    }

    function SaveServiceType(values) {
        let toSave = [];
        let toRemove = [];

        if (study.configuration['SERVICE_TYPE_LIST']) {
            toSave = values.SERVICE_TYPE_LIST.filter(curr => !study.configuration['SERVICE_TYPE_LIST'].map(prev => prev.uuid).includes(curr.uuid));
            toRemove = study.configuration['SERVICE_TYPE_LIST'].filter(prev => !values.SERVICE_TYPE_LIST.map(curr => curr.uuid).includes(prev.uuid));
        } else {
            toSave = [...values.SERVICE_TYPE_LIST];
        }
        study.AssignServiceType(toSave, () => {
            study.RemoveServiceType(toRemove, () => SaveReimbursementType(values));
            setSavingStatus(oldState => { return { ...oldState, serviceTypeList: "DONE" } });
        });
    }

    function SaveReimbursementType(values) {
        let toSave = [];
        let toRemove = [];
        if (study.configuration['REIMBURSEMENT_TYPE_LIST']) {
            toSave = values.REIMBURSEMENT_TYPE_LIST.filter(curr => !study.configuration['REIMBURSEMENT_TYPE_LIST'].map(prev => prev.uuid).includes(curr.uuid));
            toRemove = study.configuration['REIMBURSEMENT_TYPE_LIST'].filter(prev => !values.REIMBURSEMENT_TYPE_LIST.map(curr => curr.uuid).includes(prev.uuid));
        } else {
            toSave = [...values.REIMBURSEMENT_TYPE_LIST];
        }
        study.AssignReimbursementType(toSave, () => {
            study.RemoveReimbursementType(toRemove, () => SavePaymentMethod(values));
            setSavingStatus(oldState => { return { ...oldState, reimbursementTypeList: "DONE" } })
        });
    }

    function SavePaymentMethod(values) {
        let toSave = [];
        let toRemove = [];

        if (study.configuration['PAYMENT_METHOD_LIST']) {
            toSave = values?.PAYMENT_METHOD_LIST.filter(curr => !study.configuration['PAYMENT_METHOD_LIST'].map(prev => prev.uuid).includes(curr.uuid));
            toRemove = study?.configuration['PAYMENT_METHOD_LIST'].filter(prev => !values.PAYMENT_METHOD_LIST.map(curr => curr.uuid).includes(prev.uuid));
        } else {
            toSave = [...values.PAYMENT_METHOD_LIST];
        }
        study.AssignPaymentMethod(toSave, () => {
            study.RemovePaymentMethod(toRemove, () => SaveLanguages(values));
            setSavingStatus(oldState => { return { ...oldState, paymentMethodRequestList: "DONE" } })
        });
    }

    function SaveLanguages(values) {
        let toSave = [];
        let toRemove = [];

        if (study.configuration['LANGUAGE_LIST']) {
            toSave = values.LANGUAGE_LIST.filter(curr => !study.configuration['LANGUAGE_LIST'].map(prev => prev.code).includes(curr.code));
            toRemove = study.configuration['LANGUAGE_LIST'].filter(prev => !values.LANGUAGE_LIST.map(curr => curr.code).includes(prev.code));
        } else {
            toSave = [...values.LANGUAGE_LIST];
        }
        study.AssignLanguage(toSave, () => study.RemoveLanguage(toRemove, () => {
            if (!isNaN(values.PATIENT_ID_DIGITS) && study.data.status === 'DRAFT') {
                study.Activate(setDisplayWarning(false));
            }
            SaveSchedulingGrid(values);
            setSavingStatus(oldState => { return { ...oldState, languageList: "DONE" } })
        }));
    }

    function SaveSchedulingGrid(values) {
        let toSave = [];
        let toEdit = [];
        let toRemove = [];

        if (values.HAS_SCHEDULING_GRID) {
            toSave = values.schedulingGrid.filter(sGrid => !sGrid.uuid);
            toRemove = schedulingGrid.response.content.filter(prevGrid => !values.schedulingGrid.map(sGrid => sGrid.uuid).includes(prevGrid.uuid));

            schedulingGrid.response.content.forEach((prevGrid) => {
                values.schedulingGrid.forEach(currSGrid => {
                    if (prevGrid.uuid === currSGrid.uuid && (currSGrid.amount !== prevGrid.amount || currSGrid.sort !== prevGrid.sort || currSGrid.name !== prevGrid.name)) {
                        toEdit.push(currSGrid);
                    }
                });
            });

            study.AddSchedulingGrid(toSave, () => {
                study.RemoveSchedulingGrid(toRemove, () => {
                    study.EditSchedulingGrid(toEdit, () => {
                        if (values.cardList.length > 0 && values.HAS_B4B_INTEGRATION === true){
                            setSavingStatus(oldState => { return { ...oldState, schedulingGrid: "DONE" } })
                            SaveDebitCards(values);
                        }else{
                            setSavingStatus(oldState => { return { ...oldState, schedulingGrid: "DONE", debitCards: "DONE" } });
                        }
                    });
                });
            });
        }else{
            console.log("VALUES", values)
            if (values.cardList.length > 0 && values.HAS_B4B_INTEGRATION === true)
                SaveDebitCards(values);
            else
            setSavingStatus(oldState => { return { ...oldState, debitCards: "DONE" } });

        }
    }

    function SaveDebitCards(values) {
        let _toSave = values.cardList.filter(card => !card.uuid).map(card => card.code);

        debitCards.Create({
            ownerType: "STUDY",
            ownerUuid: editUuid || studyUuid,
            cards: _toSave
        }, () => {
            setSavingStatus(oldState => { return { ...oldState, debitCards: "DONE" } });
        })
    }

    useEffect(() => {
        if (study?.data?.status && !firstLoading) {
            setFirstLoading(true);
            setDisplayWarning(study.data.status === 'DRAFT');
        }
    }, [study, firstLoading])

    useEffect(() => {
        i18n.loadNamespaces(['studies', 'patients', 'services', 'payment_method', 'reimbursements', 'reimbursement_type', 'suppliers', 'generic', 'service_group', 'service_type']).then(() => {
            setLoading(false);
        });

        window.addEventListener("beforeunload", preventClose);

        return () => {
            window.removeEventListener("beforeunload", preventClose);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (studyUuid && !study.configuration && !study.loadingConfiguration && !firstLoading) {
            study.GetConfiguration(null, config => {
                if (config.HAS_SCHEDULING_GRID)
                    schedulingGrid.loadData({ studyUuid: studyUuid, size: 1000, sort: 'sort_asc' }, response => {
                        formik.setFieldValue('schedulingGrid', JSON.parse(JSON.stringify(response.content)));
                    });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [study])

    useEffect(() => {
        if (formik.values.HAS_SCHEDULING_GRID) {
            schedulingGrid.loadData({ studyUuid: studyUuid, size: 1000, sort: 'sort_asc' }, response => {
                formik.setFieldValue('schedulingGrid', JSON.parse(JSON.stringify(response.content)));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.HAS_SCHEDULING_GRID])

    useEffect(() => {
        if (study?.data?.status === 'DRAFT' && formik?.values?.LANGUAGE_LIST?.length === 0 && !languages.loading && languages.response) {
            formik.setFieldValue('LANGUAGE_LIST', languages.response.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [study, languages]);

    useEffect(() => {
        if (studyUuid || editUuid) {
            debitCards.Load({ ownerType: 'STUDY', ownerUuid: studyUuid || editUuid }).then(response => formik.setFieldValue("cardList", response));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studyUuid, editUuid]);

    if (loading || (studyUuid && study.loading))
        return <DetailLoader />

    return (
        <Fade direction="column">
            <Grid container>
                <Grid item xs={12} style={{ overflow: 'hidden' }}>
                    <BreadcrumbsHeader enableClose={query.get("fromDetail")} detailTitle={study?.data?.name} />
                    <Transition
                        items={displayWarning}
                        from={{ opacity: 0, position: 'relative', y: 100, height: '0px' }}
                        enter={{ opacity: 1, position: 'relative', y: 0, height: '80px' }}
                        leave={{ opacity: 0, position: 'relative', height: '0px', y: 100 }}
                        delay={200}
                        config={config.default}>
                        {(styles, item) =>
                            item && <animated.div style={styles} className={creationStyle['container__Message']}>
                                <Grid item xs={2}></Grid>
                                <Grid container justify="center" item xs={1}><WarningRoundedIcon htmlColor={"#F6BC4C"} /></Grid>
                                <Grid container justify="center" item xs={6}>{t('studies:draftWarning')}</Grid>
                                <Grid container justify="center" item xs={1}><WarningRoundedIcon htmlColor={"#F6BC4C"} /></Grid>
                                <Grid container justify="flex-end" item xs={2}><IconButton size="small" onClick={() => setDisplayWarning(false)}><CancelRoundedIcon style={{ fontSize: '2rem' }} /></IconButton></Grid>
                            </animated.div>
                        }
                    </Transition>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="center">
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <Stepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
                </Grid>
                <Grid container item xs={2} direction="row" justify="flex-end" alignItems="center">
                    {!isSaving && <Button className={creationStyle['container__Button']} variant="contained" color="primary" disabled={!formik.isValid} onClick={formik.handleSubmit}>
                        {
                            activeStep === 0 && !editUuid ? t('studies:create') : study?.data?.status === 'DRAFT' ? t('studies:activateStudy') : t('generic:applyChanges')
                        }
                    </Button>}
                </Grid>
            </Grid>
            <Grid container item xs justify="center">
                {activeStep === 0 && <StudyDetailsForm formik={formik} />}
                {activeStep === 1 && <StudyConfigurationForm debitCards={debitCards} schedulingGrid={schedulingGrid} languages={languages} isSaving={isSaving} savingStatus={savingStatus} formik={formik} study={study} uuid={study?.data?.uuid} />}
            </Grid>
        </Fade>
    )
}