import { useCallback, useEffect, useState } from "react"
import XLSX from 'xlsx';

export const useExcelReader = (file = null) => {
    const [data, setData] = useState(null);

    const make_cols = refstr => {
        let o = [],
            C = XLSX.utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
        return o;
    };

    const readFromExcel = useCallback(
        () => {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                /* Update state */
                setData({ data: data, cols: make_cols(ws["!ref"]) });
            };
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        },
        [file],
    )

    const readExcel = useCallback(
        (file) => {
            readFromExcel(file);
        },
        [readFromExcel],
    )
    useEffect(() => {
        if (file) {
            readExcel(file);
        }
    }, [file, readExcel])

    return {
        data
    }
}