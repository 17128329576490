import React, { useState } from 'react';
import styles from '../Dashboard.module.scss';
import { IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { UpcomingVisits } from './UpcomingVisits';
import { Notifications } from './Notification';
import { useTranslation } from 'react-i18next';

export const MobileContainer = ({ setFullHeight, fullHeight, ...rest }) => {
    const [index, setIndex] = useState(0);
    const { t } = useTranslation();

    const onScrollFn = (e) => {
        if (e.target.scrollTop > 0) {
            setFullHeight(true);
        } else if (e.target.scrollTop < 0) {
            setFullHeight(false);
        }
    }

    return (
        <div className={styles['mobileCarouselContainer']}>
            <div className={styles['mobileCarouselContainer__header']}>
                {fullHeight ? <IconButton color="secondary" size="small" onClick={() => setFullHeight(false)}><KeyboardArrowDownIcon /></IconButton>
                    : <>
                        <IconButton color="secondary" size="small" onClick={() => setIndex(index <= 0 ? 1 : 0)}><ChevronLeftIcon /></IconButton>
                        {[...Array(2).keys()].map(value =>
                            <span className={value === index ? styles['mobileCarouselContainer__header-dotSelected'] : styles['mobileCarouselContainer__header-dot']} />
                        )}
                        <IconButton color="secondary" size="small" onClick={() => setIndex(index <= 0 ? 1 : 0)}><ChevronRightIcon /></IconButton>
                    </>}
            </div>
            {index === 0 && <div className={styles['mobileCarouselContainer__list']}>
                <div className={styles['mobileCarouselContainer__list-title']}>{t('dashboard:upcomingVisits')}</div>
                <UpcomingVisits isMobile={true} onScrollFn={onScrollFn}/>
            </div>}
            {index === 1 && <div className={styles['mobileCarouselContainer__list']}>
                <div className={styles['mobileCarouselContainer__list-title']}>{t('dashboard:notification')}</div>
                <Notifications isMobile={true} onScrollFn={onScrollFn}/>
            </div>}
        </div>
    )
}