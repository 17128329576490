import React from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetailLoader, Fade } from 'src/components';
import { useAsync } from 'src/hooks/useAsync';
import { GET_PAYMENT_METHODS } from 'src/pages/Studies/services';


export const PaymentMethodsConfiguration = ({ formik }) => {
    const { t } = useTranslation();
    const reimbursements = useAsync(GET_PAYMENT_METHODS, true);

    function handleChange(event, paymentMethod) {
        let _paymentMethodRequestList = [...formik.values.PAYMENT_METHOD_LIST];

        if (event.target.checked) {
            if (!_paymentMethodRequestList.map(el => el.uuid).indexOf(paymentMethod.uuid) > -1) {
                _paymentMethodRequestList.push({ ...paymentMethod });
            }
        } else {
            if (_paymentMethodRequestList.map(el => el.uuid).indexOf(paymentMethod.uuid) > -1) {
                _paymentMethodRequestList.splice(_paymentMethodRequestList.map(el => el.uuid).indexOf(paymentMethod.uuid), 1);
            }
        }

        formik.setFieldValue('PAYMENT_METHOD_LIST', _paymentMethodRequestList);
    }

    if(reimbursements.loading)
    return <DetailLoader />;

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']} style={{ paddingBottom: 0 }}>{t('studies:paymentMethodConfiguration')}</div>
            <div className={creationStyle['container__SectionTitleSecondary']}>{t('studies:paymentMethodSecondaryTitle')}</div>
            <div className={creationStyle['container__SectionBody']} style={{ maxHeight: 'calc(100vh - 400px)' }}>
                <div className={creationStyle['container__SectionBodyTable']}>
                    <Grid container>
                        {
                            reimbursements?.response?.data.map((paymentMethod, index) => <Grid key={index} item xs={12}>
                                <FormControlLabel key={index} className={creationStyle['container__SectionBodyField']}
                                    control={<Checkbox color="primary" checked={formik.values.PAYMENT_METHOD_LIST.map(el => el.uuid).indexOf(paymentMethod.uuid) > -1} onChange={event => handleChange(event, paymentMethod)} />}
                                    label={t(paymentMethod.label.replace('paragon.payment_method.', 'payment_method:'))}
                                />
                            </Grid>
                            )
                        }
                    </Grid>
                </div>
            </div>
        </Fade>
    )
}