import HttpRequestsHandler from "src/services/http-requests-handler";


const path =  {
    GET_SIDEBAR_MENU:'/api/user/menu/roles',
    GET_LABELS:'/api/label',
    DOWNLOAD_DOCUMENT:'/api/:section/:sectionUuid/document/:uuid/download',
    DELETE_DOCUMENT:'/api/:section/:sectionUuid/document/:uuid',
    GET_ROLES: '/api/user/roles',
    GET_PROFILE_STUDYCOORDINATOR: '/api/studycoordinator/myprofile',
    GET_PROFILE_PATIENT: '/api/patient/myprofile',
    GET_COUNT_NOTIFY:'/api/notification/count',
    CHANGE_USER_PASSWORD: '/api/user/password/change',
    GET_USER_AVATAR: '/api/user/:uuid/avatar',
    GET_TUTORIAL: '/api/tutorial',
    GET_LANGUAGES: '/api/language/all'
}


export const GET_SIDEBAR_MENU = () => {
    return HttpRequestsHandler("GET", path.GET_SIDEBAR_MENU, null);
}

export const GET_USER_AVATAR = (uuid, params) => {
    return HttpRequestsHandler("GET", path.GET_USER_AVATAR.replace(/:uuid/gi, uuid), params);
}

export const GET_ROLES = () => {
    return HttpRequestsHandler("GET", path.GET_ROLES, null);
}

export const GET_COUNT_NOTIFY = () => {
    return HttpRequestsHandler("GET", path.GET_COUNT_NOTIFY, null);
}


export const DOWNLOAD_DOCUMENT = (section, sectionUuid, uuid) => {
        return HttpRequestsHandler("DOWNLOAD", path.DOWNLOAD_DOCUMENT.replace(/:sectionUuid/gi, sectionUuid).replace(/:section/gi, section).replace(/:uuid/gi, uuid), null);
}

export const DELETE_DOCUMENT = (section, sectionUuid, uuid, params) => {
        return HttpRequestsHandler("DELETE", path.DELETE_DOCUMENT.replace(/:sectionUuid/gi, sectionUuid).replace(/:section/gi, section).replace(/:uuid/gi, uuid), params);
}

export const GET_PROFILE_STUDYCOORDINATOR = () => {
    return HttpRequestsHandler("GET", path.GET_PROFILE_STUDYCOORDINATOR, null);
}

export const CHANGE_USER_PASSWORD = (body) => {
    return HttpRequestsHandler("PUT", path.CHANGE_USER_PASSWORD, null, body);
}

export const GET_PROFILE_PATIENT= () => {
    return HttpRequestsHandler("GET", path.GET_PROFILE_PATIENT, null);
}

export const GET_TUTORIAL = (params) => {
    return HttpRequestsHandler("GET", path.GET_TUTORIAL, params);
}

export const POST_TUTORIAL = ({ code, messageList }) => {
    let _toSend = {
        "code": code,
        "messageList": messageList
      }

    return HttpRequestsHandler("POST", path.GET_TUTORIAL, null, _toSend);
}

export const GET_LANGUAGES = (params) => {
    return HttpRequestsHandler("GET", path.GET_LANGUAGES, params);
}
