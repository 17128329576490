import React from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Fade, FormField } from 'src/components';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';

export const PatientsConfiguration = ({ formik }) => {
    const { t } = useTranslation();

    const fields = {
        originalName: 'patients:originalName',
        firstName: 'patients:firstName',
        middleName: 'patients:middleName',
        lastName: 'patients:lastName',
        languages: 'patients:languages',
        gender: 'patients:gender',
        birthDate: 'patients:birthdate',
        fax: 'patients:fax',
        workPhone: 'patients:workphone',
        workExtensionPhone: 'patients:workExtension',
        workCountryCode: 'patients:workCountryCode',
        primaryPhone: 'patients:cellphone',
        primaryCountryCode: 'patients:cellphoneCountryCode',
        address: 'patients:address',
        caregiverList: 'patients:caregivers',
        emergencyContactList: 'patients:emergencyContacts'
    };

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']}>{t('studies:patientsConfiguration')}</div>
            <div className={creationStyle['container__SectionBody']}>
                <div className={creationStyle['container__SectionBodyField']}>
                    <FormField onlyNumbers InputProps={{ classes: { root: creationStyle['container__FieldWhite'] } }} required={formik.values.PATIENT_CODE_AUTO_GENERATED} helperText={t('studies:helper.patientIdDigits')} formik={formik} name="PATIENT_ID_DIGITS" label={t('studies:patientIdDigits')} />
                </div>
                <div className={creationStyle['container__SectionBodyTable']}>
                    <Table stickyHeader component={"div"}>
                        <TableHead component={"div"}>
                            <TableRow component={"div"}>
                                <TableCell className={creationStyle['container__SectionBodyTableCell']} component={"div"}></TableCell>
                                <TableCell className={creationStyle['container__SectionBodyTableCell']} component={"div"}>Field name</TableCell>
                                <TableCell className={creationStyle['container__SectionBodyTableCell']} component={"div"}>Mandatory</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody component={"div"}>
                            {
                                Object.keys(fields).map((key, index) => <TableRow component={"div"} key={index}>
                                    <TableCell component={"div"}>
                                        <IconButton size="small" onClick={() => formik.setFieldValue(`PATIENT_CONFIGURATION.${key}.hidden`, !formik.values['PATIENT_CONFIGURATION'][key].hidden)}>
                                            {
                                                formik.values['PATIENT_CONFIGURATION'][key].hidden ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />
                                            }
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className={formik.values['PATIENT_CONFIGURATION'][key].hidden ? creationStyle['container__SectionBodyTableCellHidden'] : creationStyle['container__SectionBodyTableCell']} component={"div"}>{t(fields[key])}</TableCell>
                                    <TableCell component={"div"}>
                                        <Switch
                                            disabled={formik.values['PATIENT_CONFIGURATION'][key].hidden}
                                            checked={formik.values['PATIENT_CONFIGURATION'][key].mandatory}
                                            onChange={(event) => formik.setFieldValue(`PATIENT_CONFIGURATION.${key}.mandatory`, event.target.checked)}
                                            color="primary"
                                            name={`PATIENT_CONFIGURATION.${key}.mandatory`}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                </TableRow>)
                            }
                        </TableBody>
                    </Table>
                </div>
            </div>
        </Fade>
    )
}