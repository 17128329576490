import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import detailStyle from 'src/styles/detail.module.scss';


export const PanelText = ({ title, value, redirect, ...rest }) => {
    return (
        <Grid item sm={rest?.sm ? rest.sm : 6} className={detailStyle['container__BodyPanelRow']}>
            <div className={detailStyle[`container__BodyPanelText${rest?.fullWidth ? 'FullWidth' : rest?.FullWidthLarge ? 'FullWidthLarge' : '' }`]}>{title}</div>
            <div onClick={redirect} className={
                clsx({
                    [detailStyle['container__BodyPanelTextHighlight']]: !rest.fullWidth,
                    [detailStyle['container__BodyPanelTextHighlightFullWidth']] : rest.fullWidth,
                    [detailStyle['container__BodyPanelTextHighlightFullWidthLarge']] : rest.fullWidthLarge,
                    [detailStyle['container__BodyPanelTextHighlightRedirect']] : redirect,
                })}>{value ? value : rest?.isEmpty ? '' : '---'}</div>
        </Grid>
    )
}