import moment from "moment";
import { useEffect, useState } from "react"
import { useAuth } from "src/context/auth-context";
import { HandleErrorCatching } from "src/roots/AuthenticatedApp/components/utils/utils";

export function usePaginatedRequests(getFn, defaultFilters = {}, immediate = true) {
    const auth = useAuth();
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState({ count: 0, page: 0, hasMore: false, content: [] });
    const [filters, setFilters] = useState(defaultFilters);

    const [sort, setSort] = useState();
    const [orderBy, setOrderBy] = useState();

    const loadData = async (newFilters, onResolve) => {
        try {
            setLoading(true);
            getFn(newFilters ? newFilters : filters).then(_response => {
                if(onResolve){
                    onResolve(_response.data);
                }
    
                setResponse({
                    page: _response.data.number,
                    hasMore: !_response.data.last,
                    count: _response.data.totalElements,
                    content: _response.data.content
                });
                setLoading(false);
        }, error => HandleErrorCatching(error, loadData, auth));
        } catch (error) {
            setLoading(false);
            HandleErrorCatching(error, loadData, auth);
            console.log("ERROR ON LOAD PAGINATED REQUEST");
        }
    }

    const loadMore = async () => {
        try {
            setLoading(true);
            let _filters = { ...filters, page: response.page + 1 }
            let _response = await getFn(_filters);
            setResponse({
                page: _response.data.number,
                hasMore: !_response.data.last,
                count: _response.data.totalElements,
                content: response.content.concat(_response.data.content)
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            HandleErrorCatching(error, loadMore, auth);
            console.log("ERROR ON LOADMORE PAGINATED REQUEST");
        }
    }

    const changeFilters = (newFilters) => {
        let _filters = {...newFilters};
        Object.keys(_filters).forEach(key => {
            if(moment(_filters[key], true).isValid() === true && key !== 'size' && key !== 'postCode'){
                _filters[key] = moment(_filters[key]).format('YYYY-MM-DD');
            }
        })
        setFilters(_filters);
        loadData(_filters);
    }

    const handleSorting = (property) => {
        const isAsc = orderBy === property && sort === 'asc';
        let _sort = isAsc ? 'desc' : 'asc';
        let _property = property !== 'fullName' ? property : 'firstName';
        let _filters = { ...filters, sort: _property + '_' + _sort, page: 0 }
        setLoading(true);
        setSort(_sort);
        setOrderBy(property);
        setFilters(_filters);
        loadData(_filters);
    }

    useEffect(() => {
        if(immediate){
            loadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return {
        loading,
        response,
        filters,
        sort,
        orderBy,
        handleSorting,
        loadMore,
        changeFilters,
        loadData,
        setLoading
    }
}