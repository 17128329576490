import React from 'react';
import styles from './style.module.scss';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as EmptySearch } from 'assets/error.svg';


export const TableNotFound = ({customText}) => {
    return (
        <div className={styles['emptySearchTableContainer']}>
            <SvgIcon className={styles['emptySearchTableContainer__icon']} fontSize="inherit"><EmptySearch /></SvgIcon>
            <div className={styles['emptySearchTableContainer__text']}>{ customText ? customText : "No results found" }</div>
        </div>
    )
}

const NotFound = ({customText}) => {
    return (
        <div className={styles['emptySearchContainer']}>
            <SvgIcon className={styles['emptySearchContainer__icon']} fontSize="inherit"><EmptySearch /></SvgIcon>
            <div className={styles['emptySearchContainer__text']}>{ customText ? customText : "No results found" }</div>
        </div>
    )
}


export default NotFound;