import React from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { Checkbox, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetailLoader, Fade } from 'src/components';
import { usePaginatedRequests } from 'src/hooks/usePaginatedRequests';
import { GET_SUPPLIERS } from 'src/pages/Studies/services';

export const SuppliersConfiguration = ({ formik }) => {
    const { t } = useTranslation();
    const suppliers = usePaginatedRequests(GET_SUPPLIERS, {}, true);

    function handleChange(event, supplier) {
        let _supplierList = [...formik.values.SUPPLIER_LIST];

        if (event.target.checked) {
            if (!_supplierList.map(el => el.uuid).indexOf(supplier.uuid) > -1) {
                _supplierList.push({ ...supplier });
            }
        } else {
            if (_supplierList.map(el => el.uuid).indexOf(supplier.uuid) > -1) {
                _supplierList.splice(_supplierList.map(el => el.uuid).indexOf(supplier.uuid), 1);
            }
        }

        formik.setFieldValue('SUPPLIER_LIST', _supplierList);
    }

    function displayCountry(supplier){
        if(supplier.isGlobal){
            return 'Global';
        }else if(supplier?.cityList){
            return supplier.cityList.map(el => `${el.name}(${el.region.country.code})`).join(', ');
        }else if(supplier?.countryList){
            return supplier.countryList.map(el => el.name).join(', ');
        }else{
            return '---'
        }
    }


    if (suppliers.loading)
        return <DetailLoader />

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']} style={{ paddingBottom: 0 }}>{t('studies:supplierConfiguration')}</div>
            <div className={creationStyle['container__SectionTitleSecondary']}>{t('studies:supplierSecondaryTitle')}</div>
            <div className={creationStyle['container__SectionBody']}>
                <Grid container className={creationStyle['container__SectionBodyTableHeader']}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5} className={creationStyle['container__SectionBodyTableCell']}>{t('studies:supplierName')}</Grid>
                    <Grid item xs={5} className={creationStyle['container__SectionBodyTableCell']}>{t('studies:country')}</Grid>
                </Grid>
                <div className={creationStyle['container__SectionBodyTable']}>
                    {
                        suppliers.response.content.map((supplier, index) => {
                            let selected = formik.values.SUPPLIER_LIST.map(el => el.uuid).indexOf(supplier.uuid) > -1;

                            return <Grid key={index} container className={selected ? creationStyle['container__SectionBodyTableRowChecked'] : creationStyle['container__SectionBodyTableRow']}>
                                <Grid container item alignItems="center" xs={2}>
                                    <Checkbox color="primary" checked={selected} onChange={event => handleChange(event, supplier)} />
                                </Grid>
                                <Grid container item alignItems="center" xs={5} className={creationStyle['container__SectionBodyTableCell']}>{supplier.name}</Grid>
                                <Grid container item alignItems="center" xs={5} className={creationStyle['container__SectionBodyTableCell']}>{displayCountry(supplier)}</Grid>
                            </Grid>
                        })
                    }
            </div>
            </div>
        </Fade >
    )
}