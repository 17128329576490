import HttpRequestsHandler from '../services/http-requests-handler';


const path =  {
    POST_LOGIN:'/api/auth/signin',
    REFRESH_TOKEN:'/api/auth/refresh',
    GET_PROFILE:'/api/auth/profile',
    MY_PROFILE: '/api/user/myprofile',
    GET_PROFILE_STUDYCOORDINATOR: '/api/studycoordinator/myprofile',
    GET_PROFILE_PATIENT: '/api/patient/myprofile',
    VALIDATE_TOKEN:'/api/auth/token',
    CATCH_ERRORS: '/api/error/util',
    FORGOT_PASSWORD:'/api/auth/forgotpassword',
    CHECK_OTP: '/api/auth/twofa/check',
    GET_LABELS: '/api/label',
    REQUEST_RESET_PASSWORD: '/api/reset/request/forgot',
    RESET_PASSWORD: '/api/reset/forgot',
    VALIDATE_RESET_TOKEN: '/api/reset/user/:uuid',
    CHANGE_PASSWORD_EXPIRED: '/api/reset/expired',
    GET_ROLES: '/api/user/roles'
}

export const login = (body) => {
    return HttpRequestsHandler("POST", path.POST_LOGIN, null, body, true);
}

export const REFRESH_TOKEN = (body) => {
    return HttpRequestsHandler("POST", path.REFRESH_TOKEN, null, body, true);
}

export const GET_PROFILE = () => {
    return HttpRequestsHandler("GET", path.GET_PROFILE, null);
}

export const VALIDATE_TOKEN = () => {
    return HttpRequestsHandler("GET", path.VALIDATE_TOKEN, null);
}

export const CATCH_ERRORS = (body) => {
    return HttpRequestsHandler("POST", path.CATCH_ERRORS, null, body);
}

export const MY_PROFILE = () => {
    return HttpRequestsHandler("GET", path.MY_PROFILE, null);
}

export const FORGOT_PASSWORD = (body) => {
    return HttpRequestsHandler("POST", path.FORGOT_PASSWORD, null, body);
}

export const CHECK_OTP = (body) => {
    return HttpRequestsHandler("POST", path.CHECK_OTP, null, body);
}

export const GET_LABELS = async (params) => {
        return HttpRequestsHandler("GET", path.GET_LABELS, params);
}

export const REQUEST_RESET_PASSWORD = (body) => {
    return HttpRequestsHandler("PUT", path.REQUEST_RESET_PASSWORD, null, body, true);
}

export const RESET_PASSWORD = (body) => {
    return HttpRequestsHandler("PUT", path.RESET_PASSWORD, null, body);
}

export const VALIDATE_RESET_TOKEN = (uuid) => {
    return HttpRequestsHandler("GET", path.VALIDATE_RESET_TOKEN.replace(/:uuid/gi, uuid), null);
}

export const CHANGE_PASSWORD_EXPIRED = (body) => {
    return HttpRequestsHandler("PUT", path.CHANGE_PASSWORD_EXPIRED, null, body);
}

export const GET_ROLES = () => {
    return HttpRequestsHandler("GET", path.GET_ROLES, null);
}

export const GET_PROFILE_STUDYCOORDINATOR = () => {
    return HttpRequestsHandler("GET", path.GET_PROFILE_STUDYCOORDINATOR, null);
}

export const GET_PROFILE_PATIENT= () => {
    return HttpRequestsHandler("GET", path.GET_PROFILE_PATIENT, null);
}