
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { ReactComponent as PatientSVG } from 'assets/patient.svg';
import { ReactComponent as AccountSVG } from 'assets/access_administration.svg';
import { ReactComponent as StudiesSVG } from 'assets/studies.svg';
import { ReactComponent as HospitalSVG } from 'assets/hospital.svg';
import { ReactComponent as StudycoordinatorSVG } from 'assets/doctor.svg';
import { ReactComponent as CalendarSVG } from 'assets/calendar.svg';
import { ReactComponent as VisitSVG } from 'assets/medical_report.svg';
import { ReactComponent as ServiceSVG } from 'assets/services.svg';
import { ReactComponent as ReportSVG } from 'assets/report.svg';
import { ReactComponent as SponsorSVG } from 'assets/sponsor.svg';
import CreditCardIcon from '@material-ui/icons/CreditCard';

export const GetMenuButtons = (roles, {accountType, sectionsUuid}) => {
    let _sidebarMenus = [{
        icon: <DashboardIcon fontSize="large" />,
        title: "dashboard",
        linkTo: "dashboard",
        isActive: false,
        section: 'default'
    }];


        if(accountType !== 'SPONSOR' && roles.includes('ROLE_ICARE_STUDY_READ_ALL')){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><StudiesSVG /></SvgIcon>,
                title: `${accountType === 'STUDY_COORDINATOR' && sectionsUuid.studyUuid  ? "myStudy" : "studies"}`,
                linkTo: `${accountType === 'STUDY_COORDINATOR' && sectionsUuid.studyUuid  ? "studies/detail/" + sectionsUuid.studyUuid : "studies"}`,
                isActive: false,
                section: 'default'
            });
        }
        if(accountType !== 'SPONSOR' && (accountType !== 'PATIENT' && roles.includes('ROLE_ICARE_SITE_READ_ALL')) || (accountType === 'PATIENT' && roles.includes('ROLE_ICARE_SITE_READ'))){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><HospitalSVG /></SvgIcon>,
                title: `${accountType === 'STUDY_COORDINATOR' && sectionsUuid.siteUuid || accountType === 'PATIENT' && sectionsUuid.siteUuid  ? "mySite" : "sites"}`,
                linkTo: `${accountType === 'STUDY_COORDINATOR' && sectionsUuid.siteUuid || accountType === 'PATIENT' && sectionsUuid.siteUuid  ? "sites/detail/" + sectionsUuid.siteUuid : "sites"}`,
                isActive: false,
                section: 'default'
            });
        }
        if(accountType !== 'SPONSOR' && (accountType !== 'PATIENT' && roles.includes('ROLE_ICARE_STUDY_COORDINATOR_READ_ALL')) || (accountType === 'PATIENT' && roles.includes('ROLE_ICARE_STUDY_COORDINATOR_READ'))){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><StudycoordinatorSVG /></SvgIcon>,
                title: `${accountType === 'STUDY_COORDINATOR' ? "myProfile" : "studyCoordinators"}`,
                linkTo: `${accountType === 'STUDY_COORDINATOR' || accountType === 'PATIENT' ? "studycoordinators/detail/" + sectionsUuid.studyCoordinatorUuid : "studycoordinators"}`,
                isActive: false,
                section: 'default'
            });
        }
        if(accountType !== 'SPONSOR' && (accountType !== 'PATIENT' && roles.includes('ROLE_ICARE_PATIENT_READ_ALL')) || (accountType === 'PATIENT' && roles.includes('ROLE_ICARE_PATIENT_READ'))){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><PatientSVG /></SvgIcon>,
                title: `${accountType === 'PATIENT' ? "myProfile" : "patients"}`,
                linkTo: `${accountType === 'PATIENT' ? "patients/detail/" + sectionsUuid.patientUuid : "patients"}`,
                isActive: false,
                section: 'default'
            });
        }
        if(accountType !== 'SPONSOR' && roles.includes('ROLE_ICARE_VISIT_READ_ALL')){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><VisitSVG /></SvgIcon>,
                title: "visits",
                linkTo: "visits",
                isActive: false,
                section: 'default'
            });
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><CalendarSVG /></SvgIcon>,
                title: "calendar",
                linkTo: "calendar",
                isActive: false,
                section: 'default'
            });
        }
        if(accountType !== 'SPONSOR' && roles.includes('ROLE_ICARE_SUPPLIER_READ_ALL')){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><ServiceSVG /></SvgIcon>,
                title: "suppliers",
                linkTo: "suppliers",
                isActive: false,
                section: 'default'
            });
        }
        if(accountType !== 'SPONSOR' && roles.includes('ROLE_ICARE_SERVICE_READ_ALL')){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><ServiceSVG /></SvgIcon>,
                title: "services",
                linkTo: "services",
                isActive: false,
                section: 'default'
            });
        }
        if(accountType !== 'SPONSOR' && roles.includes('ROLE_ICARE_USER_READ_ALL')){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><AccountSVG /></SvgIcon>,
                title: "accounts",
                linkTo: "accounts",
                isActive: false,
                section: 'administration'
            });
        }

        if(accountType === 'PARAGON_STAFF'){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><CreditCardIcon /></SvgIcon>,
                title: `${"Debit cards"}`,
                linkTo: `${"debitcards"}`,
                isActive: false,
                section: 'default'
            });
        }

        // if(roles.includes('ROLE_ICARE_CONFIGURATION_READ')){
        //     _sidebarMenus.push({
        //         icon: <SettingsIcon fontSize="large" />,
        //         title: "configuration",
        //         linkTo: "config",
        //         isActive: false,
        //         section: 'others'
        //     });
        // }

        if(roles.filter(role => role.endsWith("_REPORT")).length > 0){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large"><ReportSVG /></SvgIcon>,
                title: "reporting",
                linkTo: "reports",
                isActive: false,
                section: 'administration'
            });
        }
        if(accountType !== 'SPONSOR' && roles.includes('ROLE_ICARE_SPONSOR_READ_ALL')){
            _sidebarMenus.push({
                icon: <SvgIcon fontSize="large" ><SponsorSVG /></SvgIcon>,
                title: "sponsors",
                linkTo: "sponsors",
                isActive: false,
                section: 'administration'
            });
        }

    return _sidebarMenus;
}