import React, { createRef, useState } from 'react';
import mainStyle from 'src/styles/main.module.scss';
import { Button, Checkbox, Dialog, FormControlLabel, IconButton, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { IsMobile } from 'src/hooks/MediaQuery/isMobile';
import { DialogContent } from '../Dialog/DialogContent';
import { FilledDialogTitle } from '../Dialog/FilledDialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { UPLOAD_STUDY_DOCUMENT } from 'src/hooks/Study/services';
import { HandleErrorCatching } from 'src/roots/AuthenticatedApp/components/utils/utils';
import { useAuth } from 'src/context/auth-context';
import { useRoles } from 'src/hooks/User/useRoles';
import { UPLOAD_PATIENT_DOCUMENT } from 'src/hooks/Patient/services';

export const UploadDialogButton = (props) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const isMobile = IsMobile();
    const [open, setOpen] = useState(false);
    const [highlight, setHighlight] = useState(false);
    const [files, setFiles] = useState([]);
    const { children, maxWidth, title, studyUuid, patientUuid, reloadDetail, ...rest } = props;
    const fileInputRef = createRef();
    const permissions = useRoles();

    const openFileDialog = () => {
        fileInputRef.current.click()
    }

    const onFilesAdded = (evt) => {
        let _files = evt.target.files;
        setFiles(fileListToArray(_files));
    }

    const onDragOver = (evt) => {
        evt.preventDefault();
        setHighlight(true);
    }

    const onDragLeave = () => {
        setHighlight(false);
    }

    const onDrop = (event) => {
        event.preventDefault()
        let files = event.dataTransfer.files;
        const array = fileListToArray(files);
        setFiles(array);
        setHighlight(false);
    }


    const fileListToArray = (list) => {
        const array = []
        for (var i = 0; i < list.length; i++) {
            let file = {
                expanded: true,
                file: null,
                fileName: '',
                visibility: 'ALL',
                category: '',
                needsSignature: false,
                requiredForActivation: false
            };
            file.file = list.item(i);
            file.fileName = list.item(i).name;
            array.push(file)
        }
        return array
    }

    function handleOpen() {
        setOpen(!open);
    }

    function handleBack() {
        setFiles([]);
    }

    function handleExpand(index) {
        let _files = [...files];
        files[index].expanded = !files[index].expanded;
        setFiles(_files);
    }

    function handleChange(event, index, value) {
        let _files = [...files];
        files[index][event.target.name] = value;
        setFiles(_files);
    }

    const callHTTPRequest = (requests) => {
        if (requests.length > 0) {
            requests[0]().then(() => {
                requests.splice(0, 1);
                callHTTPRequest(requests);
            }, error => HandleErrorCatching(error, () => callHTTPRequest(requests), auth));
        } else {
            reloadDetail();
            handleOpen();
            return;
        }
    }

    const uploadDocuments = () => {
        let httpRequests = [];

        files.forEach(el => {
            let formData = new FormData();
            let _jsonFile = { ...el };
            delete _jsonFile.file;

            let _oldNameExtension = el.file.name.slice((el.file.name.lastIndexOf(".") - 1 >>> 0) + 2);
            let _newNameExtension = el.fileName.slice((el.fileName.lastIndexOf(".") - 1 >>> 0) + 2);

            if (_newNameExtension !== _oldNameExtension) {
                el.fileName = el.fileName.replace(_newNameExtension, _oldNameExtension);
            }

            formData.append('document', el.file, el.fileName);
            formData.append('documentInfo', new Blob([JSON.stringify(_jsonFile)], {
                type: 'application/json'
            }));

            if (patientUuid) {
                httpRequests.push(() => UPLOAD_PATIENT_DOCUMENT(patientUuid, formData));
            } else {
                httpRequests.push(() => UPLOAD_STUDY_DOCUMENT(studyUuid, formData));
            }

        });

        callHTTPRequest(httpRequests);
    }

    return (
        <>
            <Button onClick={handleOpen} {...rest}>
                {children}
            </Button>
            <Dialog fullWidth maxWidth={maxWidth ? maxWidth : 'sm'} fullScreen={isMobile} open={open}>
                <FilledDialogTitle onClose={handleOpen}>
                    {title}
                </FilledDialogTitle>
                <DialogContent className={mainStyle['container__UploadDocuments']}>
                    {
                        files.length > 0 ? <div className={mainStyle['container__UploadDocumentsList']}>
                            <div className={mainStyle['container__UploadDocumentsListHeader']}>
                                <Tooltip title={t('generic:back')}>
                                    <IconButton onClick={handleBack} size="small" >
                                        <ArrowBackRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                                <div className={mainStyle['container__UploadDocumentsListHeaderButton']}>
                                    <Button variant="contained" color="primary" onClick={uploadDocuments}>
                                        {t('generic:uploadFiles')}
                                    </Button>
                                </div>
                            </div>
                            <div className={mainStyle['container__UploadDocumentsListBody']}>
                                {
                                    files.map((file, index) => <div key={index} className={mainStyle['container__UploadDocumentsListBodyFiles']}>
                                        <div className={mainStyle[`container__UploadDocumentsListBodyRow${file.expanded ? 'Expanded' : ''}`]}>
                                            <IconButton color="primary" size="small" onClick={() => handleExpand(index)}>
                                                {file.expanded ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                                            </IconButton>
                                            <div>
                                                {file.fileName}
                                            </div>
                                        </div>
                                        {
                                            file.expanded && <div className={mainStyle['container__Column']}>
                                                <div className={mainStyle['container__Row']}>
                                                    <TextField label={t('generic:fileName')} fullWidth value={files[index].fileName} name="fileName" variant="outlined" color="primary" className={mainStyle['container__Field']} onChange={event => handleChange(event, index, event.target.value)} />
                                                </div>
                                                <div className={mainStyle['container__Row']}>
                                                    {!patientUuid && <TextField select label={t('generic:fileFor')} fullWidth value={files[index].visibility} name="visibility" variant="outlined" color="primary" className={mainStyle['container__Field']} onChange={event => handleChange(event, index, event.target.value)}>
                                                        <MenuItem value="ALL">{t('generic:all')}</MenuItem>
                                                        <MenuItem value="PATIENT">{t('generic:patient')}</MenuItem>
                                                        <MenuItem value="STUDY_COORDINATOR">{t('generic:studyCoordinator')}</MenuItem>
                                                    </TextField>}
                                                    <TextField label={t('generic:fileCategory')} fullWidth value={files[index].category} name="category" variant="outlined" color="primary" className={mainStyle['container__Field']} onChange={event => handleChange(event, index, event.target.value)} />
                                                </div>
                                                <div className={mainStyle['container__Row']} style={{ borderBottom: '1px solid #CCC' }}>
                                                    {!patientUuid && <div className={mainStyle['container__FieldFlex']}>
                                                        <FormControlLabel control={<Checkbox color="primary" checked={file.needToBeSigned} name={"needToBeSigned"} onChange={event => handleChange(event, index, event.target.checked)} />}
                                                            label={t('generic:fileNeedToBeSigned')}
                                                        />
                                                    </div>}

                                                    {
                                                        file.visibility === 'PATIENT' && <div className={mainStyle['container__FieldFlex']}>
                                                            <FormControlLabel control={<Checkbox color="primary" checked={file.requiredForActivation} name={"requiredForActivation"} onChange={event => handleChange(event, index, event.target.checked)} />}
                                                                label={t('generic:fileIsMandatory')}
                                                            /> </div>
                                                    }
                                                </div>

                                            </div>
                                        }
                                    </div>)
                                }
                            </div>
                        </div> :
                            <div className={
                                highlight ? mainStyle['container__UploadDocumentsDropZoneHighlight'] : mainStyle['container__UploadDocumentsDropZone']}
                                onDragOver={onDragOver}
                                onDragLeave={onDragLeave}
                                onDrop={onDrop}
                                onClick={openFileDialog}>
                                <div className={mainStyle['container__UploadDocumentsDropZoneText']}>
                                    <CloudUploadIcon fontSize="inherit" />
                                    <span>{t('generic:dropFiles')}</span>
                                </div>
                                <input accept="application/pdf, image/*, application/msword, application/vnd.ms-excel" name="fileName" value='' type="file" multiple className={mainStyle['container__UploadDocumentsDropZoneInput']} ref={fileInputRef} onChange={onFilesAdded} />
                            </div>
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}