import HttpRequestsHandler from "src/services/http-requests-handler";

const path =  {
    USER_CONFIGURATION: '/api/user/configuration',
    NOTIFICATION_MANAGEMENT: '/api/user/notification/management',
    GET_FOLLOWED_STUDIES: '/api/user/followedstudies',
    PUT_USER_LANGUAGES: '/api/user/languages',
    GET_TFA_TOKEN: '/api/auth/twofa/token',
    GET_TFA_QRCODE: '/api/auth/twofa/qrcode',
    SEND_OTP: '/api/auth/twofa'
}

export const GET_USER_CONFIGURATION = () => {
    return HttpRequestsHandler("GET", path.USER_CONFIGURATION, null);
}

export const PUT_USER_CONFIGURATION = (body) => {
    return HttpRequestsHandler("PUT", path.USER_CONFIGURATION, null, { json: body });
}

export const GET_NOTIFICATION_MANAGEMENT = () => {
    return HttpRequestsHandler("GET", path.NOTIFICATION_MANAGEMENT, null);
}

export const PUT_NOTIFICATION_MANAGEMENT = (body) => {
    return HttpRequestsHandler("PUT", path.NOTIFICATION_MANAGEMENT, null, body );
}

export const GET_FOLLOWED_STUDIES = (filters) => {
    return HttpRequestsHandler("GET", path.GET_FOLLOWED_STUDIES, filters);
}

export const PUT_USER_LANGUAGES = (body) => {
    return HttpRequestsHandler("PUT", path.PUT_USER_LANGUAGES, null, body );
}

export const GET_TFA_TOKEN = () => {
    return HttpRequestsHandler("GET", path.GET_TFA_TOKEN, null);
}

export const GET_TFA_QRCODE = () => {
    return HttpRequestsHandler("GET", path.GET_TFA_QRCODE, null, null, null, {
        responseType: 'arraybuffer'
      });
}

export const SEND_OTP = (body) => {
    return HttpRequestsHandler("PUT", path.SEND_OTP, null, body);
}