import React, { useEffect, useState } from 'react';
import styles from '../Dashboard.module.scss';
import { ReactComponent as Doctors } from 'assets/undraw_doctors_hwty.svg';
import { ReactComponent as DoctorsTwo } from 'assets/undraw_medicine_b1ol.svg';
import { IsMobile } from 'src/hooks/MediaQuery/isMobile';
import { useProfile } from 'src/hooks/User/useProfile';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

let stopRefresh = false;

export const WelcomeText = () => {
    const profile = useProfile();
    const isMobile = IsMobile();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState(moment().format('h:mm'));


    const refreshData = () => {
        setTimeout(() => {
            setData(moment().format('h:mm'))
            if(!stopRefresh)
            refreshData();
        }, 1000);
    }

    useEffect(() => {
        refreshData();

        return () => {
            stopRefresh = true;
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

    return (
        <div className={!sessionStorage.getItem('_FL') ? styles['welcomeComponent-grow'] : styles['welcomeComponent']} >
            { !isMobile && !isMobile && <DoctorsTwo width="250" height="250" className={styles['welcomeComponent__icon']}/>}
            <div className={styles['welcomeComponent__column']}>
                <div className={styles['welcomeComponent__text-column']}>
                    <span className={styles['welcomeComponent__text-data']}>{data}</span>
                    <span className={styles['welcomeComponent__text-day']}>{moment().format('MMMM Do YYYY')}</span>
                </div>
                <div className={styles['welcomeComponent__text']}>
                    {t('dashboard:welcome')} <span className={styles['welcomeComponent__text-name']}>&ensp;{profile.fullName}</span>
                </div>
            </div>
            { !isMobile && !isMobile && <Doctors width="250" height="250" className={styles['welcomeComponent__flip']}/>}
        </div>
    )
}