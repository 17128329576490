import React, { useEffect, useState } from 'react';
import { GET_USER_CONFIGURATION, PUT_USER_CONFIGURATION } from 'src/pages/Profile/services';
import { useAuth } from 'src/context/auth-context';
import { HandleErrorCatching, IsMobile } from '../../utils/utils';
import { CustomActionButton } from '../../inputs/Button';
import { Accordion, AccordionDetails, AccordionSummary, Button, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactPlayer from 'react-player';

const Sections = ({ styles, handleClose, openMenu, userConfiguration, setUserConfiguration }) => {
    const auth = useAuth();

    useEffect(() => {
        if (openMenu === true) {
            let _userConfiguration = auth?._data?.config;

            if (!_userConfiguration?.tutorials) {
                _userConfiguration['tutorials'] = {};
            }

            setUserConfiguration(_userConfiguration)
        }
    }, 
    // eslint-disable-next-line
    [openMenu]);

    const setTutorialDone = (handleClose) => {
        if (!userConfiguration.tutorials['videoTutorials']) {
            let _userConfiguration = { ...userConfiguration };

            _userConfiguration.tutorials['videoTutorials'] = "DONE";
            PUT_USER_CONFIGURATION(_userConfiguration).then(handleClose).catch(error => HandleErrorCatching(error, setTutorialDone, auth));
        } else {
            handleClose();
        }
    }


    const tutorials = [
        {
            title: 'How to create a patient',
            id: 'createPatientSC',
            url: 'https://www.youtube.com/watch?v=8xr2TFXBr78',
            role: 'STUDY_COORDINATOR',
            description: 'In this video you will learn how to insert a patient inside PatientCRS.'
        },
        {
            title: 'How to schedule and edit a visit for a patient',
            id: 'scheduleVisitSC',
            url: 'https://www.youtube.com/watch?v=jDANk9afGGQ',
            role: 'STUDY_COORDINATOR',
            description: 'In this video you will learn how to schedule a visit for a patient inside PatientCRS.'
        },
        {
            title: 'How to request a Flight service for a patient',
            id: 'requestFlightServiceSC',
            url: 'https://www.youtube.com/watch?v=gXJYSB28ojY',
            role: 'STUDY_COORDINATOR',
            description: 'In this video you will learn how to request a Flight service for a patient inside PatientCRS.'
        },
        {
            title: 'How to request a Hotel service for a patient',
            id: 'requestHotelServiceSC',
            url: 'https://www.youtube.com/watch?v=frdGVF_cDI4',
            role: 'STUDY_COORDINATOR',
            description: 'In this video you will learn how to request a Hotel service for a patient inside PatientCRS.'
        },
        {
            title: 'How to change your password',
            id: 'resetPasswordSC',
            url: 'https://www.youtube.com/watch?v=N6pew5c2S6I',
            role: 'STUDY_COORDINATOR',
            description: 'In this video you will learn how to change your password inside PatientCRS.'
        },
        {
            title: 'How Request a reimbursement',
            id: 'requestReimbursementPT',
            url: 'https://youtu.be/trXPQ0I0k7U',
            role: 'PATIENT',
            description: 'In this video you will learn How to request a reimbursement inside PatientCRS.'
        }
    ]
    return (

        <>
            <DialogContent dividers={true}>
                <DialogContentText className={styles['formView']} component={"div"}>
                    {tutorials.map(tutorial => (
                        (auth?._data?.roles.includes(tutorial?.role) || auth?._data?.roles.includes('PARAGON_STAFF')) && 
                        <Accordion key={tutorial.id} TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary className={styles['formView__blackTitle']} expandIcon={<ExpandMoreIcon />} aria-controls={`${tutorial.id}-content`} id={`${tutorial.id}-header`}>
                                {tutorial.title}
                            </AccordionSummary>
                            <AccordionDetails className={styles['formView__column']}>
                                <div className={styles['formView__description']}>
                                    {
                                        tutorial.description
                                    }
                                </div>
                                <div className={styles['formView__row']}>
                                    <ReactPlayer
                                        controls
                                        width={'100%'}
                                        url={tutorial.url}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles['formView__actionContainer']}>
                <Button onClick={() => setTutorialDone(handleClose)} color="primary" autoFocus variant={'contained'} className={styles['formView__button']}>{'Close'}</Button>
            </DialogActions>
        </>
    )
}

const VideoTutorialDialog = ({ className, customStyles, openMenu }) => {
    const [userConfiguration, setUserConfiguration] = useState({});
    const auth = useAuth();
    const _isMobile = IsMobile();

    const reloadUserConfiguration = () => {
        GET_USER_CONFIGURATION().then(configuration => {
            auth.setState({ config: JSON.parse(configuration.data.json) });
            setUserConfiguration(JSON.parse(configuration.data.json));
        }, error => HandleErrorCatching(error, reloadUserConfiguration, auth)).catch(error => HandleErrorCatching(error, reloadUserConfiguration, auth))
    }

    return (
        <CustomActionButton variant="text" className={className} customStyles={customStyles} icon={<OndemandVideoIcon fontSize="large"/>} fullScreen={_isMobile} fullWidth maxWidth={"md"} text={openMenu ? "Video tutorials" : ''} color="inherit" dialogTitle="Video Tutorials" onCloseFn={reloadUserConfiguration}>
            <Sections setUserConfiguration={setUserConfiguration} userConfiguration={userConfiguration} openMenu={openMenu}/>
        </CustomActionButton>
    )
}

export default VideoTutorialDialog;