/* RESPONSIVE CONST */

import { useMediaQuery } from "react-responsive";
import { CATCH_ERRORS } from "context/services";

// const IsMediumDevice = useMediaQuery({ query: '(max-width: 1280px)' });
// const IsLargeDevice = useMediaQuery({ query: '(min-width: 1281px)' });

export const IsMobile = () => {
    const _isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const _isMobileDevice = useMediaQuery({ query: '(max-device-width: 768px)' });

    return _isMobile || _isMobileDevice ? true : false;
}

export const IsTablet = () => {
    const _isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const _isTabletDevice = useMediaQuery({ query: '(max-device-width: 1024px)' });

    return _isTablet || _isTabletDevice ? true : false;
}


export const HandleErrorCatching = (error, lastFn, { refreshToken, signOut, handleError }) => {
    CATCH_ERRORS({
        error: JSON.stringify({
            response: error.response, 
            stack: JSON.stringify({ response: error.response, stack: error.stack })
        }) 
    });

    if (error?.response?.status === 401) {
        refreshToken(lastFn);
    }

    if(error?.response?.status === 403){
      handleError(403);
    }

    if(error?.response?.status === 404){
      handleError(404);
    }
}


export function componentLoader(lazyComponent, attemptsLeft) {
    return new Promise((resolve, reject) => {
      lazyComponent()
        .then(resolve)
        .catch((error) => {
          CATCH_ERRORS({
            error: JSON.stringify({
                response: error.response, 
                stack: JSON.stringify({ response: error.response, stack: error.stack })
            }) 
        });
          // let us retry after 1500 ms
          setTimeout(() => {
            if (attemptsLeft === 1) {
              reject(error);
              return;
            }
            componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
          }, 1500);
        });
    });
  }


  export function navigateThroughDetail (history, URL) {
    let _lstLocationList = [];
    if(sessionStorage.getItem('_lstURL')){
      _lstLocationList = _lstLocationList.concat(...JSON.parse(sessionStorage?.getItem('_lstURL')))
    }
    let _currentLocation = window.location.pathname + window.location.search;
    _lstLocationList.push(_currentLocation);
    sessionStorage.setItem('_lstURL', JSON.stringify(_lstLocationList));
    history.push(URL);
  }