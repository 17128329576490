import { useEffect, useState } from "react";
import { useAuth } from "src/context/auth-context"



export function useProfile () {
    const auth = useAuth();
    const [profile, setProfile] = useState({});

    useEffect(() => {
        if(auth._data){
            setProfile({
                accountType: auth._data.profile.accountType,
                firstName: auth._data.profile.firstName,
                lastName: auth._data.profile.lastName,
                fullName: [auth._data.profile.firstName, auth._data.profile.lastName].join(' '),
                initials: String(auth._data.profile.firstName).charAt(0) + String(auth._data.profile.lastName).charAt(0),
                hasAvatar: auth._data.config.avatar ? true : false,
                avatar: {
                    ...auth._data.config.avatar,
                    style:{
                        width: '3rem',
                        height: '3rem'
                    },
                    avatarStyle:'Circle'
                }
            })
        }
    }, [auth]);

    return profile;
}