import React from 'react';
import styles from 'src/styles/main.module.scss';
import { ReactComponent as EmptySearch } from 'assets/error.svg';
import { SvgIcon } from '@material-ui/core';

export const TableElementsNotFound = () => {

    return <div className={styles['container__TableNotFound']}>
        <SvgIcon className={styles['container__TableNotFoundIcon']}><EmptySearch /></SvgIcon>
        <div className={styles['container__TableNotFoundText']}> No results found, please retry with a new research</div>
    </div>
}