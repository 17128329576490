import React, { useRef, useState, createRef, useEffect, useCallback } from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Grid, IconButton, InputBase, SvgIcon, Tooltip } from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { AssignDebitCardDialog, ConfirmDialogButton, DetailLoader, Fade, FormField } from 'src/components';
import { useParams } from 'react-router';
import mainStyle from 'src/styles/main.module.scss';
import ClearIcon from '@material-ui/icons/Clear';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useExcelReader } from 'src/hooks/useExcel';

export const StudyDebitCardsConfiguration = ({ formik, debitCards }) => {
    const { t } = useTranslation();
    const { studyUuid, editUuid } = useParams();
    const scrollDestinationRef = useRef(null);
    const [searchValue, setSearchValue] = useState(null);

    const fileInputRef = createRef();
    const [file, setFile] = useState(null);
    const excelReader = useExcelReader(file);

    const assignDebitCardRef = createRef(null);

    function handleChangeUpload(e) {
        const files = e.target.files;
        if (files && files[0]) {
            setFile(e.target.files[0]);
        }
    }

    function SearchWithFilters() {
    }

    function onSearch(event) {
        if (event.key === 'Enter' && searchValue.length) {
            SearchWithFilters();
        }
    }

    function onAdd() {
        let _debitCards = [...formik.values.cardList];
        _debitCards.push({
            code: '',
            editable: true
        });
        formik.setFieldValue('cardList', _debitCards);
        setTimeout(() => {
            scrollDestinationRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 200);
    }

    function onRemove(debitCard, index, handleClose) {
        if (debitCard.uuid) {
            debitCards.Remove({
                cardType: "STUDY",
                cardUuid: debitCard.uuid,
                studyUuid: studyUuid || editUuid,
                siteUuid: null,
                patientUuid: null
            }, () => {
                let _debitCards = formik.values.cardList;
                _debitCards.splice(index, 1);
                formik.setFieldValue("cardList", _debitCards);
                handleClose();
            });
        } else {
            let _debitCards = formik.values.cardList;
            _debitCards.splice(index, 1);
            formik.setFieldValue("cardList", _debitCards);
            handleClose();
        }
    }

    const bulkLoadDebitCards = useCallback((data) => {
        let _colIndex = 0;
        let _data = data.data;
        _data.shift();
        let _debitCards = [...formik.values.cardList];

        _data = _data.filter(el => el.length > 0);

        console.log(_data);

        if (data.data[0][0] !== 'DEBIT CARD NUMBER') {
            data.data[0].forEach((elem, index) => {
                if (elem === 'DEBIT CARD NUMBER') {
                    _colIndex = index;
                }
            });
        }
        if (_data.length > 0) {
            _data.forEach(debitCard => {
                _debitCards.push({
                    code: debitCard[_colIndex],
                    editable: true
                });

                if (_debitCards.length === formik.values.cardList.length + _data.length) {
                    formik.setFieldValue('cardList', _debitCards);
                    setTimeout(() => {
                        scrollDestinationRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200);
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assignCard = (card) => {
        let _debitCards = [...formik.values.cardList];
        _debitCards.push({
            code: card.code
        });
        formik.setFieldValue('cardList', _debitCards);
    }

    useEffect(() => {
        if (excelReader.data)
            bulkLoadDebitCards(excelReader.data)
    }, [bulkLoadDebitCards, excelReader.data]);

    if (debitCards.loading)
        return <DetailLoader />

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']} style={{ paddingBottom: 0 }}>
                {t('studies:debitCardConfiguration')}
                <div className={creationStyle['container__SectionTitleUpload']}>
                    <Tooltip title={t('generic:uploadFile')}>
                        <IconButton size="small" onClick={() => fileInputRef.current.click()}>
                            <CloudUploadIcon />
                        </IconButton>
                    </Tooltip>
                    <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleChangeUpload} />
                </div>
            </div>
            <div className={creationStyle['container__SectionTitleSecondary']}>{t('studies:debitCardConfigurationSecondaryTitle')}</div>
            <div className={creationStyle['container__SectionBody']}>
                <Grid container alignItems='center' className={creationStyle['container__SectionBodyTableHeader']}>
                    <Grid style={{ padding: '0 10px' }} item xs={8} className={creationStyle['container__SectionBodyTableCell']}>{t('studies:debitCardNumber')}</Grid>
                    <Grid item xs={4} className={creationStyle['container__SectionBodyTableCell']}>
                        <div>
                            <IconButton classes={{ root: mainStyle['container__SearchIcon'] }} size="small" onClick={searchValue ? SearchWithFilters : null}>
                                <SvgIcon fontSize="default">
                                    <SearchIcon />
                                </SvgIcon>
                            </IconButton>
                            <InputBase value={searchValue || ''} onChange={e => { setSearchValue(e.target.value) }} onKeyDown={onSearch} classes={{ root: mainStyle['container__SearchInput'] }} placeholder={"search debit card..."}
                                endAdornment={searchValue && searchValue.length > 0 ?
                                    <IconButton size="small" onClick={() => setSearchValue(null)}>
                                        <ClearIcon />
                                    </IconButton> : null} />
                        </div>
                    </Grid>
                </Grid>
                <div className={creationStyle['container__SectionBodyTable']} style={{ height: 'calc(100vh - 560px)', position: 'relative' }}>
                    {
                        formik.values.cardList.map((debitCard, index) => (!searchValue || debitCard.code.includes(searchValue)) && <Grid ref={index === formik.values.cardList.length - 1 ? scrollDestinationRef : null} key={index} container className={creationStyle['container__SectionBodyTableRowFixed']}>
                            <Grid style={{ padding: '10px' }} container item alignItems="center" xs={11} className={creationStyle['container__SectionBodyTableCell']}>
                                {debitCard.editable ? <FormField required inputProps={{ maxLength: 10 }} fullWidth placeholder={t('studies:debitCardNumber')} InputProps={{ classes: { root: creationStyle['container__FieldWhite'] } }} formik={formik} name={`cardList[${index}].code`} /> : debitCard.code}
                            </Grid>
                            <Grid justify="center" container item alignItems="center" xs={1} className={creationStyle['container__SectionBodyTableCell']}>
                                <ConfirmDialogButton size="small" iconButton maxWidth="sm" onConfirm={(handleClose) => onRemove(debitCard, index, handleClose)} text={t('studies:removeCard.dialogText')} title={t('studies:removeCard.dialogTitle')}><DeleteRoundedIcon /></ConfirmDialogButton>
                            </Grid>
                        </Grid>
                        )}
                </div>
                <div className={creationStyle['container__SectionBodyButtons']}>
                    <Button fullWidth onClick={onAdd} color="primary">
                        {t('studies:addDebitCard')}
                    </Button>
                    <div className={creationStyle['container__SectionBodyButtonsOr']}>{t('generic:or')}</div>
                    <Button fullWidth onClick={() => assignDebitCardRef.current.openDialog()} color="primary">
                        {t('studies:assignDebitCard')}
                    </Button>
                    <AssignDebitCardDialog cards={formik.values.cardList.map(el => el.code)} ref={assignDebitCardRef} onClick={assignCard} />
                </div>
            </div>
        </Fade>
    )
}