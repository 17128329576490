import React from 'react';
import styles from '../Dashboard.module.scss';
import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import { useNotification } from 'src/hooks/Notifications/useNotification';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import { ReactComponent as NewSVG } from 'assets/new.svg';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';



export const Notifications = ({ isMobile, onScrollFn }) => {
    const notifications = useNotification();
    const { t } = useTranslation();

    return (
        <Grid className={styles['notificationContainer']} item md={5}>
            {!isMobile && <div className={styles['notificationContainer__title']}>
                {t('dashboard:latestNotifications')}
                <div className={styles['notificationContainer__title-icon']}>
                    {notifications.response.content.length > 0 && notifications.response.content[0]?.status !== 'READ' && <IconButton size="small" onClick={notifications.readAll}>
                        <Tooltip title={t('dashboard:readAll')}><DoneAllIcon /></Tooltip>
                    </IconButton>}
                </div>
            </div>}
            <div id="parentScroll" className={styles['notificationContainer__body']}>
                {notifications.response.content.length > 0 && <InfiniteScroll onScroll={onScrollFn} height={"100%"} style={{ overflowX: 'hidden' }} dataLength={notifications.response.count} next={notifications.loadMore} hasMore={notifications.response.hasMore} loader={<CircularProgress />}>
                    {
                        notifications.response.content.map((notification, index) => {
                            let _msg = t(notification.label.replace('paragon.notification.', 'dashboard:'));

                            let _objectName = <b>{notification?.object?.name}</b>;
                            let _agentName = <b>{notification?.agent?.name}</b>;
                            // let _composedMsg = <div>{_msg?.split('{agent}')[0]}{_agentName}{_msg?.split('{agent}')[1]?.split('{object}')[0]}{_objectName}{_msg?.split('{agent}')[1]?.split('{object}')[1]}</div>;
                            // let _composedMsg = <div>{_msg.replace('{agent}', _agentName).replace('{object}', _objectName)}</div>;
                            if (_msg.indexOf('{agent}') > -1) {
                                let splittedAgent = _msg.split('{agent}');
                                if (splittedAgent.indexOf("") > -1) {
                                    splittedAgent[splittedAgent.indexOf("")] = _agentName;
                                } else {
                                    splittedAgent = [splittedAgent[0], _agentName, splittedAgent[1]];
                                }

                                if (_msg.indexOf('{object}') > -1) {
                                    let indexObject = splittedAgent.map(el => typeof el === 'string' && el.includes('{object}')).indexOf(true);
                                    let splittedObject = splittedAgent[indexObject].split('{object}');
                                    splittedObject[splittedObject.indexOf("")] = _objectName;
                                    splittedAgent[indexObject] = splittedObject;


                                }
                                _msg = splittedAgent;
                            } else if (_msg.indexOf('{object}') > -1) {
                                let splittedObject = _msg.split('{object}');

                                if (splittedObject.indexOf("") > -1) {
                                    splittedObject[splittedObject.indexOf("")] = _objectName;
                                } else {
                                    splittedObject = [splittedObject[0], _objectName, splittedObject[1]];
                                }

                                // console.log("splittedObject", splittedObject);
                                _msg = splittedObject;
                            }

                            // _msg = _composedMsg;
                            if (notifications.response.content[index - 1]?.status === 'SENT' && notifications.response.content[index]?.status === 'READ') {
                                return (
                                    <Fragment key={index}>
                                        <div key={"already-read"} className={styles['notificationContainer__body-row-read']}>
                                            {t('dashboard:alreadyRead')}
                                        </div>
                                        <div key={index} className={notification.status === 'READ' ? styles['notificationContainer__body-row-done'] : styles['notificationContainer__body-row-pending']}>
                                            <div className={styles['statusIcon']} >
                                                {notification.status === 'READ' ? <DoneIcon className={styles['statusIcon__done']} /> : <NewSVG className={styles['statusIcon__pending']} />}
                                            </div>
                                            <div className={styles['notificationContainer__body-row-text']} onClick={() => notifications.handleRedirect(notification, notification.objectType.toLowerCase() + "s", notification?.object?.redirectUuid ? notification?.object?.redirectUuid : notification?.object?.uuid)}>
                                                <div>{_msg}</div>
                                                <span className={styles['notificationContainer__body-row-text-date']}>{moment(notification.createdAt * 1000).format('DD/MM/YYYY HH:mm')}</span>
                                            </div>
                                            {notification.status !== 'READ' && <div className={styles['notificationContainer__body-row-icon']}>
                                                <IconButton size="small" onClick={() => notifications.read(notification.uuid)}>
                                                    <DoneIcon htmlColor={isMobile && '#FFF'} />
                                                </IconButton>
                                            </div>
                                            }
                                        </div>
                                    </Fragment>
                                )
                            } else {
                                return (
                                    <div key={index} className={notification.status === 'READ' ? styles['notificationContainer__body-row-done'] : styles['notificationContainer__body-row-pending']}>
                                        <div className={styles['statusIcon']} >
                                            {notification.status === 'READ' ? <DoneIcon className={styles['statusIcon__done']} /> : <NewSVG className={styles['statusIcon__pending']} />}
                                        </div>
                                        <div className={styles['notificationContainer__body-row-text']} onClick={() => notifications.handleRedirect(notification, notification.objectType.toLowerCase() + "s", notification?.object?.redirectUuid ? notification?.object?.redirectUuid : notification?.object?.uuid)}>
                                            <div>{_msg}</div>
                                            <span className={styles['notificationContainer__body-row-text-date']}>{moment(notification.createdAt * 1000).format('DD/MM/YYYY HH:mm')}</span>
                                        </div>
                                        {notification.status !== 'READ' && <div className={styles['notificationContainer__body-row-icon']}>
                                            <IconButton size="small" onClick={() => notifications.read(notification.uuid)}>
                                                <DoneIcon htmlColor={isMobile && '#FFF'} />
                                            </IconButton>
                                        </div>
                                        }
                                    </div>
                                )
                            }
                        })
                    }
                </InfiniteScroll>}

                {
                    notifications.response.content.length === 0 && <div className={styles['notificationContainer__body-zero']}>{t('dashboard:noNotifications')}</div>
                }
            </div>
        </Grid>
    )
}