import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: i18n.options.lng,
    fallbackLng: 'en',
    ns: ['sidebar', 'suppliers', 'services', 'generic', 'response'],
    defaultNS: 'login',
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/_language/{{lng}}/{{ns}}.json`
    },
    react: {
      useSuspense: true
    },
    debug: false
  });

export default i18n;