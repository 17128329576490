import HttpRequestsHandler from "src/services/http-requests-handler";

const path = {
    GET_STUDIES: '/api/study/all',
    POST_STUDY: '/api/study',
    PUT_STUDY: '/api/study/:code',
    DELETE_STUDY: '/api/study/:code',
    GET_STUDY_DETAIL: '/api/study/:code',
    GET_STUDYCOORDINATORS: '/api/studycoordinator/all',
    GET_PATIENTS: '/api/patient/all',
    GET_SITES: '/api/site/all',
    ASSIGN_STUDYCOORDINATOR_TO_STUDY: '/api/study/:code/studycoordinator',
    ASSIGN_SITE_TO_STUDY: '/api/study/:code/site',
    ASSIGN_PATIENT_TO_STUDY: '/api/study/:code/patient',
    GET_STUDY_PATIENTS: '/api/patient/all',
    GET_STUDY_COORDINATORS: '/api/studycoordinator/all',
    REMOVE_PATIENT_FROM_STUDY: '/api/study/:studyCode/patient/:username',
    REMOVE_COORDINATOR_FROM_STUDY: '/api/study/:studyCode/studycoordinator',
    REMOVE_SITE_FROM_STUDY: '/api/study/:studyCode/site',
    GET_STUDY_DOCUMENTS: '/api/study/:uuid/document/all',
    UPLOAD_STUDY_DOCUMENT: '/api/study/:uuid/document',
    COMPLETE_STUDY: '/api/study/:code/complete',
    GET_STUDY_SERVICETYPE: '/api/servicetype/all',
    ASSIGN_SERVICETYPE: '/api/study/:uuid/servicetype',
    ASSIGN_SUPPLIER: '/api/study/:uuid/supplier',
    GET_SUPPLIERS: '/api/supplier/all',
    GET_FOLLOW_STUDY: '/api/study/:uuid/followcheck',
    FOLLOW_STUDY: '/api/study/:uuid/follow',
    UNFOLLOW_STUDY: '/api/study/:uuid/unfollow',
    REMOVE_SUPPLIER: '/api/study/:uuid/supplier',
    ASSIGN_REIMBURSEMENT_TYPE:'/api/study/:uuidStudy/reimbursementtype/:uuidReimbursementType',
    VALIDATE_COUNTRY: '/api/location/country/validate/:code',
    GET_PAYMENT_METHODS: '/api/paymentmethod/all',
    DELETE_PAYMENT_METHOD:'/api/study/:uuid/paymentmethod',
    SET_DEFAULT_PAYMENT_METHOD: '/api/study/:studyUuid/paymentmethod/:paymentMethodUuid',
    ASSIGN_PAYMENT_METHOD: '/api/study/:studyUuid/paymentmethod',
    EDIT_STUDY_LANGUAGE: '/api/study/:studyUuid/language',
    GET_ALL_STUDY_LANGUAGES: '/api/language/all'
}


export const GET_STUDIES = (filters) => {

    return HttpRequestsHandler("GET", path.GET_STUDIES, filters);
}

export const POST_STUDY = (body) => {

    return HttpRequestsHandler("POST", path.POST_STUDY, null, body);
}

export const PUT_STUDY = (code, body) => {

    return HttpRequestsHandler("PUT", path.PUT_STUDY.replace(/:code/gi, code), null, body);
}

export const GET_STUDY_DETAIL = (code) => {
    return HttpRequestsHandler("GET", path.GET_STUDY_DETAIL.replace(/:code/gi, code), null);
}

export const GET_PATIENTS = (params) => {

    return HttpRequestsHandler("GET", path.GET_PATIENTS, params);
}

export const GET_STUDYCOORDINATORS = (params) => {

    return HttpRequestsHandler("GET", path.GET_STUDYCOORDINATORS, params);
}

export const GET_SITES = (params) => {

    return HttpRequestsHandler("GET", path.GET_SITES, params);
}

export const ASSIGN_STUDYCOORDINATOR_TO_STUDY = (studyCode, body) => {

    return HttpRequestsHandler("PUT", path.ASSIGN_STUDYCOORDINATOR_TO_STUDY.replace(/:code/gi, studyCode), null, body);
}

export const ASSIGN_SITE_TO_STUDY = (studyCode, body) => {

    return HttpRequestsHandler("PUT", path.ASSIGN_SITE_TO_STUDY.replace(/:code/gi, studyCode), null, body);
}

export const ASSIGN_PATIENT_TO_STUDY = (studyCode, body) => {

    return HttpRequestsHandler("PUT", path.ASSIGN_PATIENT_TO_STUDY.replace(/:code/gi, studyCode), null, body);
}

export const DELETE_STUDY = (code) => {

    return HttpRequestsHandler("DELETE", path.DELETE_STUDY.replace(/:code/gi, code), null, null);
}

export const GET_STUDY_PATIENTS = (params) => {

    return HttpRequestsHandler("GET", path.GET_STUDY_PATIENTS, params, null);
}

export const REMOVE_PATIENT_FROM_STUDY = (code, username) => {

    return HttpRequestsHandler("DELETE", path.REMOVE_PATIENT_FROM_STUDY.replace(/:studyCode/gi, code).replace(/:username/gi, username), null, null);
}


export const GET_STUDY_COORDINATORS = (params) => {

    return HttpRequestsHandler("GET", path.GET_STUDY_COORDINATORS, params, null);
}

export const REMOVE_COORDINATOR_FROM_STUDY = (code, body) => {

    return HttpRequestsHandler("DELETE", path.REMOVE_COORDINATOR_FROM_STUDY.replace(/:studyCode/gi, code), null, body);
}

export const REMOVE_SITE_FROM_STUDY = (code, body) => {

    return HttpRequestsHandler("DELETE", path.REMOVE_SITE_FROM_STUDY.replace(/:studyCode/gi, code), null, body);
}

export const GET_STUDY_DOCUMENTS = (uuid, params) => {

    return HttpRequestsHandler("GET", path.GET_STUDY_DOCUMENTS.replace(/:uuid/gi, uuid), params);
}

export const UPLOAD_STUDY_DOCUMENT = (uuid, body) => {

    return HttpRequestsHandler("UPLOAD", path.UPLOAD_STUDY_DOCUMENT.replace(/:uuid/gi, uuid), null, body);
}

export const COMPLETE_STUDY = (code, body) => {

    return HttpRequestsHandler("PUT", path.COMPLETE_STUDY.replace(/:code/gi, code), null, body);
}

export const GET_STUDY_SERVICETYPE = (params) => {

    return HttpRequestsHandler("GET", path.GET_STUDY_SERVICETYPE, params);

}

export const ASSIGN_SERVICETYPE = (uuid, body) => {

    return HttpRequestsHandler("PUT", path.ASSIGN_SERVICETYPE.replace(/:uuid/gi, uuid), null, body);
}

export const UNASSIGN_SERVICETYPE = (uuid, body) => {

    return HttpRequestsHandler("DELETE", path.ASSIGN_SERVICETYPE.replace(/:uuid/gi, uuid), null, body);
}

export const ASSIGN_SUPPLIER = (uuid, body) => {

    return HttpRequestsHandler("PUT", path.ASSIGN_SUPPLIER.replace(/:uuid/gi, uuid), null, body);
}

export const GET_SUPPLIERS = (params) => {

    return HttpRequestsHandler("GET", path.GET_SUPPLIERS, params);
}

export const GET_FOLLOW_STUDY = (uuid) => {

    return HttpRequestsHandler("GET", path.GET_FOLLOW_STUDY.replace(/:uuid/gi, uuid), null);
}

export const FOLLOW_STUDY = (uuid) => {

    return HttpRequestsHandler("PUT", path.FOLLOW_STUDY.replace(/:uuid/gi, uuid), null, null);
}

export const UNFOLLOW_STUDY = (uuid) => {

    return HttpRequestsHandler("PUT", path.UNFOLLOW_STUDY.replace(/:uuid/gi, uuid), null, null);
}

export const REMOVE_SUPPLIER = (uuid, body) => {

    return HttpRequestsHandler("DELETE", path.REMOVE_SUPPLIER.replace(/:uuid/gi, uuid), null, body);

}

export const ASSIGN_REIMBURSEMENT_TYPE = (uuidStudy, uuidReimbursementType, body) => {

    return HttpRequestsHandler("PUT", path.ASSIGN_REIMBURSEMENT_TYPE.replace(/:uuidStudy/gi, uuidStudy).replace(/:uuidReimbursementType/gi, uuidReimbursementType), null, body);
}

export const UNASSIGN_REIMBURSEMENT_TYPE = (uuidStudy, uuidReimbursementType, body) => {

    return HttpRequestsHandler("DELETE", path.ASSIGN_REIMBURSEMENT_TYPE.replace(/:uuidStudy/gi, uuidStudy).replace(/:uuidReimbursementType/gi, uuidReimbursementType), null, body);
}

export const VALIDATE_COUNTRY = (code) => {
    return HttpRequestsHandler("GET", path.VALIDATE_COUNTRY.replace(/:code/gi, code), null);
}

export const GET_PAYMENT_METHODS = (params) =>{
    return HttpRequestsHandler("GET", path.GET_PAYMENT_METHODS, params);
}

export const DELETE_PAYMENT_METHOD = (uuid, body) => {

    return HttpRequestsHandler("DELETE", path.DELETE_PAYMENT_METHOD.replace(/:uuid/gi, uuid), null, body);
}

export const ASSIGN_PAYMENT_METHOD = (studyUuid, body) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_PAYMENT_METHOD.replace(/:studyUuid/gi, studyUuid), null, body);
}

export const SET_DEFAULT_PAYMENT_METHOD = (studyUuid, paymentMethodUuid) => {
    return HttpRequestsHandler("PUT", path.SET_DEFAULT_PAYMENT_METHOD.replace(/:studyUuid/gi, studyUuid).replace(/:paymentMethodUuid/gi, paymentMethodUuid), null, null);
}

export const GET_ALL_STUDY_LANGUAGES = (params) => {
    return HttpRequestsHandler("GET", path.GET_ALL_STUDY_LANGUAGES, params);
}

export const ASSIGN_STUDY_LANGUAGE = (studyUuid, body) => {
    return HttpRequestsHandler("PUT", path.EDIT_STUDY_LANGUAGE.replace(/:studyUuid/gi, studyUuid), null, body);
}

export const DELETE_STUDY_LANGUAGE = (studyUuid, body) => {

    return HttpRequestsHandler("DELETE", path.EDIT_STUDY_LANGUAGE.replace(/:studyUuid/gi, studyUuid), null, body);
}