import './tools/i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import AppProviders from './context';
import Application from './App';
import theme from './styles/theme';
import { Suspense } from 'react';
import { LoadingScreen } from './components';

ReactDOM.render(
    <Suspense fallback={<LoadingScreen />}>
        <ThemeProvider theme={theme}>
            <AppProviders>
                <Application />
            </AppProviders>
        </ThemeProvider>
    </Suspense>
    ,
    document.getElementById('root'));