import PropTypes from "prop-types";
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import mainStyle from 'src/styles/main.module.scss';
import { Button, IconButton, InputBase, SvgIcon } from '@material-ui/core';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTranslation } from 'react-i18next';


export const TableSearch = forwardRef(({ inputPlaceholder, Search, filterForm }, ref) => {
    const { t } = useTranslation();
    const filterRef = createRef();
    const [searchValue, setSearchValue] = useState(null);
    const [filters, setFilters] = useState({ number: 0, content: {}, display: false });

    useImperativeHandle(ref, () => ({
        getSearchValue: () => searchValue,
        setSearchValue: setSearchValue
    }));

    useEffect(() => {
        if (typeof searchValue === 'string' && searchValue.length === 0) {
            Search({});
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchValue]);

    const onSearch = event => {
        if (event.key === 'Enter' && searchValue.length) {
            SearchWithFilters();
        }
    }

    const resetTextFn = () => {
        setSearchValue('');
        Search({});
    }

    function SearchWithFilters(){
        setFilters(oldState => {
            return { ...oldState, content: { ...oldState.content, q: searchValue }}
        });
        Search({ q: searchValue });
    }

    function DisplayFilters() {
        if(filters.display){
            Search({});
        }

        setFilters(oldState => {
            return { ...oldState, display: !oldState.display, content: oldState.display ? {} : oldState.content, number: oldState.display ? 0 : oldState.number }
        });
    }

    const ClearButton = () => {
        return (
            <IconButton size="small" onClick={resetTextFn}>
                <ClearIcon />
            </IconButton>
        )
    }

    function onSubmit(){
        let values = filterRef.current.values;
        let _filters = Object.entries(values).reduce((a, [k, v]) => (v == null || k === 'sort' ? a : (a[k] = v, a)), {});
        setFilters(oldState => {
            return { ...oldState, number: Object.keys(_filters).length, content: _filters, display: false }
        });
        Search(_filters);
    }

    return (
        <div className={mainStyle['container__RowFullWidth']}>
            <div id="searchInput" className={mainStyle['container__Search']}>
                <IconButton classes={{ root: mainStyle['container__SearchIcon'] }} size="small" onClick={searchValue ? SearchWithFilters : null}>
                    <SvgIcon fontSize="default">
                        <SearchIcon />
                    </SvgIcon>
                </IconButton>
                <InputBase value={searchValue || ''} onChange={e => { setSearchValue(e.target.value) }} onKeyDown={onSearch} classes={{ root: mainStyle['container__SearchInput'] }} placeholder={inputPlaceholder}
                    endAdornment={searchValue && searchValue.length > 0 ? <ClearButton /> : null} />
                {filters.display && <div className={mainStyle['container__FilterDialog']}>
                    <div className={mainStyle['container__FilterDialogBody']}>
                        {
                            filterForm({ t: t, ref: filterRef, filters: filters.content })
                        }
                    </div>
                    <div className={mainStyle['container__FilterDialogAction']}>
                        <Button variant="outlined" color="primary" style={{ marginRight: 15 }} onClick={DisplayFilters}>{t('generic:cancel')}</Button>
                        <Button variant="contained" color="primary" style={{ marginRight: 5 }} onClick={onSubmit}>{t('generic:search')}</Button>
                    </div>
                </div>
                }
            </div>
            {filterForm && <div className={mainStyle['container__Filter']}>
                <Button id="filterButton" onClick={DisplayFilters} color={filters.display ? "primary" : "secondary"} variant={"outlined"} className={mainStyle['container__FilterButton']} startIcon={<FilterListIcon />} endIcon={filters.number > 0 && <div className={mainStyle['container__FilterBadge']}>{filters.number}</div>} >
                    {t('generic:filters')}
                </Button>
            </div>}
        </div>
    );
});

TableSearch.propTypes = {
  Search: PropTypes.func,
  filterForm: PropTypes.func,
  inputPlaceholder: PropTypes.any
}
