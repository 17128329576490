import React, { useState, cloneElement } from 'react';
import styles from './button.module.scss';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, MenuItem, Tooltip, Fab } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/Help';
import Tutorial from 'src/roots/AuthenticatedApp/components/generalComponents/tourController/tourController';
import { useTranslation } from 'react-i18next';

const ConfirmButton = ({ menuItemBlack, id, onlyIcon, tooltipTitle, iconButtonSize, isMenuItem, icon, text, dialogTitle, dialogText, variant, color, onCloseFn, maxWidth, fullWidth, fullScreen, className }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    }

    const onSubmit = () => {
        if (onCloseFn) {
            onCloseFn();
        }
        setOpen(false);
    }

    return (
        <>
            {isMenuItem ?
                <MenuItem id={id} onClick={() => setOpen(true)}>
                    {icon && <div className={styles['buttonContainer__icon']}>{icon}</div>}
                    <div className={className ? className : menuItemBlack ? styles['buttonContainer__menuItemTextBlack'] : styles['buttonContainer__menuItemText']}>{text}</div>
                </MenuItem>
                :
                onlyIcon ?
                    <IconButton id={id} color={color} onClick={() => setOpen(true)} size={iconButtonSize ? iconButtonSize : "small"}>
                        <Tooltip title={tooltipTitle}>
                            {icon}
                        </Tooltip>
                    </IconButton>
                    :
                    <Button id={id} className={styles['buttonContainer']} variant={variant} color={color} onClick={() => setOpen(true)}>
                        {icon && <div className={styles['buttonContainer__icon']}>{icon}</div>}
                        <div className={styles['buttonContainer__text']}>{text}</div>
                    </Button>
            }
            <Dialog maxWidth={maxWidth} fullWidth={fullWidth} fullScreen={fullScreen} open={open} onClose={handleClose}>
                <DialogTitle disableTypography className={styles['dialogContainer__title']}>
                    <h2>{dialogTitle}</h2>
                    <div className={styles['dialogContainer__title-icon']}>
                        <IconButton size="small" onClick={() => setOpen(false)}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText>
                        {dialogText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)} color="primary">
                        {t('generic:cancel')}
                    </Button>
                    <Button onClick={onSubmit} color="primary" autoFocus variant={'contained'}>
                        {t('generic:confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const CustomActionButton = (props) => {
    const { menuItemBlack, customStyles, tutorialSection, id, startIcon, endIcon, tooltipTitle, onlyIcon, isFab, iconButtonSize, isMenuItem, icon, text, dialogTitle, variant, color, onCloseFn, children, maxWidth, fullWidth, fullScreen, className, buttonFullWidth} = props;
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(dialogTitle);
    const [showTutorial, setShowTutorial] = useState(false);
    const [tutSection, setTutSection] = useState(tutorialSection);

    function changeTitle(title) {
        setTitle(title);
    }

    const showHelp = () => {
        setShowTutorial(true);
    }

    const handleClose = () => {
        if (onCloseFn) {
            onCloseFn();
        }
        setOpen(false);
    }

    return (
        <>
            {isMenuItem ?
                <MenuItem id={id} onClick={() => setOpen(true)}>
                    {icon && <div className={styles['buttonContainer__icon']}>{icon}</div>}
                    <div className={menuItemBlack ? styles['buttonContainer__menuItemTextBlack'] : styles['buttonContainer__menuItemText']}>{text}</div>
                </MenuItem>
                : onlyIcon ?
                    <IconButton id={id} color={color} onClick={() => setOpen(true)} size={iconButtonSize ? iconButtonSize : "small"}>
                        <Tooltip title={tooltipTitle}>
                            {icon}
                        </Tooltip>
                    </IconButton>
                    :
                    isFab ?
                        <Fab id={id} color="primary" className={className} onClick={() => setOpen(true)}>
                            {icon}
                        </Fab>
                        :
                        <Button fullWidth={buttonFullWidth} id={id} startIcon={startIcon} endIcon={endIcon} className={className ? className : styles['buttonContainer']} variant={variant} color={color} onClick={() => setOpen(true)}>
                            {icon && <div className={customStyles?.icon ? customStyles.icon : styles['buttonContainer__icon']}>{icon}</div>}
                            <div className={customStyles?.textButton ? customStyles.textButton : styles['buttonContainer__text']}>{text}</div>
                        </Button>}
            <Dialog fullScreen={fullScreen} fullWidth={fullWidth} maxWidth={maxWidth} open={open} disableBackdropClick>
                <DialogTitle disableTypography className={styles['dialogContainer__title']}>
                    <h2>{title}</h2>
                    <div className={styles['dialogContainer__title-icon']}>
                        {tutSection && <IconButton size="small" onClick={showHelp}>
                            <HelpIcon />
                        </IconButton>
                        }
                        <IconButton size="small" onClick={handleClose}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                {cloneElement(children, { ...props, changeTutorialSection: (tut) => setTutSection(tut), changeTitle: changeTitle, handleClose: handleClose, styles: styles, open: open })}
            </Dialog>
            {
                showTutorial && <Tutorial onClose={() => setShowTutorial(false)} section={tutSection} openOnLoad={showTutorial} />
            }
        </>
    )
}

const ParagonMenuButton = (props) => {
    const { menuItemBlack, id, icon, text, onClick } = props;

    return (
        <MenuItem id={id} onClick={onClick}>
            {icon && <div className={styles['buttonContainer__icon']}>{icon}</div>}
            <div className={menuItemBlack ? styles['buttonContainer__menuItemTextBlack'] : styles['buttonContainer__menuItemText']}>{text}</div>
        </MenuItem>
    )
}

const ParagonButton = (props) => {
    const { customStyles, id, startIcon, endIcon, icon, text, variant, color, className, onClick } = props;

    return (
        <Button id={id} startIcon={startIcon} endIcon={endIcon} className={className ? className : styles['buttonContainer']} variant={variant} color={color} onClick={onClick}>
            {icon && <div className={customStyles?.icon ? customStyles.icon : styles['buttonContainer__icon']}>{icon}</div>}
            <div className={customStyles?.textButton ? customStyles.textButton : styles['buttonContainer__text']}>{text}</div>
        </Button>
    )
}


export { CustomActionButton, ConfirmButton, ParagonButton, ParagonMenuButton };