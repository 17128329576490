import React from 'react';
import creationStyle from 'src/styles/creation.module.scss';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetailLoader, Fade } from 'src/components';


export const LanguagesConfiguration = ({ formik, study, languages }) => {
    const { t } = useTranslation();

    function handleChange(event, language) {
        let _languageList = [...formik.values.LANGUAGE_LIST];

        if (event.target.checked) {
            if (!_languageList.map(el => el.code).indexOf(language.code) > -1) {
                _languageList.push({ ...language });
            }
        } else {
            if (_languageList.map(el => el.code).indexOf(language.code) > -1) {
                _languageList.splice(_languageList.map(el => el.code).indexOf(language.code), 1);
            }
        }

        formik.setFieldValue('LANGUAGE_LIST', _languageList);
    }

    if(languages.loading)
    return <DetailLoader />;

    return (
        <Fade className={creationStyle['container__Section']}>
            <div className={creationStyle['container__SectionTitle']} style={{ paddingBottom: 0 }}>{t('studies:languagesConfigurations')}</div>
            <div className={creationStyle['container__SectionTitleSecondary']}>{t('studies:languagesSecondaryTitle')}</div>
            <div className={creationStyle['container__SectionBody']} style={{ maxHeight: 'calc(100vh - 400px)' }}>
                <div className={creationStyle['container__SectionBodyTable']}>
                    <Grid container>
                        {
                            languages?.response?.data.map((language, index) => <Grid key={index} item xs={12}>
                                <FormControlLabel key={index} className={creationStyle['container__SectionBodyField']}
                                    control={<Checkbox color="primary" checked={formik.values.LANGUAGE_LIST.map(el => el.code).indexOf(language.code) > -1} onChange={event => handleChange(event, language)} />}
                                    label={`${language.code.toUpperCase()} - ${language.name}`}
                                />
                            </Grid>
                            )
                        }
                    </Grid>
                </div>
            </div>
        </Fade>
    )
}