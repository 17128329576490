import React, { cloneElement, useState } from 'react';
import styles from './paragonTable.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Table, TableHead, TableRow, TableSortLabel, TableCell, TableBody, CircularProgress, IconButton, Menu } from '@material-ui/core';
import { TableNotFound } from 'components/utils/notFound';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const TableLoader = () => {
    return <div className={styles['circularProgessLoaderContainer']}><CircularProgress /></div>
}

const DotIcon = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <TableCell className={styles['tableBodyContainer__cell']} size="medium" component={"div"} align="right">
            <IconButton id="dotIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} size="small">
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
            >
                {children}
            </Menu>
        </TableCell>
    );

}

export const ParagonActionCell = ({ children }) => {
    return (
        <DotIcon>
            {children}
        </DotIcon>
    );
}

export const ParagonTableRow = ({ element, rowIndex, children, reloadElements }) => {
    return (
        <TableRow hover component={"div"} key={rowIndex} className={styles['tableBodyContainer__row']}>
            {
                cloneElement(children, { rowIndex: rowIndex, element: element, styles: styles, reloadElements: reloadElements })
            }
        </TableRow>
    )
}

const ParagonTable = ({ view, columns, elements, getMoreElements, hasMore, order, orderBy, handleSorting, filters, paragonTableRow, isLoading, ChipComponent, reloadElements, tableStyle, headerCellStyle}) => {
    return (
        <div id="parentScroll" style={tableStyle} className={view === 'table' ? styles['tableScrollContainer'] : styles['tableScrollContainer__chip']}>
            <InfiniteScroll className={view === 'chip' && styles['tableScrollContainer__chip']} style={{ overflow: 'unset', height: view === 'chip' && '100%' }} scrollableTarget="parentScroll" dataLength={elements.length} next={getMoreElements} hasMore={hasMore}>
                {view === 'table' && <Table stickyHeader component={"div"}>
                    <TableHead component={"div"}>
                        <TableRow component={"div"} className={styles['tableHeaderContainer__row']}>
                            {
                                columns.map((column, index) => (
                                    <TableCell style={headerCellStyle} className={styles['tableHeaderContainer__cell']} size={column.size ? column.size : "medium"} align={column.align ? column.align : "left"} component={"div"} key={index} sortDirection={filters.order === column.key ? filters.sort : false}>
                                        {
                                            column.key !== 'icon' && <TableSortLabel classes={{ root: styles['tableHeaderContainer__sortLabel'], active: styles['tableHeaderContainer__sortLabel'] }} component={"div"} active={orderBy === column.key} direction={orderBy === column.key ? order : 'asc'} onClick={() => handleSorting(column.key)}>
                                                {column.title}
                                            </TableSortLabel>
                                        }
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    {isLoading ? <TableLoader /> :
                        <TableBody component={"div"} className={styles['tableBodyContainer']}>
                            {
                                elements.length > 0 ? elements.map((element, rowIndex) => cloneElement(paragonTableRow, { key: rowIndex, element: element, rowIndex: rowIndex, styles: styles, reloadElements: reloadElements })) : <TableNotFound />
                            }
                        </TableBody>
                    }
                </Table>}
                {
                    view === 'chip' && <>
                        {elements.length > 0 ? elements.map((element, rowIndex) => <ChipComponent element={element} key={rowIndex} />) : <TableNotFound />} </>
                }
            </InfiniteScroll>
        </div >
    )
}

export default ParagonTable;
