import { Button, Dialog, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IsMobile } from 'src/roots/AuthenticatedApp/components/utils/utils';
import { DialogActions } from '../Dialog/DialogActions';
import { DialogContent } from '../Dialog/DialogContent';
import { FilledDialogTitle } from '../Dialog/FilledDialogTitle';
import styles from './button.module.scss';



export const ConfirmDialogButton = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const isMobile = IsMobile();

    const { maxWidth, title, text, onConfirm, iconButton, detailButton, ...rest } = props;

    function openDialog() {
        setOpen(!open);
    }

    return (
        <>
            {!iconButton && <Button {...rest} onClick={openDialog}>
                <span className={detailButton ? styles['buttonContainer__text'] : null}>{props.children} </span>
            </Button>}
            {
                iconButton && <IconButton {...rest} onClick={openDialog}>
                    {props.children}
                </IconButton>
            }
            <Dialog fullWidth maxWidth={maxWidth ? maxWidth : 'md'} fullScreen={isMobile} open={open}>
                <FilledDialogTitle onClose={openDialog}>
                    {title}
                </FilledDialogTitle>
                <DialogContent>
                    {text}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={openDialog} variant={"outlined"}>{t('generic:cancel')}</Button>
                    <Button onClick={() => onConfirm(openDialog)} color="primary" autoFocus variant={'contained'} >{t('generic:confirm')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}