import React, { useEffect, useState } from 'react';
import { useAuth } from 'src/context/auth-context';
import { IsMobile } from 'src/hooks/MediaQuery/isMobile';
import { IsTablet } from 'src/hooks/MediaQuery/isTablet';
import { useAsync } from 'src/hooks/useAsync';
import { DesktopSidebar } from './components/desktopSidebar';
import { MobileSidebar } from './components/mobileSidebar';
import { GET_COUNT_NOTIFY, GET_SIDEBAR_MENU } from './services';
import eventSource from 'eventsource';
import { GetMenuButtons } from './components/GetMenuButtons';
import { useHistory, useRouteMatch } from 'react-router';
import { Fade } from '@material-ui/core';
import { LoadingScreen } from 'src/components';
import i18n from 'src/tools/i18n';
import { useNotification } from 'src/hooks/Notifications/useNotification';


export const Sidebar = ({ menuOpen, closeMenu, reloadCount }) => {
    const [sidebarLoading, setSidebarLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const notifications = useNotification();
    const isMobile = IsMobile();
    const isTablet = IsTablet();

    const history = useHistory();
    const detailNavigation = useRouteMatch('/:actualPath/:detail/:detailCode');
    const menuNavigation = useRouteMatch('/:actualPath');

    const auth = useAuth();
    const accountType = auth._data.profile.accountType;

    const sidebarRoles = useAsync(GET_SIDEBAR_MENU, true);
    const previousNotificationCount = useAsync(GET_COUNT_NOTIFY, true);

    const [countNotification, setCountNotification] = useState('0');
    const [selectedView, setSelectedView] = useState('');
    const [buttonList, setButtonList] = useState([]);

    useEffect(() => {

        window.addEventListener('reloadCount', () => {
            previousNotificationCount.execute();
        }, true);

        return () => {
            window.removeEventListener('reloadCount', () => {
                previousNotificationCount.execute();
            });
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (parseInt(previousNotificationCount.response?.data.message) <= parseInt(countNotification)) {
            setCountNotification(previousNotificationCount.response.data.message)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousNotificationCount.response])

    useEffect(() => {
        if (accountType) {
            i18n.loadNamespaces(['sidebar']).then(() => {
                if (accountType === 'STUDY_COORDINATOR' && sidebarRoles.response) {
                    setButtonList(GetMenuButtons(sidebarRoles.response.data, {
                        accountType, sectionsUuid: {
                            studyUuid: auth._data.profile?.studyList?.length === 1 ? auth._data.profile.studyList[0].uuid : null,
                            studyCoordinatorUuid: auth._data.profile.uuid,
                            siteUuid: auth._data.profile?.siteList?.length === 1 ? auth._data.profile.siteList[0].uuid : null,
                            patientUuid: null
                        }
                    }));
                    setSidebarLoading(false);
                } else
                    if (accountType === 'PATIENT' && sidebarRoles.response) {

                        if (auth._data.profile?.response?.data?.languageList) {
                            let defaultLanguage = auth._data.profile.languageList.filter(el => el.isMain === true)[0]?.code;

                            if (!defaultLanguage) {
                                defaultLanguage = auth._data.profile.languageList[0].code;
                            }

                            i18n.changeLanguage(defaultLanguage);
                        }

                        setButtonList(GetMenuButtons(sidebarRoles.response.data, {
                            accountType, sectionsUuid: {
                                studyUuid: null,
                                studyCoordinatorUuid: auth._data.profile.studyCoordinatorUuid,
                                siteUuid: auth._data.profile.site.uuid,
                                patientUuid: auth._data.profile.uuid
                            }
                        }));
                        setSidebarLoading(false);
                    }
                    else if (accountType !== 'PATIENT' && accountType !== 'STUDY_COORDINATOR' && sidebarRoles.response) {
                        setButtonList(GetMenuButtons(sidebarRoles.response.data, { accountType, sectionsUuid: {} }));
                        setSidebarLoading(false);
                    }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountType, sidebarRoles.response]);

    useEffect(() => {
        if (previousNotificationCount.response) {
            setCountNotification(previousNotificationCount.response.data.message);
        }
    }, [previousNotificationCount, reloadCount])

    useEffect(() => {
        const eventSourceInitDict = { headers: { 'Authorization': localStorage.getItem('token') } };
        const evtSource = new eventSource('/api/realtime/notification/count', eventSourceInitDict);

        evtSource.onmessage = function ({ data }) {
            let _parsedData = JSON.parse(data).notificationCount;
            setCountNotification(_parsedData);
        };

        return () => {
            evtSource.close();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    useEffect(() => {
        if (buttonList.length) {
            let _actualPath = menuNavigation && menuNavigation.params && menuNavigation.params.actualPath;
            let _isDetail = (window.location.href.includes('/reports/visits') || window.location.href.includes('reports/studies') || window.location.href.includes('/create')) ||
                detailNavigation && detailNavigation.params && (detailNavigation.params.detail === "detail" || detailNavigation.params.detail === "history" || detailNavigation.params.detail === "edit" || detailNavigation.params.detail === "configuration" || detailNavigation.params.detail === "clone" ) && detailNavigation.params.detailCode.length > 0;
            let _sameURL = false;

            buttonList.forEach(({ linkTo }) => {
                if (_actualPath === linkTo) {
                    _sameURL = true;
                    if (!_isDetail) history.push('/' + linkTo);
                    setSelectedView(_actualPath);
                }
            });
            if (!_sameURL && !_isDetail && !window.location.href.includes('myprofile')) {
                setSelectedView(buttonList[0].linkTo);
                history.push('/' + buttonList[0].linkTo);
            }
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [buttonList]);

    history.listen(({ pathname }) => {
        if (pathname.includes('myprofile')) {
            setSelectedView(null);
        } else {
            buttonList.forEach(({ linkTo }) => {
                if (linkTo.includes(pathname.split('/')[1])) {
                    setSelectedView(linkTo);
                }
            })
        }
    });

    useEffect(() => {
        if(selectedView && auth.displayError !== null){
            auth.handleError(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedView])

    return (
        sidebarLoading ? <Fade in={sidebarLoading}>
            <LoadingScreen />
        </Fade> :
            isMobile || isTablet ? <MobileSidebar accountType={accountType} openMenu={menuOpen} closeMenu={closeMenu} selectedView={selectedView} setSelectedView={setSelectedView} signOut={auth.signOut} countNotification={countNotification} buttonList={buttonList} /> : <DesktopSidebar accountType={accountType} selectedView={selectedView} setSelectedView={setSelectedView} signOut={auth.signOut} countNotification={countNotification} buttonList={buttonList} />
    )
}