import HttpRequestsHandler from "src/services/http-requests-handler";

const path = {
    GET_PATIENT: '/api/patient/:uuid',
    GET_PATIENT_DOCUMENT: '/api/patient/:uuid/document/all',
    UPLOAD_PATIENT_DOCUMENT: '/api/patient/:uuidPatient/document',
    UPLOAD_SIGNED_PATIENT_DOCUMENT: '/api/patient/:uuidPatient/document/:uuidDocument/signed',
    EDIT_PATIENT_STATUS: '/api/patient/:uuid/status',
    FORCE_RESET_PASSWORD_PATIENT: '/api/patient/:uuid/password',
    UPLOAD_REIMBURSEMENT_FORM: '/api/patient/:uuid/reimbursement/document',
    ASSIGN_DEFAULT_PAYMENT_METHOD: '/api/patient/:patientUuid/paymentmethod/:paymentUuid',
    REMOVE_DEFAULT_PAYMENT_METHOD: '/api/patient/:patientUuid/paymentmethod/remove',
    ASSIGN_PATIENT_TO_SITE: '/api/patient/:uuid/site/:siteUuid',
    APPROVE_PATIENT: '/api/patient/:uuid/activate',
    FORCE_APPROVE_PATIENT: '/api/patient/:uuid/forceactivate',
    REJECT_PATIENT: '/api/patient/:uuid/reject',
    ENABLE_TRANSFER: '/api/patient/:uuid/transferstatus',
    DELETE_PATIENT: '/api/patient/:uuid'
}

export const DELETE_PATIENT = (uuid) => {
    return HttpRequestsHandler("DELETE", path.DELETE_PATIENT.replace(/:uuid/gi, uuid), null);
}

export const GET_PATIENT = (uuid) => {
    return HttpRequestsHandler("GET", path.GET_PATIENT.replace(/:uuid/gi, uuid), null);
}

export const ASSIGN_PATIENT_TO_SITE = (uuid, uuidSite) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_PATIENT_TO_SITE.replace(/:uuid/gi, uuid).replace(/:siteUuid/gi, uuidSite), null, null);
}

export const PUT_PATIENT = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.GET_PATIENT.replace(/:uuid/gi, uuid), null, body);
}

export const GET_PATIENT_DOCUMENT = (uuid, params) => {
    return HttpRequestsHandler("GET", path.GET_PATIENT_DOCUMENT.replace(/:uuid/gi, uuid), params);
}

export const UPLOAD_PATIENT_DOCUMENT = (uuidPatient, body) => {
    return HttpRequestsHandler("UPLOAD", path.UPLOAD_PATIENT_DOCUMENT.replace(/:uuidPatient/gi, uuidPatient), null, body);
}

export const UPLOAD_SIGNED_PATIENT_DOCUMENT = (uuidPatient, uuidDocument, body) => {
    return HttpRequestsHandler("UPLOAD", path.UPLOAD_SIGNED_PATIENT_DOCUMENT.replace(/:uuidPatient/gi, uuidPatient).replace(/:uuidDocument/gi, uuidDocument), null, body);
}

export const EDIT_PATIENT_STATUS = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.EDIT_PATIENT_STATUS.replace(/:uuid/gi, uuid), null, body);
}

export const FORCE_RESET_PASSWORD_PATIENT = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.FORCE_RESET_PASSWORD_PATIENT.replace(/:uuid/gi, uuid), null, body);
}

export const UPLOAD_REIMBURSEMENT_FORM = (uuid, body) => {
    return HttpRequestsHandler("UPLOAD", path.UPLOAD_REIMBURSEMENT_FORM.replace(/:uuid/gi, uuid), null, body);
}

export const ASSIGN_DEFAULT_PAYMENT_METHOD = (patientUuid, paymentUuid) => {
    return HttpRequestsHandler("PUT", path.ASSIGN_DEFAULT_PAYMENT_METHOD.replace(/:patientUuid/gi, patientUuid).replace(/:paymentUuid/gi, paymentUuid), null, null);
}

export const REMOVE_DEFAULT_PAYMENT_METHOD = (patientUuid) => {
    return HttpRequestsHandler("PUT", path.REMOVE_DEFAULT_PAYMENT_METHOD.replace(/:patientUuid/gi, patientUuid), null, null);
}

export const APPROVE_PATIENT = (uuid) => {
    return HttpRequestsHandler("PUT", path.APPROVE_PATIENT.replace(/:uuid/gi, uuid), null, null);
}

export const FORCE_APPROVE_PATIENT = (uuid) => {
    return HttpRequestsHandler("PUT", path.FORCE_APPROVE_PATIENT.replace(/:uuid/gi, uuid), null, null);
}

export const REJECT_PATIENT = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.REJECT_PATIENT.replace(/:uuid/gi, uuid), null, body);
}

export const ENABLE_TRANSFER = (uuid, body) => {
    return HttpRequestsHandler("PUT", path.ENABLE_TRANSFER.replace(/:uuid/gi, uuid), null, body);
}