import React, { createContext, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
  login,
  GET_PROFILE,
  REFRESH_TOKEN,
  CATCH_ERRORS,
  CHECK_OTP,
  RESET_PASSWORD,
  REQUEST_RESET_PASSWORD,
  CHANGE_PASSWORD_EXPIRED,
  GET_ROLES,
  GET_PROFILE_STUDYCOORDINATOR,
  GET_PROFILE_PATIENT,
} from "./services";
import { isTomorrow } from "date-fns";
import moment from "moment";
import { GET_USER_CONFIGURATION } from "src/pages/Profile/services";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

const GetProfileByAccountType = {
  'PARAGON_STAFF': GET_PROFILE,
  'PARAGON_MANAGER': GET_PROFILE,
  'PI': GET_PROFILE,
  'SPONSOR': GET_PROFILE,
  'STUDY_COORDINATOR': GET_PROFILE_STUDYCOORDINATOR,
  'PATIENT': GET_PROFILE_PATIENT
};

const AuthProvider = (props) => {
  const [isPending, setPending] = useState(false);
  const [requestTFA, setRequestTFA] = useState(false);
  const [displayError, setDisplayError] = useState(null);
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "LOGIN":
          return {
            ...prevState,
            token: action.token,
            roles: action.roles,
            profile: action.profile,
            passwordExpired: action.passwordExpired,
            config: action.config,
          };
        case "EDIT_STATE":
          return {
            ...prevState,
            roles: action.roles ? action.roles : prevState.roles,
            profile: action.profile ? action.profile : prevState.profile,
            lastAction: action.lastAction
              ? action.lastAction
              : prevState.lastAction,
            passwordExpired: action.passwordExpired
              ? action.passwordExpired
              : prevState.passwordExpired,
            config: action.config ? action.config : prevState.config,
            myprofile: action.myprofile
              ? action.myprofile
              : prevState.myprofile,
          };
        case "REFRESH_TOKEN":
          return {
            ...prevState,
            token: action.token,
          };
        case "LOGOUT":
          return {
            ...prevState,
            profile: null,
            token: null,
            roles: null,
            lastAction: null,
            passwordExpired: null,
          };

        default:
          return {
            ...prevState,
            profile: null,
            token: null,
            roles: null,
            lastAction: null,
            passwordExpired: null,
          };
      }
    },
    {
      token: null,
    }
  );

  const loadProfile = useCallback(
    async (_token, _fetchingUser) => {
      try {
        if (_fetchingUser) {
          setPending(true);
          let _roles = await GET_ROLES();
          let _accountType = ['PARAGON_STAFF', 'PARAGON_MANAGER', 'PI', 'SPONSOR', 'STUDY_COORDINATOR', 'PATIENT'].filter(aType => _roles.data.includes(aType))[0];
          let _profile = await GetProfileByAccountType[_accountType]();
          let _configuration = await GET_USER_CONFIGURATION();


          if (_profile?.data?.languageList?.length === 1) {
            i18n.changeLanguage(_profile.data.languageList[0].code);
          } else if(_profile?.data?.languageList?.length > 1){
            let mainLang = _profile.data.languageList.filter(lang => lang.isMain === true)[0];
            
            if(mainLang){
              i18n.changeLanguage(mainLang.code);
            }else{
              i18n.changeLanguage(_profile.data.languageList[0].code);
            }
          }

          if (_fetchingUser) {
            dispatch({
              type: "LOGIN",
              roles: [..._roles.data, _profile.data.username],
              profile: _profile.data,
              token: _token,
              passwordExpired:
                isTomorrow(_profile.data.passwordExpirationDate) ||
                _profile.data.firstLogin,
              config: _configuration.data.json !== "" ? JSON.parse(_configuration.data.json) : {},
            });
            setPending(false);
          }else{
            setPending(false);
          }
        }
      } catch (error) {
        console.warn("LOAD PROFILE ERROR", error, error?.response?.data);
        setPending(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const userAction = useMemo(
    () => ({
      setState: async (state) => {
        dispatch({ type: "EDIT_STATE", ...state });
      },
      signIn: async (loginFields) => {
        return new Promise((resolve, reject) => {
          login(loginFields).then(
            (data) => {
              if (data?.data?.using2fa) {
                dispatch({
                  type: "LOGIN",
                  profile: data.data,
                });
                setRequestTFA(true);
                resolve("OK")
              } else {
                if (!data.data.firstLogin) {
                  localStorage.setItem("token", data.data.jwtToken);
                  loadProfile(data.data.jwtToken, true);
                  setDisplayError(null);
                  resolve("OK")
                } else {
                  localStorage.setItem("token", data.data.jwtToken);
                  dispatch({
                    type: "LOGIN",
                    roles: data.data.roles,
                    token: data.data.jwtToken,
                    profile: data.data,
                    config: { avatar: null },
                    passwordExpired:
                      isTomorrow(data.data.passwordExpirationDate) ||
                      data.data.firstLogin,
                  });
                  resolve("OK")
                  setDisplayError(null);
                }
              }
            },
            (error) => {
              if (error.response.data.code === 401 && error.response.data.message === "paragon.authentication.password_expired") {
                // window.location.href = window.location.origin + `/reset?username=${loginFields.username}`
                reject("password_expired");
              } else {
                reject(error.response.data.message)
              }
            }
          );
        });
      },
      signOut: async () => {
        localStorage.removeItem("token");
        sessionStorage.removeItem("LA");
        sessionStorage.removeItem('showWarning');
        window.location.href = window.location.origin;
      },
      refreshToken: async (lastFn) => {
        let _toSend = {
          lastAction: moment(sessionStorage.getItem("LA")).format(),
          token: localStorage.getItem("token"),
        };
        REFRESH_TOKEN(_toSend)
          .then(
            (response) => {
              if (response.status === 200) {
                localStorage.setItem("token", response?.data?.jwtToken);
                dispatch({
                  type: "REFRESH_TOKEN",
                  token: response?.data?.jwtToken,
                });
                lastFn();
              } else {
                userAction.signOut();
              }
            },
            (error) => {
              console.log("ERROR");
              userAction.signOut();
            }
          )
          .catch((error) => {
            console.log("CATCH");
            userAction.signOut();
          });
      },
      reloadProfile: async () => {
        loadProfile();
      },
      checkOTP: async (body) => {
        CHECK_OTP(body).then(
          (data) => {
            let _roles = [...data.data.roles];
            _roles.push(data.data.accountType);
            _roles.push(data.data.username);
            localStorage.setItem("token", data.data.jwtToken);

            if (!data.data.firstLogin) {
              GET_USER_CONFIGURATION().then(
                (configuration) => {
                  let _config =
                    configuration.data.json !== ""
                      ? JSON.parse(configuration.data.json)
                      : {};
                  dispatch({
                    type: "LOGIN",
                    roles: _roles,
                    token: data.data.jwtToken,
                    profile: data.data,
                    config: _config,
                    passwordExpired:
                      isTomorrow(new Date(data.data.passwordExpirationDate)) ||
                      data.data.firstLogin,
                  });
                },
                (error) => {
                  CATCH_ERRORS({
                    error: JSON.stringify({
                      response: error.response,
                      stack: JSON.stringify({
                        response: error.response,
                        stack: error.stack,
                      }),
                    }),
                  });
                  setPending(false);
                }
              );
            } else {
              dispatch({
                type: "LOGIN",
                roles: _roles,
                token: data.data.jwtToken,
                profile: data.data,
                config: { avatar: null },
                passwordExpired:
                  isTomorrow(new Date(data.data.passwordExpirationDate)) ||
                  data.data.firstLogin,
              });
            }
            setPending(false);
          },
          (error) => {
            CATCH_ERRORS({
              error: JSON.stringify({
                response: error.response,
                stack: JSON.stringify({
                  response: error.response,
                  stack: error.stack,
                }),
              }),
            });
            setPending(false);
          }
        );
      },
      handleError: (value) => {
        setDisplayError(value);
      },
      forgotPassword: (values) => {
        REQUEST_RESET_PASSWORD(values).then(
          (data) => {
            // console.log(data);
          },
          (error) => {
            CATCH_ERRORS({
              error: JSON.stringify({
                response: error.response,
                stack: JSON.stringify({
                  response: error.response,
                  stack: error.stack,
                }),
              }),
            });
            setPending(false);
          }
        );
      },
      changeExpiredPassword: (values) => {
        return new Promise((resolve, reject) => {
          setPending(true);
          CHANGE_PASSWORD_EXPIRED(values).then(
            (data) => {
              setPending(false);
              resolve("OK");
              // console.log(data);
            },
            (error) => {
              reject(error.response);
              CATCH_ERRORS({
                error: JSON.stringify({
                  response: error.response,
                  stack: JSON.stringify({
                    response: error.response,
                    stack: error.stack,
                  }),
                }),
              });
              setPending(false);
            }
          )
        });
      }
      ,
      resetPassword: (values) => {
        setPending(true);
        RESET_PASSWORD(values).then(
          (data) => {
            setPending(false);
            window.location.href = window.location.origin;
          },
          (error) => {
            CATCH_ERRORS({
              error: JSON.stringify({
                response: error.response,
                stack: JSON.stringify({
                  response: error.response,
                  stack: error.stack,
                }),
              }),
            });
            setPending(false);
          }
        );
      }
    }),
    [loadProfile]
  );

  useEffect(() => {
    let fetchingUser = true;
    let _token = localStorage.getItem("token");

    if (_token) {
      loadProfile(_token, fetchingUser);
    }else{
      changeLanguage("en");
    }

    return () => {
      fetchingUser = false;
    }
  }, [i18n, loadProfile])

  return (
    <AuthContext.Provider
      value={{
        ...userAction,
        _data: state,
        isPending: isPending,
        requestTFA: requestTFA,
        displayError: displayError,
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { useAuth, AuthContext, AuthProvider };
