import React from 'react';
import mainStyle from 'src/styles/main.module.scss';
import { Button } from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { useRoles } from 'src/hooks/User/useRoles';

export const TableCreateButton = ({ text, role, onClick }) => {
    const permissions = useRoles();
    if (role && !permissions.hasRole(role))
        return null;

    return (
        <div id="create" className={mainStyle['container__Create']}>
            <Button classes={{ root: mainStyle['container__CreateButton'] }} variant="contained" color="primary" size="large" startIcon={<AddCircleOutlineRoundedIcon />} onClick={onClick}>
                {text}
            </Button>
        </div>
    )
}